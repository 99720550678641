import React from "react";
import { Link } from "react-router-dom";
import NewsLetter from "../../../components/news/news-letter";
import colombiaClubLogos from "../../../assets/img/football/clubs-logos/colombia.png";
import argClubLogos from "../../../assets/img/football/clubs-logos/arg.png";
import espClubLogos from "../../../assets/img/football/clubs-logos/esp.png";
import porClubLogos from "../../../assets/img/football/clubs-logos/por.png";

import BlogImage1 from "../../../assets/img/football/blog/1.jpg";

import LocationThree from "../../../assets/img/football/locations/3.jpg";

const MatchResults = (props) => {

    return (
        <>
            {/* {<!-- Section Title -->} */}
            <div className="section-title single-result" style={{background:'url("'+LocationThree+'")'}}>
                <div className="container">
                <div className="row">
                    {/* {<!-- Result Location -->} */}
                    <div className="col-lg-12">
                        <div className="result-location">
                            <ul>
                            <li>
                                Wed 26 Jun 2022
                            </li>
                            <li>
                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                                Saint Petersburg Stadium, Russia
                            </li>
                            <li>Att: 80,000</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* {<!-- End Result Location -->} */}
                {/* {<!-- Result -->} */}
                <div className="row">
                    <div className="col-md-5 col-lg-5">
                        <div className="team">
                            <img src={colombiaClubLogos} alt="club-logo" />
                            <Link to="/single-team">Colombia</Link>
                        </div>
                    </div>
                    <div className="col-md-2 col-lg-2">
                        <div className="result-match">
                            3 : 2
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-5">
                        <div className="team right">
                            <Link to="/single-team">Argentina</Link>
                            <img src={argClubLogos} alt="club-logo" />
                        </div>
                    </div>
                </div>
                {/* {<!-- End Result -->} */}
                </div>
            </div>
            {/* {<!-- End Section Title -->} */}
            <div className="container if-tabs">
                <div className="row">
                <div className="col-sm-12">
                    <ul className="d-flex justify-content-center flex-row pt-4" id="myDIV">
                        <li className="bv"><a href="#vd">Videos</a></li>
                        <li className="bv"><a href="#nwtm">News of the two teams</a></li>
                        <li className="bv"><a href="#precon">Previous confrontations</a></li>
                    </ul>
                </div>
                </div>
            </div>
            {/* {<!-- Section Area - Content Central -->} */}
            <section className="content-info">
                <div className="container paddings-mini">
                <div className="row">
                    {/* {<!-- Sidebars -->} */}
                    {/* {<!-- End Sidebars -->} */}
                    <div className="col-lg-8 mx-auto">
                        {/* {<!-- Content Text-->} */}
                        <div id="precon"></div>
                        <div id="Matches-resultso"></div>
                        <div className="panel-box">
                            <div className="titles">
                            <h4>Match Results</h4>
                            </div>
                            <div className="row text-center">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header bg-dark text-white">
                                        History of the two teams' matches (last 2 matches)
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive bgt">
                                        <table className="table table-bordered ">
                                            <thead className="bg-dark">
                                                <tr>
                                                    <th scope="col" className="text-white">FRANCE</th>
                                                    <th scope="col" className="text-white">Statistics</th>
                                                    <th scope="col" className="text-white">AUSTRALIA</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Win</td>
                                                    <td>0</td>
                                                </tr>
                                                <tr>
                                                    <td>0</td>
                                                    <td>be balanced</td>
                                                    <td>0</td>
                                                </tr>
                                                <tr>
                                                    <td>0</td>
                                                    <td>defeat</td>
                                                    <td>2</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="recent-results">
                                    <div className="info-results bg-transparent">
                                        <ul>
                                        <li>
                                            <span className="head"> 
                                            Portugal Vs Spain <span className="date">27 Jun 2017</span>
                                            </span>
                                            <div className="goals-result">
                                                <span>
                                                <img src={porClubLogos} alt="" /> <br/>
                                                Portugal
                                                </span>
                                                <span className="goals">
                                                <b>0</b> - <b>0</b>
                                                </span>
                                                <span>
                                                <img src={espClubLogos} alt="" /><br/>
                                                Spain
                                                </span>
                                            </div>
                                        </li>
                                        <li>
                                            <span className="head"> 
                                            Portugal Vs Spain <span className="date">27 Jun 2017</span>
                                            </span>
                                            <div className="goals-result">
                                                <span>
                                                <img src={porClubLogos} alt="" /> <br/>
                                                Portugal
                                                </span>
                                                <span className="goals">
                                                <b>0</b> - <b>0</b>
                                                </span>
                                                <span>
                                                <img src={espClubLogos} alt="" /><br/>
                                                Spain
                                                </span>
                                            </div>
                                        </li>
                                        <li>
                                            <span className="head"> 
                                            Portugal Vs Spain <span className="date">27 Jun 2017</span>
                                            </span>
                                            <div className="goals-result">
                                                <span>
                                                <img src={porClubLogos} alt="" /> <br/>
                                                Portugal
                                                </span>
                                                <span className="goals">
                                                <b>0</b> - <b>0</b>
                                                </span>
                                                <span>
                                                <img src={espClubLogos} alt="" /><br/>
                                                Spain
                                                </span>
                                            </div>
                                        </li>
                                        <li>
                                            <span className="head"> 
                                            Portugal Vs Spain <span className="date">27 Jun 2017</span>
                                            </span>
                                            <div className="goals-result">
                                                <span>
                                                <img src={porClubLogos} alt="" /> <br/>
                                                Portugal
                                                </span>
                                                <span className="goals">
                                                <b>0</b> - <b>0</b>
                                                </span>
                                                <span>
                                                <img src={espClubLogos} alt="" /><br/>
                                                Spain
                                                </span>
                                            </div>
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div id="nwtm"></div>
                        <div className="panel-box">
                            <div className="titles">
                            <h4>Recent News</h4>
                            </div>
                            {/* {<!-- Post Item -->} */}
                            <div className="post-item">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="img-hover">
                                        <img src={BlogImage1} alt="" className="img-responsive" />
                                        <div className="overlay"><Link to="/single-news">+</Link></div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <h5><Link to="/single-news">Group Stage Breakdown</Link></h5>
                                    <span className="data-info">January 3, 2014  / <i className="fa fa-comments"></i><a href="!#">0</a></span>
                                    <p>While familiar with fellow European nation France, Hareide admits that South American side Peru.<Link to="/single-news">Read More [+]</Link></p>
                                </div>
                            </div>
                            </div>
                            {/* {<!-- End Post Item -->} */}
                        </div>
                        <div id="vd"></div>
                        <div className="panel-box">
                            <div className="titles">
                            <h4>Latest Club Videos</h4>
                            </div>
                            <div className="row ">
                            {/* {<!--Item Club News -->} */}
                            <div className="col-lg-6 col-xl-4">
                                {/* {<!-- Widget Text-->} */}
                                <div className="panel-box">
                                    <div className="titles no-margin">
                                        <h4 className="px-2"><a href="!#">Eliminatory to the world.</a></h4>
                                    </div>
                                    <iframe className="video" src="https://www.youtube.com/embed/Ln8rXkeeyP0" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen=""></iframe>
                                </div>
                                {/* {<!-- End Widget Text-->} */}
                            </div>
                            {/* {<!--End Item Club News -->} */}
                            {/* {<!--Item Club News -->} */}
                            <div className="col-lg-6 col-xl-4">
                                {/* {<!-- Widget Text-->} */}
                                <div className="panel-box">
                                    <div className="titles no-margin">
                                        <h4 className="px-2"><a href="!#">Colombia classification</a></h4>
                                    </div>
                                    <iframe className="video" src="https://www.youtube.com/embed/Z5cackyUfgk" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen=""></iframe>
                                </div>
                                {/* {<!-- End Widget Text-->} */}
                            </div>
                            {/* {<!--End Item Club News -->} */}
                            {/* {<!--Item Club News -->} */}
                            <div className="col-lg-6 col-xl-4">
                                {/* {<!-- Widget Text-->} */}
                                <div className="panel-box">
                                    <div className="titles no-margin">
                                        <h4 className="px-2"><a href="!#">World Cup goal</a></h4>
                                    </div>
                                    <iframe className="video" src="https://www.youtube.com/embed/hW3hnUoUS0k" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen=""></iframe>
                                </div>
                                {/* {<!-- End Widget Text-->} */}
                            </div>
                            {/* {<!--End Item Club News -->} */}
                            </div>
                        </div>
                        {/* {<!-- End Content Text-->} */}
                    </div>
                </div>
                </div>
                {/* {<!-- Newsletter -->} */}
                <NewsLetter />
                {/* {<!-- End Newsletter -->} */}
            </section>
            {/* {<!-- End Section Area -  Content Central -->} */}
        </>
    )
}


export default MatchResults;

