import React from "react";

import Header from "./Header";
import Footer from "./Footer";
import MobileTopNavbar from "./mobile-top-navbar";

const Layout = ({ Component, route, props }) => (
  <>
    <MobileTopNavbar />
    <div id="layout" className="mm-page mm-slideout">
      <Header />
      <Component route={route} />
      <Footer />
    </div>
  </>
);

export default Layout;
