import React, { useState, useEffect } from "react";
import GamesDropdown from "../../components/games/games";
import LanguagesDropdown from "../../components/languages/languages";
import TopNavbar1 from "./top-navbar1";
import { useTranslation } from "react-i18next";

const Header = (props) => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")) {
      setLanguage(localStorage.getItem("i18nextLng"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <header className="header-2">
        <div className="headerbox">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <ul className="language-game d-flex flex-row justify-content-end mb-0">
                  <li>
                    <div className="form-group mb-0">
                      <GamesDropdown />
                    </div>
                  </li>
                  <li>
                    <div className="form-group mb-0">
                      <label for="exampleFormControlSelect1">
                        Select Language
                      </label>
                      <select
                        className="form-control"
                        value={language}
                        onChange={(event) => {
                          i18n.changeLanguage(event.target.value);
                          setLanguage(event.target.value);
                          window.location.reload();
                        }}
                      >
                        <LanguagesDropdown />
                      </select>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row justify-content-between align-items-center">
              {/* <!-- Logo--> */}
              
              <TopNavbar1 />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
