import React from "react";
import { Link } from "react-router-dom";
import NewsLetter from "../../../components/news/news-letter";
import LogoSlider from "../../../components/logoslider/logoslider";
import SlideOne from "../../../assets/img/cricket/slide/1.jpg";

import ColombiaClubLogo from "../../../assets/img/cricket/clubs-logos/colombia.png";
import RussiaClubLogo from "../../../assets/img/cricket/clubs-logos/rusia.png";
import ArabiaClubLogo from "../../../assets/img/cricket/clubs-logos/arabia.png";
import UrugueyClubLogo from "../../../assets/img/cricket/clubs-logos/uru.png";
import BrazilClubLogo from "../../../assets/img/cricket/clubs-logos/bra.png";
import CostaRicaClubLogo from "../../../assets/img/cricket/clubs-logos/costa-rica.png";
import JapanClubLogo from "../../../assets/img/cricket/clubs-logos/japan.png";
import SenegalClubLogo from "../../../assets/img/cricket/clubs-logos/sen.png";
import PolandClubLogo from "../../../assets/img/cricket/clubs-logos/pol.png";
import ColClubLogo from "../../../assets/img/cricket/clubs-logos/col_logo.png";
import ColombiaClubTeam from "../../../assets/img/cricket/clubs-teams/IND.png";
import PlayerCristianoRonaldo from "../../../assets/img/cricket/players/1.jpg";
import PlayerLionelMessi from "../../../assets/img/cricket/players/2.jpg";
import PlayerNeymar from "../../../assets/img/cricket/players/3.jpg";
import PlayerLuisSuárez from "../../../assets/img/cricket/players/4.jpg";
import PlayerGarethBale from "../../../assets/img/cricket/players/5.jpg";
import PlayerSergioAgüero from "../../../assets/img/cricket/players/6.jpg";
import PortugalClubLogo from "../../../assets/img/cricket/clubs-logos/por.png";
import SpainClubLogo from "../../../assets/img/cricket/clubs-logos/esp.png";
import FranceClubLogo from "../../../assets/img/cricket/clubs-logos/fra.png";
import AustraliaClubLogo from "../../../assets/img/cricket/clubs-logos/aus.png";

import BlogOne from "../../../assets/img/cricket/blog/1.jpg";
import BlogTwo from "../../../assets/img/cricket/blog/2.jpg";
import BlogThree from "../../../assets/img/cricket/blog/3.jpg";
import ClubTeamColombia from "../../../assets/img/cricket/clubs-teams/IND.png";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const SingleTeam = (props) => {

  return (
    <>
        {/* {<!-- Section Title -->} */}
        <div className="section-title-team">
            <div className="container">
                <div className="row">
                    <div className="col-xl-7">
                        <div className="row">
                            <div className="col-md-3">
                                <img src={ColClubLogo} alt="" />
                            </div>

                            <div className="col-md-9">
                                <h1>India</h1>
                                <ul className="general-info">
                                    <li><h6><strong>Foundation:</strong> 1930</h6></li>
                                    <li><h6><strong>President:</strong> Juan R.</h6></li>
                                    <li><h6><strong>Manager:</strong> Pekerman</h6></li>
                                    <li><h6><strong>Total Titles:</strong> 100+</h6></li>
                                    <li><h6><strong>Location:</strong> Colombia</h6></li>
                                    <li>
                                        <h6>
                                            <i className="fa fa-link" aria-hidden="true"></i>
                                            <a href="https://themeforest.net/user/iwthemes/portfolio?ref=iwthemes" target="_blank">www.site.com</a>
                                        </h6>
                                    </li>
                                </ul>

                                <ul className="social-teams">
                                    <li>
                                        <div>
                                            <a href="!#" className="facebook">
                                                <i className="fa fa-facebook"></i>
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <a href="!#" className="twitter-icon">
                                                <i className="fa fa-twitter"></i>
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <a href="!#" className="youtube">
                                                <i className="fa fa-youtube"></i>
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-image-team" style={{background:'url("'+ColombiaClubTeam+'")'}}></div>
        </div>
        {/* {<!-- End Section Title -->} */}

        {/* {<!-- Section Area - Content Central -->} */}
        <section className="content-info">

            {/* {<!-- Single Team Tabs -->} */}
            <div className="single-team-tabs">
                <div className="container">
                    <div className="row">
                        {/* {<!-- Left Content - Tabs and Carousel -->} */}
                        <div className="col-xl-9 col-md-9">
                            <Tabs
                                defaultActiveKey="overview"
                                id="uncontrolled-tab-example"
                                className="nav nav-tabs mb-4"
                            >
                                <Tab eventKey="overview" title="Overview">
                                    <div className="tab-content">
                                        {/* {<!-- Tab One - overview -->} */}
                                        <div className="tab-pane active" id="overview">

                                            <div className="panel-box padding-b">
                                                <div className="titles">
                                                    <h4>Colombia national football team</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 col-xl-4">
                                                        <img src={ClubTeamColombia} alt="" />
                                                    </div>

                                                    <div className="col-lg-12 col-xl-8">
                                                        <p>The Colombia national football team (Spanish: Selección de fútbol de Colombia) represents Colombia in international football competitions and is overseen by the Colombian Football Federation. It is a member of the CONMEBOL and is currently ranked thirteenth in the FIFA World Rankings.[3] The team are nicknamed Los Cafeteros due to the coffee production in their country.</p>

                                                        <p>Since the mid-1980s, the national team has been a symbol fighting the country's negative reputation. This has made the sport popular and made the national team a sign of nationalism, pride and passion for many Colombians worldwide.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* {<!--Items Club News -->} */}
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h3 className="clear-title">Latest Club News</h3>
                                                </div>

                                                {/* {<!--Item Club News -->} */}
                                                <div className="col-lg-6 col-xl-4">
                                                    {/* {<!-- Widget Text-->} */}
                                                    <div className="panel-box">
                                                        <div className="titles no-margin">
                                                            <h4><a href="!#">World football's dates.</a></h4>
                                                        </div>
                                                        <a href="!#"><img src={BlogOne} alt="" /></a>
                                                        <div className="row">
                                                            <div className="info-panel">
                                                                <p>Fans from all around the world can apply for 2022 FIFA World Cup™ tickets as the first window of sales.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* {<!-- End Widget Text-->} */}
                                                </div>
                                                {/* {<!--End Item Club News -->} */}

                                                {/* {<!--Item Club News -->} */}
                                                <div className="col-lg-6 col-xl-4">
                                                    {/* {<!-- Widget Text-->} */}
                                                    <div className="panel-box">
                                                        <div className="titles no-margin">
                                                            <h4><a href="!#">Mbappe’s year to remember</a></h4>
                                                        </div>
                                                        <a href="!#"><img src={BlogTwo} alt="" /></a>
                                                        <div className="row">
                                                            <div className="info-panel">
                                                                <p>Tickets may be purchased online by using Visa payment cards or Visa Checkout. Visa is the official.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* {<!-- End Widget Text-->} */}
                                                </div>
                                                {/* {<!--End Item Club News -->} */}

                                                {/* {<!--Item Club News -->} */}
                                                <div className="col-lg-6 col-xl-4">
                                                    {/* {<!-- Widget Text-->} */}
                                                    <div className="panel-box">
                                                        <div className="titles no-margin">
                                                            <h4><a href="!#">Egypt are one family</a></h4>
                                                        </div>
                                                        <a href="!#"><img src={BlogThree} alt="" /></a>
                                                        <div className="row">
                                                            <div className="info-panel">
                                                                <p>Successful applicants who have applied for supporter tickets and conditional supporter tickets will.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* {<!-- End Widget Text-->} */}
                                                </div>
                                                {/* {<!--End Item Club News -->} */}
                                            </div>
                                            {/* {<!--End Items Club News -->} */}


                                            {/* {<!--Items Club video -->} */}
                                            <div className="row no-line-height">
                                                <div className="col-md-12">
                                                    <h3 className="clear-title">Latest Club Videos</h3>
                                                </div>

                                                {/* {<!--Item Club News -->} */}
                                                <div className="col-lg-6 col-xl-4">
                                                    {/* {<!-- Widget Text-->} */}
                                                    <div className="panel-box">
                                                        <div className="titles no-margin">
                                                            <h4><a href="!#">Eliminatory to the world.</a></h4>
                                                        </div>
                                                        <iframe className="video" src="https://www.youtube.com/embed/Ln8rXkeeyP0" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe>
                                                    </div>
                                                    {/* {<!-- End Widget Text-->} */}
                                                </div>
                                                {/* {<!--End Item Club News -->} */}

                                                {/* {<!--Item Club News -->} */}
                                                <div className="col-lg-6 col-xl-4">
                                                    {/* {<!-- Widget Text-->} */}
                                                    <div className="panel-box">
                                                        <div className="titles no-margin">
                                                            <h4><a href="!#">Colombia classification</a></h4>
                                                        </div>
                                                        <iframe className="video" src="https://www.youtube.com/embed/Z5cackyUfgk" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe>
                                                    </div>
                                                    {/* {<!-- End Widget Text-->} */}
                                                </div>
                                                {/* {<!--End Item Club News -->} */}

                                                {/* {<!--Item Club News -->} */}
                                                <div className="col-lg-6 col-xl-4">
                                                    {/* {<!-- Widget Text-->} */}
                                                    <div className="panel-box">
                                                        <div className="titles no-margin">
                                                            <h4><a href="!#">World Cup goal</a></h4>
                                                        </div>
                                                        <iframe className="video" src="https://www.youtube.com/embed/hW3hnUoUS0k" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe>
                                                    </div>
                                                    {/* {<!-- End Widget Text-->} */}
                                                </div>
                                                {/* {<!--End Item Club News -->} */}
                                            </div>
                                            {/* {<!--End Items Club video -->} */}

                                            {/* {<!--Sponsors CLub -->} */}
                                            <div className="row no-line-height">
                                                    <div className="col-md-12">
                                                        <h3 className="clear-title">Sponsors Club</h3>
                                                    </div>
                                            </div>
                                            {/* {<!--End Sponsors CLub -->} */}

                                            <LogoSlider />

                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="squad" title="Squad">
                                    {/* <div className="tab-content"> */}
                                        {/* {<!-- Tab Two - squad -->} */}
                                        <div className="tab-pane" id="squad">
                                            <div className="row">

                                                {/* {<!-- Item Player -->} */}
                                                <div className="col-xl-4 col-lg-6 col-md-6">
                                                    <div className="item-player">
                                                        <div className="head-player">
                                                            <img src={PlayerCristianoRonaldo} alt="location-team" />
                                                            <div className="overlay"><Link to="/cricket/single-player">+</Link></div>
                                                        </div>
                                                        <div className="info-player">
                                                            <span className="number-player">
                                                                13
                                                            </span>
                                                            <h4>
                                                                Cristiano Ronaldo
                                                                <span>Forward</span>
                                                            </h4>
                                                            <ul>
                                                                <li>
                                                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                                                </li>
                                                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                                                <li><strong>AGE:</strong> <span>28</span></li>
                                                            </ul>
                                                        </div>
                                                        <Link to="/cricket/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                                                    </div>
                                                </div>
                                                {/* {<!-- End Item Player -->} */}

                                                {/* {<!-- Item Player -->} */}
                                                <div className="col-xl-4 col-lg-6 col-md-6">
                                                    <div className="item-player">
                                                        <div className="head-player">
                                                            <img src={PlayerLionelMessi} alt="location-team" />
                                                            <div className="overlay"><Link to="/cricket/single-player">+</Link></div>
                                                        </div>
                                                        <div className="info-player">
                                                            <span className="number-player">
                                                                10
                                                            </span>
                                                            <h4>
                                                                Lionel Messi
                                                                <span>Defender</span>
                                                            </h4>
                                                            <ul>
                                                                <li>
                                                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                                                </li>
                                                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                                                <li><strong>AGE:</strong> <span>28</span></li>
                                                            </ul>
                                                        </div>
                                                        <Link to="/cricket/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                                                    </div>
                                                </div>
                                                {/* {<!-- End Item Player -->} */}

                                                {/* {<!-- Item Player -->} */}
                                                <div className="col-xl-4 col-lg-6 col-md-6">
                                                    <div className="item-player">
                                                        <div className="head-player">
                                                            <img src={PlayerNeymar} alt="location-team" />
                                                            <div className="overlay"><Link to="/cricket/single-player">+</Link></div>
                                                        </div>
                                                        <div className="info-player">
                                                            <span className="number-player">
                                                                2
                                                            </span>
                                                            <h4>
                                                                Neymar
                                                                <span>Midfielder</span>
                                                            </h4>
                                                            <ul>
                                                                <li>
                                                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                                                </li>
                                                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                                                <li><strong>AGE:</strong> <span>28</span></li>
                                                            </ul>
                                                        </div>
                                                        <Link to="/cricket/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                                                    </div>
                                                </div>
                                                {/* {<!-- End Item Player -->} */}

                                                {/* {<!-- Item Player -->} */}
                                                <div className="col-xl-4 col-lg-6 col-md-6">
                                                    <div className="item-player">
                                                        <div className="head-player">
                                                            <img src={PlayerLuisSuárez} alt="location-team" />
                                                            <div className="overlay"><Link to="/cricket/single-player">+</Link></div>
                                                        </div>
                                                        <div className="info-player">
                                                            <span className="number-player">
                                                                2
                                                            </span>
                                                            <h4>
                                                                Luis Suárez
                                                                <span>Goalkeeper</span>
                                                            </h4>
                                                            <ul>
                                                                <li>
                                                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                                                </li>
                                                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                                                <li><strong>AGE:</strong> <span>28</span></li>
                                                            </ul>
                                                        </div>
                                                        <Link to="/cricket/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                                                    </div>
                                                </div>
                                                {/* {<!-- End Item Player -->} */}

                                                {/* {<!-- Item Player -->} */}
                                                <div className="col-xl-4 col-lg-6 col-md-6">
                                                    <div className="item-player">
                                                        <div className="head-player">
                                                            <img src={PlayerGarethBale} alt="location-team" />
                                                            <div className="overlay"><Link to="/cricket/single-player">+</Link></div>
                                                        </div>
                                                        <div className="info-player">
                                                            <span className="number-player">
                                                                2
                                                            </span>
                                                            <h4>
                                                                Gareth Bale
                                                                <span>Midfielder</span>
                                                            </h4>
                                                            <ul>
                                                                <li>
                                                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                                                </li>
                                                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                                                <li><strong>AGE:</strong> <span>28</span></li>
                                                            </ul>
                                                        </div>
                                                        <Link to="/cricket/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                                                    </div>
                                                </div>
                                                {/* {<!-- End Item Player -->} */}

                                                {/* {<!-- Item Player -->} */}
                                                <div className="col-xl-4 col-lg-6 col-md-6">
                                                    <div className="item-player">
                                                        <div className="head-player">
                                                            <img src={PlayerSergioAgüero} alt="location-team" />
                                                            <div className="overlay"><Link to="/cricket/single-player">+</Link></div>
                                                        </div>
                                                        <div className="info-player">
                                                            <span className="number-player">
                                                                2
                                                            </span>
                                                            <h4>
                                                                Sergio Agüero
                                                                <span>Goalkeeper</span>
                                                            </h4>
                                                            <ul>
                                                                <li>
                                                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                                                </li>
                                                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                                                <li><strong>AGE:</strong> <span>28</span></li>
                                                            </ul>
                                                        </div>
                                                        <Link to="/cricket/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                                                    </div>
                                                </div>
                                                {/* {<!-- End Item Player -->} */}

                                                {/* {<!-- Item Player -->} */}
                                                <div className="col-xl-4 col-lg-6 col-md-6">
                                                    <div className="item-player">
                                                        <div className="head-player">
                                                            <img src={PlayerCristianoRonaldo} alt="location-team" />
                                                            <div className="overlay"><Link to="/cricket/single-player">+</Link></div>
                                                        </div>
                                                        <div className="info-player">
                                                            <span className="number-player">
                                                                13
                                                            </span>
                                                            <h4>
                                                                Cristiano Ronaldo
                                                                <span>Forward</span>
                                                            </h4>
                                                            <ul>
                                                                <li>
                                                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                                                </li>
                                                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                                                <li><strong>AGE:</strong> <span>28</span></li>
                                                            </ul>
                                                        </div>
                                                        <Link to="/cricket/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                                                    </div>
                                                </div>
                                                {/* {<!-- End Item Player -->} */}

                                                {/* {<!-- Item Player -->} */}
                                                <div className="col-xl-4 col-lg-6 col-md-6">
                                                    <div className="item-player">
                                                        <div className="head-player">
                                                            <img src={PlayerLionelMessi} alt="location-team" />
                                                            <div className="overlay"><Link to="/cricket/single-player">+</Link></div>
                                                        </div>
                                                        <div className="info-player">
                                                            <span className="number-player">
                                                                10
                                                            </span>
                                                            <h4>
                                                                Lionel Messi
                                                                <span>Defender</span>
                                                            </h4>
                                                            <ul>
                                                                <li>
                                                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                                                </li>
                                                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                                                <li><strong>AGE:</strong> <span>28</span></li>
                                                            </ul>
                                                        </div>
                                                        <Link to="/cricket/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                                                    </div>
                                                </div>
                                                {/* {<!-- End Item Player -->} */}

                                                {/* {<!-- Item Player -->} */}
                                                <div className="col-xl-4 col-lg-6 col-md-6">
                                                    <div className="item-player">
                                                        <div className="head-player">
                                                            <img src={PlayerNeymar} alt="location-team" />
                                                            <div className="overlay"><Link to="/cricket/single-player">+</Link></div>
                                                        </div>
                                                        <div className="info-player">
                                                            <span className="number-player">
                                                                2
                                                            </span>
                                                            <h4>
                                                                Neymar
                                                                <span>Midfielder</span>
                                                            </h4>
                                                            <ul>
                                                                <li>
                                                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                                                </li>
                                                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                                                <li><strong>AGE:</strong> <span>28</span></li>
                                                            </ul>
                                                        </div>
                                                        <Link to="/cricket/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                                                    </div>
                                                </div>
                                                {/* {<!-- End Item Player -->} */}

                                                {/* {<!-- Item Player -->} */}
                                                <div className="col-xl-4 col-lg-6 col-md-6">
                                                    <div className="item-player">
                                                        <div className="head-player">
                                                            <img src={PlayerLuisSuárez} alt="location-team" />
                                                            <div className="overlay"><Link to="/cricket/single-player">+</Link></div>
                                                        </div>
                                                        <div className="info-player">
                                                            <span className="number-player">
                                                                2
                                                            </span>
                                                            <h4>
                                                                Luis Suárez
                                                                <span>Goalkeeper</span>
                                                            </h4>
                                                            <ul>
                                                                <li>
                                                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                                                </li>
                                                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                                                <li><strong>AGE:</strong> <span>28</span></li>
                                                            </ul>
                                                        </div>
                                                        <Link to="/cricket/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                                                    </div>
                                                </div>
                                                {/* {<!-- End Item Player -->} */}

                                                {/* {<!-- Item Player -->} */}
                                                <div className="col-xl-4 col-lg-6 col-md-6">
                                                    <div className="item-player">
                                                        <div className="head-player">
                                                            <img src={PlayerGarethBale} alt="location-team" />
                                                            <div className="overlay"><Link to="/cricket/single-player">+</Link></div>
                                                        </div>
                                                        <div className="info-player">
                                                            <span className="number-player">
                                                                2
                                                            </span>
                                                            <h4>
                                                                Gareth Bale
                                                                <span>Midfielder</span>
                                                            </h4>
                                                            <ul>
                                                                <li>
                                                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                                                </li>
                                                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                                                <li><strong>AGE:</strong> <span>28</span></li>
                                                            </ul>
                                                        </div>
                                                        <Link to="/cricket/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                                                    </div>
                                                </div>
                                                {/* {<!-- End Item Player -->} */}

                                                {/* {<!-- Item Player -->} */}
                                                <div className="col-xl-4 col-lg-6 col-md-6">
                                                    <div className="item-player">
                                                        <div className="head-player">
                                                            <img src={PlayerSergioAgüero} alt="location-team" />
                                                            <div className="overlay"><Link to="/cricket/single-player">+</Link></div>
                                                        </div>
                                                        <div className="info-player">
                                                            <span className="number-player">
                                                                2
                                                            </span>
                                                            <h4>
                                                                Sergio Agüero
                                                                <span>Goalkeeper</span>
                                                            </h4>
                                                            <ul>
                                                                <li>
                                                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                                                </li>
                                                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                                                <li><strong>AGE:</strong> <span>28</span></li>
                                                            </ul>
                                                        </div>
                                                        <Link to="/cricket/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                                                    </div>
                                                </div>
                                                {/* {<!-- End Item Player -->} */}

                                            </div>
                                        </div>
                                        {/* {<!-- End Tab Two - squad -->} */}
                                    {/* </div> */}

                                </Tab>
                                <Tab eventKey="fixtures" title="FIXTURES">

                                    {/* {<!-- Tab Theree - fixtures -->} */}
                                    <div className="tab-pane" id="fixtures">

                                        <table className="table-striped table-responsive table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Team A</th>
                                                    <th className="text-center">VS</th>
                                                    <th>Team B</th>
                                                    <th>Details</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <img src={ColombiaClubLogo} alt="icon" />
                                                        <strong>Colombia</strong><br />
                                                        <small className="meta-text">GROUP H.</small>
                                                    </td>
                                                    <td className="text-center">Vs</td>
                                                    <td>
                                                        <img src={JapanClubLogo} alt="icon1" />
                                                        <strong>Japan</strong><br />
                                                        <small className="meta-text">GROUP H.</small>
                                                    </td>
                                                    <td>
                                                        Jun 19,  07:00<br />
                                                        <small className="meta-text">Mordovia Arena,Saransk</small>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src={PolandClubLogo} alt="icon1" />
                                                        <strong>Poland</strong><br />
                                                        <small className="meta-text">GROUP H.</small>
                                                    </td>
                                                    <td className="text-center">Vs</td>
                                                    <td>
                                                        <img src={ColombiaClubLogo} alt="icon" />
                                                        <strong>Colombia</strong><br />
                                                        <small className="meta-text">GROUP H.</small>
                                                    </td>
                                                    <td>
                                                        Jun 24,  13:00<br />
                                                        <small className="meta-text">Kazan Arena,Kazan</small>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src={SenegalClubLogo} alt="icon1" />
                                                        <strong>Senegal</strong><br />
                                                        <small className="meta-text">GROUP H.</small>
                                                    </td>
                                                    <td className="text-center">Vs</td>
                                                    <td>
                                                        <img src={ColombiaClubLogo} alt="icon" />
                                                        <strong>Colombia</strong><br />
                                                        <small className="meta-text">GROUP H.</small>
                                                    </td>
                                                    <td>
                                                        Jun 28, 09:00<br />
                                                        <small className="meta-text">Samara Arena,Samara</small>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src={ColombiaClubLogo} alt="icon" />
                                                        <strong>Colombia</strong><br />
                                                        <small className="meta-text">GROUP H.</small>
                                                    </td>
                                                    <td className="text-center">Vs</td>
                                                    <td>
                                                        <img src={PolandClubLogo} alt="icon1" />
                                                        <strong>Poland</strong><br />
                                                        <small className="meta-text">GROUP H.</small>
                                                    </td>
                                                    <td>
                                                        Jun 24,  13:00<br />
                                                        <small className="meta-text">Kazan Arena,Kazan</small>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    {/* {<!-- End Tab Theree - fixtures -->} */}

                                </Tab>
                                <Tab eventKey="results" title="RESULTS">
                                    {/* {<!-- Tab Theree - results -->} */}
                                    <div className="tab-pane" id="results">
                                        <div className="recent-results results-page">
                                            <div className="info-results">
                                                <ul>
                                                    <li>
                                                        <span className="head">
                                                            Portugal Vs Spain <span className="date">27 Jun 2017</span>
                                                        </span>

                                                        <div className="goals-result">
                                                            <Link to="/cricket/single-team">
                                                                <img src={PortugalClubLogo} alt="" />
                                                                Portugal
                                                            </Link>

                                                            <span className="goals">
                                                                <b>2</b> - <b>3</b>
                                                                <Link to="/cricket/single-result" className="btn theme">View More</Link>
                                                            </span>

                                                            <Link to="/cricket/single-team">
                                                                <img src={SpainClubLogo} alt="" />
                                                                Spain
                                                            </Link>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <span className="head">
                                                            Rusia Vs Colombia <span className="date">30 Jun 2017</span>
                                                        </span>

                                                        <div className="goals-result">
                                                            <Link to="/cricket/single-team">
                                                                <img src={RussiaClubLogo} alt="" />
                                                                Rusia
                                                            </Link>

                                                            <span className="goals">
                                                                <b>2</b> - <b>3</b>
                                                                <Link to="/cricket/single-result" className="btn theme">View More</Link>
                                                            </span>

                                                            <Link to="/cricket/single-team">
                                                                <img src={ColombiaClubLogo} alt="" />
                                                                    Colombia
                                                            </Link>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <span className="head">
                                                            Uruguay Vs Portugal <span className="date">31 Jun 2017</span>
                                                        </span>

                                                        <div className="goals-result">
                                                            <Link to="/cricket/single-team">
                                                                <img src={UrugueyClubLogo} alt="" />
                                                                Uruguay
                                                            </Link>

                                                            <span className="goals">
                                                                <b>2</b> - <b>3</b>
                                                                <Link to="/cricket/single-result" className="btn theme">View More</Link>
                                                            </span>

                                                            <Link to="/cricket/single-team">
                                                                <img src={PortugalClubLogo} alt="" />
                                                                    Portugal
                                                            </Link>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <span className="head">
                                                            Uruguay Vs Portugal <span className="date">31 Jun 2017</span>
                                                        </span>

                                                        <div className="goals-result">
                                                            <Link to="/cricket/single-team">
                                                                <img src={UrugueyClubLogo} alt="" />
                                                                Uruguay
                                                            </Link>

                                                            <span className="goals">
                                                                <b>2</b> - <b>3</b>
                                                                <Link to="/cricket/single-result" className="btn theme">View More</Link>
                                                            </span>

                                                            <Link to="/cricket/single-team">
                                                                <img src={PortugalClubLogo} alt="" />
                                                                    Portugal
                                                            </Link>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <span className="head">
                                                            Uruguay Vs Portugal <span className="date">31 Jun 2017</span>
                                                        </span>

                                                        <div className="goals-result">
                                                            <Link to="/cricket/single-team">
                                                                <img src={UrugueyClubLogo} alt="" />
                                                                Uruguay
                                                            </Link>

                                                            <span className="goals">
                                                                <b>2</b> - <b>3</b>
                                                                <Link to="/cricket/single-result" className="btn theme">View More</Link>
                                                            </span>

                                                            <Link to="/cricket/single-team">
                                                                <img src={PortugalClubLogo} alt="" />
                                                                    Portugal
                                                            </Link>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <span className="head">
                                                            Uruguay Vs Portugal <span className="date">31 Jun 2017</span>
                                                        </span>

                                                        <div className="goals-result">
                                                            <Link to="/cricket/single-team">
                                                                <img src={UrugueyClubLogo} alt="" />
                                                                Uruguay
                                                            </Link>

                                                            <span className="goals">
                                                                <b>2</b> - <b>3</b>
                                                                <Link to="/cricket/single-result" className="btn theme">View More</Link>
                                                            </span>

                                                            <Link to="/cricket/single-team">
                                                                <img src={PortugalClubLogo} alt="" />
                                                                    Portugal
                                                            </Link>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <span className="head">
                                                            Uruguay Vs Portugal <span className="date">31 Jun 2017</span>
                                                        </span>

                                                        <div className="goals-result">
                                                            <Link to="/cricket/single-team">
                                                                <img src={UrugueyClubLogo} alt="" />
                                                                Uruguay
                                                            </Link>

                                                            <span className="goals">
                                                                <b>2</b> - <b>3</b>
                                                                <Link to="/cricket/single-result" className="btn theme">View More</Link>
                                                            </span>

                                                            <Link to="/cricket/single-team">
                                                                <img src={PortugalClubLogo} alt="" />
                                                                    Portugal
                                                            </Link>
                                                        </div>
                                                    </li>

                                                        <li>
                                                        <span className="head">
                                                            Portugal Vs Spain <span className="date">27 Jun 2017</span>
                                                        </span>

                                                        <div className="goals-result">
                                                            <Link to="/cricket/single-team">
                                                                <img src={PortugalClubLogo} alt="" />
                                                                Portugal
                                                            </Link>

                                                            <span className="goals">
                                                                <b>2</b> - <b>3</b>
                                                                <Link to="/cricket/single-result" className="btn theme">View More</Link>
                                                            </span>

                                                            <Link to="/cricket/single-team">
                                                                <img src={SpainClubLogo} alt="" />
                                                                Spain
                                                            </Link>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <span className="head">
                                                            Uruguay Vs Portugal <span className="date">31 Jun 2017</span>
                                                        </span>

                                                        <div className="goals-result">
                                                            <Link to="/cricket/single-team">
                                                                <img src={UrugueyClubLogo} alt="" />
                                                                Uruguay
                                                            </Link>

                                                            <span className="goals">
                                                                <b>2</b> - <b>3</b>
                                                                <Link to="/cricket/single-result" className="btn theme">View More</Link>
                                                            </span>

                                                            <Link to="/cricket/single-team">
                                                                <img src={PortugalClubLogo} alt="" />
                                                                    Portugal
                                                            </Link>
                                                        </div>
                                                    </li>

                                                        <li>
                                                        <span className="head">
                                                            Portugal Vs Spain <span className="date">27 Jun 2017</span>
                                                        </span>

                                                        <div className="goals-result">
                                                            <Link to="/cricket/single-team">
                                                                <img src={PortugalClubLogo} alt="" />
                                                                Portugal
                                                            </Link>

                                                            <span className="goals">
                                                                <b>2</b> - <b>3</b>
                                                                <Link to="/cricket/single-result" className="btn theme">View More</Link>
                                                            </span>

                                                            <Link to="/cricket/single-team">
                                                                <img src={SpainClubLogo} alt="" />
                                                                Spain
                                                            </Link>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {<!-- End Tab Theree - results -->} */}

                                </Tab>
                                <Tab eventKey="stats" title="STATS">
                                    {/* {<!-- Tab Theree - stats -->} */}
                                    <div className="tab-pane" id="stats">

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="stats-info">
                                                    <ul>
                                                        <li>
                                                            Matches Played
                                                            <h3>866</h3>
                                                        </li>

                                                        <li>
                                                            Wins
                                                            <h3>328</h3>
                                                        </li>

                                                        <li>
                                                            Losses
                                                            <h3>317</h3>
                                                        </li>

                                                        <li>
                                                            Goals
                                                            <h3>1,188</h3>
                                                        </li>

                                                        <li>
                                                            Goals Conceded
                                                            <h3>1,170</h3>
                                                        </li>

                                                        <li>
                                                            Clean Sheets
                                                            <h3>226</h3>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-xl-4">
                                                {/* {<!-- Attack -->} */}
                                                <div className="panel-box">
                                                    <div className="titles no-margin">
                                                        <h4><i className="fa fa-calendar"></i>Attack</h4>
                                                    </div>
                                                    <ul className="list-panel">
                                                        <li><p>Goals <span>1,188</span></p></li>
                                                        <li><p>Goals Per Match <span>1.37</span></p></li>
                                                        <li><p>Shots <span>4,621</span></p></li>
                                                        <li><p>Shooting Accuracy % <span>32%</span></p></li>
                                                        <li><p>Penalties Scored <span>30</span></p></li>
                                                        <li><p>Big Chances Created <span>293</span></p></li>
                                                        <li><p>Hit Woodwork <span>107</span></p></li>
                                                    </ul>
                                                </div>
                                                {/* {<!-- End Attack -->} */}
                                            </div>

                                            <div className="col-lg-6 col-xl-4">
                                                {/* {<!-- Attack -->} */}
                                                <div className="panel-box">
                                                    <div className="titles no-margin">
                                                        <h4><i className="fa fa-calendar"></i>Team Play</h4>
                                                    </div>
                                                    <ul className="list-panel">
                                                        <li><p>Passes <span>140,417</span></p></li>
                                                        <li><p>Passes Per Match <span>162.14</span></p></li>
                                                        <li><p>Pass Accuracy % <span>76%</span></p></li>
                                                        <li><p>Crosses <span>8,148</span></p></li>
                                                        <li><p>Cross Accuracy % <span>22%</span></p></li>
                                                    </ul>
                                                </div>
                                                {/* {<!-- End Attack -->} */}
                                            </div>

                                            <div className="col-lg-6 col-xl-4">
                                                {/* {<!-- Attack -->} */}
                                                <div className="panel-box">
                                                    <div className="titles no-margin">
                                                        <h4><i className="fa fa-calendar"></i>Defence</h4>
                                                    </div>
                                                    <ul className="list-panel">
                                                        <li><p>Clean Sheets <span>226</span></p></li>
                                                        <li><p>Goals Conceded <span>1,170</span></p></li>
                                                        <li><p>Goals Conceded Per Match <span>1.35</span></p></li>
                                                        <li><p>Saves <span>392</span></p></li>
                                                        <li><p>Tackles <span>7,438</span></p></li>
                                                        <li><p>Tackle Success % <span>75%</span></p></li>
                                                        <li><p>Blocked Shots <span>1,208</span></p></li>
                                                        <li><p>Interceptions <span>5,334</span></p></li>
                                                        <li><p>Clearances <span>11,436</span></p></li>
                                                        <li><p>Headed Clearance <span>3,710</span></p></li>
                                                        <li><p>Aerial Battles/Duels Won <span>25,401</span></p></li>
                                                        <li><p>Errors Leading To Goal <span>59</span></p></li>
                                                        <li><p>Own Goals <span>27</span></p></li>
                                                    </ul>
                                                </div>
                                                {/* {<!-- End Attack -->} */}
                                            </div>
                                        </div>

                                    </div>
                                    {/* {<!-- End Tab Theree - stats -->} */}
                                </Tab>
                            </Tabs>
                            {/* {<!-- Nav Tabs -->} */}
                           
                            {/* {<!-- End Nav Tabs -->} */}
                        </div>

                      
                        {/* {<!-- Side info single team-->} */}
                        <div className="col-lg-3 col-lg-3 padding-top-mini">
                            {/* {<!-- Diary -->} */}
                            <div className="panel-box">
                                <div className="titles">
                                    <h4><i className="fa fa-calendar"></i>Diary</h4>
                                </div>

                                {/* {<!-- List Diary -->} */}
                                <ul className="list-diary">
                                    {/* {<!-- Item List Diary -->} */}
                                    <li>
                                        <h6>GROUP A <span>14 JUN 2022 - 18:00</span></h6>
                                        <ul className="club-logo">
                                            <li>
                                                <img src={RussiaClubLogo} alt="" />
                                                <span>RUSSIA</span>
                                            </li>
                                            <li>
                                                <img src={ArabiaClubLogo} alt="" />
                                                <span>SAUDI ARABIA</span>
                                            </li>
                                        </ul>
                                    </li>
                                    {/* {<!-- End Item List Diary -->} */}

                                    {/* {<!-- Item List Diary -->} */}
                                    <li>
                                        <h6>GROUP E <span>22 JUN 2022 - 15:00</span></h6>
                                        <ul className="club-logo">
                                            <li>
                                                <img src={BrazilClubLogo} alt="" />
                                                <span>BRAZIL</span>
                                            </li>
                                            <li>
                                                <img src={CostaRicaClubLogo} alt="" />
                                                <span>COSTA RICA</span>
                                            </li>
                                        </ul>
                                    </li>
                                    {/* {<!-- End Item List Diary -->} */}

                                    {/* {<!-- Item List Diary -->} */}
                                    <li>
                                        <h6>GROUP H <span>19 JUN 2022 - 15:00</span></h6>
                                        <ul className="club-logo">
                                            <li>
                                                <img src={ColombiaClubLogo} alt="" />
                                                <span>COLOMBIA</span>
                                            </li>
                                            <li>
                                                <img src={JapanClubLogo} alt="" />
                                                <span>JAPAN</span>
                                            </li>
                                        </ul>
                                    </li>
                                    {/* {<!-- End Item List Diary -->} */}

                                    {/* {<!-- Item List Diary -->} */}
                                    <li>
                                        <h6>GROUP C <span>16 JUN 2022 - 15:00</span></h6>
                                        <ul className="club-logo">
                                            <li>
                                                <img src={FranceClubLogo} alt="" />
                                                <span>FRANCE</span>
                                            </li>
                                            <li>
                                                <img src={AustraliaClubLogo} alt="" />
                                                <span>AUSTRALIA</span>
                                            </li>
                                        </ul>
                                    </li>
                                    {/* {<!-- End Item List Diary -->} */}
                                </ul>
                                {/* {<!-- End List Diary -->} */}
                            </div>
                            {/* {<!-- End Diary -->} */}

                            {/* {<!-- Video presentation -->} */}
                            <div className="panel-box">
                                <div className="titles no-margin">
                                    <h4>Presentation</h4>
                                </div>
                                {/* {<!-- Locations Video -->} */}
                                <div className="row">
                                    <iframe src="https://www.youtube.com/embed/AfOlAUd7u4o" className="video"></iframe>
                                    <div className="info-panel">
                                        <h4>Rio de Janeiro</h4>
                                        <p>Lorem ipsum dolor sit amet, sit amet, consectetur adipisicing elit, elit, incididunt ut labore et dolore magna aliqua sit amet, consectetur adipisicing elit,</p>
                                    </div>
                                </div>
                                {/* {<!-- End Locations Video -->} */}
                            </div>
                            {/* {<!-- End Video presentation-->} */}

                            {/* {<!-- Widget Text-->} */}
                            <div className="panel-box">
                                <div className="titles no-margin">
                                    <h4>Widget Image</h4>
                                </div>
                                <img src={SlideOne} alt="" />
                                <div className="row">
                                    <div className="info-panel">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,  ut sit amet, consectetur adipisicing elit, labore et dolore.</p>
                                    </div>
                                </div>
                            </div>
                            {/* {<!-- End Widget Text-->} */}
                        </div>
                        {/* {<!-- end Side info single team-->} */}

                    </div>
                </div>
            </div>
            {/* {<!-- Single Team Tabs -->} */}

            {/* {<!-- Newsletter -->} */}
            <NewsLetter />
            {/* {<!-- End Newsletter -->} */}
        </section>
        {/* {<!-- End Section Area -  Content Central -->} */}

        <div className="instagram-btn">
            <div className="btn-instagram">
                <i className="fa fa-instagram"></i>
                FOLLOW
                <a href="https://www.instagram.com/fifaworldcup/" target="_blank">&#64;fifaworldcup</a>
            </div>
        </div>

        <div className="content-instagram">
            <div id="instafeed"></div>
        </div>
    </>
  );
};

export default SingleTeam;
