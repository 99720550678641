import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.png";
import One from "../../assets/img/blog/1.jpg";
import Two from "../../assets/img/blog/2.jpg";
import Three from "../../assets/img/blog/3.jpg";
import { useTranslation } from "react-i18next";


import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

const TopNavbar1 = (props) => {
  const { t, i18n } = useTranslation();

  let game_slug = localStorage.getItem("game_slug");
  // console.log(game_slug)
  let game_slug_route = ''
  if(game_slug === 'football'){
    game_slug_route = '';
  } else {
    game_slug_route = "/"+game_slug;
  }

  console.log(game_slug_route);
  
  useEffect(() => {
    /*setTimeout(() => {
      i18n.changeLanguage("en");
    }, 10000);
    */

    

  }, []);
  

  return (
    <>
      {/* <!-- Adds nav--> */}
      <div className="col-sm-12 pr-0">
        <Navbar collapseOnSelect expand="xl" bg="" variant="">
          <Navbar.Brand href="/">
            <div className="logo">
              <Link to={`${ game_slug_route +"/"}`} title="Return Home">
                <img src={Logo} alt="Logo" className="logo_img" />
              </Link>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="mainmenu">
            <ul className="ml-auto sf-menu sf-js-enabled sf-arrows">
              <li className="current"><Nav.Link href={`${ game_slug_route +"/"}`}>{t("Home")}</Nav.Link></li>
              <li ><Nav.Link href="#" className="sf-with-ul">{t("WORLD_CUP")}</Nav.Link>
                <div className="sf-mega">
                  <div className="row">
                    <div className="col-md-3">
                      <h5>
                        <i className="fa fa-trophy" aria-hidden="true"></i>World
                        Cup
                      </h5>
                      <ul>
                        <li>
                          <Link to={`${ game_slug_route +"/point-table"}`}>Point Table</Link>
                        </li>
                        <li>
                          <Link to={`${ game_slug_route +"/fixtures"}`}>Fixtures</Link>
                        </li>
                        <li>
                          <Link to={`${ game_slug_route +"/groups"}`}>Groups</Link>
                        </li>
                        <li>
                          <Link to={`${ game_slug_route +"/news"}`}>News</Link>
                        </li>
                        <li>
                          <Link to={`${ game_slug_route +"/contact"}`}>Contact Us</Link>
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-3">
                      <h5>
                        <i className="fa fa-users" aria-hidden="true"></i> {t("TEAMS")}
                        List
                      </h5>
                      <div className="img-hover">
                        <img src={One} alt="" className="img-responsive" />
                        <div className="overlay">
                          <Link to={`${ game_slug_route +"/teams"}`}>+</Link>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <h5>
                        <i className="fa fa-futbol-o" aria-hidden="true"></i>{" "}
                        Players List
                      </h5>
                      <div className="img-hover">
                        <img src={Two} alt="" className="img-responsive" />
                        <div className="overlay">
                          <Link to={`${ game_slug_route +"/players"}`}>+</Link>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <h5>
                        <i className="fa fa-gamepad" aria-hidden="true"></i>{" "}
                        Results Info
                      </h5>
                      <div className="img-hover">
                        <img src={Three} alt="" className="img-responsive" />
                        <div className="overlay">
                          <Link to={`${ game_slug_route +"/results"}`}>+</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </li>
              <li className="current"><Nav.Link href={`${ game_slug_route +"/teams"}`}>{t("TEAMS")}</Nav.Link></li>
              <li className="current"><Nav.Link href={`${ game_slug_route +"/leagues"}`}>{t("Leagues")}</Nav.Link></li>
              <li className="current"><Nav.Link href="#" className="sf-with-ul">{t("Players")}</Nav.Link>
                <ul className="sub-current">
                  <li>
                    <Link to={`${ game_slug_route +"/players"}`}>Players List</Link>
                  </li>
                  <li>
                    <Link to={`${ game_slug_route +"/single-player"}`}>Single Player</Link>
                  </li>
                </ul>

              </li>

              <li className="current"><Nav.Link href={`${ game_slug_route +"/fixtures"}`}>Fixtures</Nav.Link></li>
              <li className="current"><Nav.Link href="#" className="sf-with-ul">Results</Nav.Link>
                <ul className="sub-current">
                  <li>
                    <Link to={`${ game_slug_route +"/results"}`}>Results</Link>
                  </li>
                  <li>
                    <Link to={`${ game_slug_route +"/single-results"}`}>Single Results</Link>
                  </li>
                </ul>

              </li>
              <li className="current"><Nav.Link href={`${ game_slug_route +"/point-table"}`}>Point Table</Nav.Link></li>
              <li className="current"><Nav.Link href={`${ game_slug_route +"/groups"}`}>Groups</Nav.Link></li>
              <li >
                <Link to="#" className="sf-with-ul">Features</Link>
                <div className="sf-mega">
                  <div className="row">
                    <div className="col-sm-6">
                      <h5>Features</h5>
                      <ul>
                        <li>
                          <Link to={`${ game_slug_route +"/faq"}`}>FAQ</Link>
                        </li>
                        <li>
                          <a href="sitemap.html">Sitemap</a>
                        </li>
                        <li>
                          <Link to={`${ game_slug_route +"/pricing"}`}>Pricing</Link>
                        </li>
                        <li>
                          <Link to={`${ game_slug_route +"/register"}`}>Register Form</Link>
                        </li>
                      </ul>
                    </div>


                    <div className="col-sm-6">
                      <h5>Pages</h5>
                      <ul>
                        <li>
                          <Link to={`${ game_slug_route +"/about"}`}>About Us</Link>
                        </li>
                        <li>
                          <Link to={`${ game_slug_route +"/services"}`}>Services</Link>
                        </li>
                        <li>
                          <a href="single-team.html">Club Info</a>
                        </li>
                        <li>
                          <a href="single-result.html">Match Result</a>
                        </li>

                      </ul>
                    </div>


                    <i className="fa fa-trophy big-icon" aria-hidden="true"></i>
                  </div>
                </div>
              </li>


              <li className="current"><Nav.Link href={`${ game_slug_route +"/live-stream"}`}>Live Stream</Nav.Link></li>
              <li className="current"> <Nav.Link href={`${ game_slug_route +"/contact"}`}>Contact</Nav.Link></li>


            </ul>

          </Navbar.Collapse>
        </Navbar>

      </div>
    </>
  );
};

export default TopNavbar1;
