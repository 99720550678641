import HomePage from "../../views/cricket/home/index";
import TeamsPage from "../../views/cricket/team/teams";
import FixturePage from "../../views/cricket/fixture/fixtures";
import ResultsPage from "../../views/cricket/result/results";
import SingleResultPage from "../../views/cricket/singleresult/single-results";
import GroupsPage from "../../views/cricket/groups/groups";
import PointTablePage from "../../views/cricket/pointtable/point-table";
import NewsPage from "../../views/cricket/news/news";
import ContactPage from "../../views/cricket/contact/contact";
import AboutPage from "../../views/cricket/about/about";
import FaqPage from "../../views/cricket/faq/faq";
import RegisterPage from "../../views/cricket/register/register";
import PricingPage from "../../views/cricket/pricing/pricing";
import ServicesPage from "../../views/cricket/services/services";
import SingleNewsPage from "../../views/cricket/singlenews/single-news";
import PlayersPage from "../../views/cricket/players/players";
import SinglePlayerPage from "../../views/cricket/players/single-player";
import SingleTeamPage from "../../views/cricket/team/single-team";
import PredictionContestPage from "../../views/cricket/predictioncontest/prediction-contest";
import LeaguesPage from "../../views/cricket/leagues/leagues";
import PredictionLeaguePage from "../../views/cricket/predictionleague/prediction-league";
import MatchResultsPage from "../../views/cricket/matchresults/match-results";

import IsotopePage from "../../views/cricket/isotope/isotope";
import LiveStreamPage from "../../views/cricket/livestream/live-stream";
import SingleLeaguePage from "../../views/cricket/singleleague/single-league";

const cricketRoutes = [
  {
    component: HomePage,
    exact: true,
    path: "/cricket/",
  },
  {
    component: TeamsPage,
    exact: true,
    path: "/cricket/teams",
  },
  {
    component: FixturePage,
    exact: true,
    path: "/cricket/fixtures",
  },
  {
    component: ResultsPage,
    exact: true,
    path: "/cricket/results",
  },
  {
    component: SingleResultPage,
    exact: true,
    path: "/cricket/single-results",
  },

  {
    component: GroupsPage,
    exact: true,
    path: "/cricket/groups",
  },
  {
    component: PointTablePage,
    exact: true,
    path: "/cricket/point-table",
  },
  {
    component: NewsPage,
    exact: true,
    path: "/cricket/news",
  },
  {
    component: ContactPage,
    exact: true,
    path: "/cricket/contact",
  },
  {
    component: AboutPage,
    exact: true,
    path: "/cricket/about",
  },
  {
    component: FaqPage,
    exact: true,
    path: "/cricket/faq",
  },

  {
    component: RegisterPage,
    exact: true,
    path: "/cricket/register",
  },
  {
    component: PricingPage,
    exact: true,
    path: "/cricket/pricing",
  },
  {
    component: ServicesPage,
    exact: true,
    path: "/cricket/services",
  },
  {
    component: IsotopePage,
    exact: true,
    path: "/cricket/isotope",
  },
  {
    component: PlayersPage,
    exact: true,
    path: "/cricket/players",
  },
  {
    component: SingleNewsPage,
    exact: true,
    path: "/cricket/single-news/:slug",
  },
  {
    component: SinglePlayerPage,
    exact: true,
    path: "/cricket/single-player",
  },
  {
    component: SingleTeamPage,
    exact: true,
    path: "/cricket/single-team",
  },
  {
    component: LiveStreamPage,
    exact: true,
    path: "/cricket/live-stream",
  },
  {
    component: PredictionContestPage,
    exact: true,
    path: "/cricket/prediction-contest",
  },
  {
    component: LeaguesPage,
    exact: true,
    path: "/cricket/leagues",
  },
  {
    component: SingleLeaguePage,
    exact: true,
    path: "/cricket/single-league",
  },
  {
    component: PredictionLeaguePage,
    exact: true,
    path: "/cricket/prediction-league",
  },
  {
    component: MatchResultsPage,
    exact: true,
    path: "/cricket/match-results",
  },
];

export default cricketRoutes;
