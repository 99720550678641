import React from "react";

const Footer = (props) => {
  return (
    <>
      <div className="instagram-btn">
        <div className="btn-instagram">
          <i className="fa fa-instagram"></i>
          FOLLOW
          <a href="https://www.instagram.com/fifaworldcup/" target="_blank">
            &#64;fifaworldcup
          </a>
        </div>
      </div>

      <div className="content-instagram">
        <div id="instafeed"></div>
      </div>
      {/* <!-- footer--> */}
      <footer id="footer">
        {/* <!-- Footer Top--> */}
        <div className="top-footer">
          {/* <!-- Logo Footer--> */}
          <div className="col-lg-12">
            <div className="logo-footer">
              <h2>Sports Cup</h2>
            </div>
          </div>
          {/* <!-- End Logo Footer--> */}

          {/* <!-- Social Icons--> */}
          <ul className="social">
            <li>
              <div>
                <a href="/#" className="facebook">
                  <i className="fa fa-facebook"></i>
                </a>
              </div>
            </li>
            <li>
              <div>
                <a href="/#" className="twitter-icon">
                  <i className="fa fa-twitter"></i>
                </a>
              </div>
            </li>
            <li>
              <div>
                <a href="/#" className="vimeo">
                  <i className="fa fa-vimeo-square"></i>
                </a>
              </div>
            </li>
            <li>
              <div>
                <a href="/#" className="google-plus">
                  <i className="fa fa-google-plus"></i>
                </a>
              </div>
            </li>
            <li>
              <div>
                <a href="/#" className="youtube">
                  <i className="fa fa-youtube"></i>
                </a>
              </div>
            </li>
          </ul>
          {/* <!-- End Social Icons--> */}
        </div>
        {/* <!-- End Footer Top--> */}

        {/* <!-- Links Footer--> */}
        <div className="links-footer">
          <div className="container">
            <div className="row">
              {/* <!-- Column Links --> */}
              <div className="col-sm-6">
                <div className="info-links">
                  <h5>Organisation</h5>
                  <ul>
                    <li>
                      <a href="/#">Volunteers</a>
                    </li>
                    <li>
                      <a href="/#">Committees</a>
                    </li>
                    <li>
                      <a href="/#">Official Documents</a>
                    </li>
                    <li>
                      <a href="/#">Terms of Service</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- End Column Links --> */}

              {/* <!-- Column Links --> */}

              {/* <!-- End Column Links --> */}

              {/* <!-- Column Links --> */}
              <div className="col-sm-6">
                <div className="info-links">
                  <h5>Interest Links</h5>
                  <ul>
                    <li>
                      <a href="/#">Statistics</a>
                    </li>
                    <li>
                      <a href="/#">Teams</a>
                    </li>
                    <li>
                      <a href="/#">Qualifiers</a>
                    </li>
                    <li>
                      <a href="/#">Ticketing</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- End Column Links --> */}

              {/* <!-- Column Links --> */}

              {/* <!-- End Column Links --> */}
            </div>
          </div>
        </div>
        {/* <!-- End Links Footer--> */}
      </footer>
      {/* <!-- End footer--> */}

      {/* <!-- footer Down--> */}
      <div className="footer-down">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>&copy; 2022 SportsCup . All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- footer Down--> */}
    </>
  );
};

export default Footer;
