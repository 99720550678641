import React from "react";
import { Link } from "react-router-dom";
import NewsLetter from "../../../components/news/news-letter";

import SlideOne from "../../../assets/img/cricket/slide/1.jpg";

import WorldCup from "../../../assets/img/cricket/prediction-contest/world-cup.png";
import PremierLeague from "../../../assets/img/cricket/prediction-contest/premier.png";
import EgyptianLeague from "../../../assets/img/cricket/prediction-contest/egyptian.png";

const PredictionContest = (props) => {
  return (
    <>
        {/* {<!-- Section Title -->} */}
        <div className="section-title" style={{background:'url("'+SlideOne+'")'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <h1>Prediction Contest</h1>
                    </div>

                    <div className="col-md-4">
                        <div className="breadcrumbs">
                            <ul>
                                <li><Link to="/cricket/">Home</Link></li>
                                <li>Prediction Contest</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* {<!-- End Section Title -->} */}

        {/* {<!-- Section Area - Content Central -->} */}
        <section className="content-info prediction-lea">

            <div className="container paddings-mini">
                <div className="row">
                    <div className="col-sm-12">
                        <h2 className="text-center mb-4">Select League</h2>
                    </div>
                    <div className="col-md-7 mx-auto">
                        <div className="card-deck">
                            <Link to="/cricket/prediction-league" className="card text-center p-4">
                                <img className="card-img-top mx-auto" src={WorldCup} alt="Card image cap" />
                                <div className="card-body">
                                <h5 className="card-title">World Cup 2022</h5>
                                
                                </div>
                            </Link>
                            <Link to="/cricket/prediction-league" className="card text-center p-4">
                                <img className="card-img-top mx-auto" src={PremierLeague} alt="Card image cap" />
                                <div className="card-body">
                                <h5 className="card-title">Premier League 2022</h5>
                                
                                </div>
                            </Link>
                            <Link to="/cricket/prediction-league" className="card text-center p-4">
                                <img className="card-img-top mx-auto" src={EgyptianLeague} alt="Card image cap" />
                                <div className="card-body">
                                <h5 className="card-title">Egyptian League 2021</h5>
                                
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* {<!-- Newsletter -->} */}
            <NewsLetter />
            {/* {<!-- End Newsletter -->} */}
        </section>
        {/* {<!-- End Section Area -  Content Central -->} */}

        <div className="instagram-btn">
            <div className="btn-instagram">
                <i className="fa fa-instagram"></i>
                FOLLOW
                <a href="https://www.instagram.com/fifaworldcup/" target="_blank">&#64;fifaworldcup</a>
            </div>
        </div>

        <div className="content-instagram">
            <div id="instafeed"></div>
        </div>
    </>
  );
};

export default PredictionContest;
