import axios from "axios";
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Football from "../../assets/img/football/sports/football-icon.png";
import BasketBall from "../../assets/img/football/sports/basketball-icon.png";
import Cricket from "../../assets/img/football/sports/cricket-icon.png";
import Tennis from "../../assets/img/football/sports/tennis-icon.png";

import FootballIcon from "../../assets/img/football/sports/1.jpg";
import BasketBallIcon from "../../assets/img/football/sports/2.jpg";
import CricketIcon from "../../assets/img/football/sports/3.jpg";
import TennisIcon from "../../assets/img/football/sports/4.jpg";

const GamesSection = () => {

	var game_slug = localStorage.getItem("game_slug");

	if(typeof game_slug == "undefined" || game_slug == null ){
		game_slug = "football";
	}

  const [gamesData, setGamesData] = useState([]);
  const apigames = async () => {
    var base_url = "https://sportsbackend.pixeltechnosol.com/api/";
    var response = await axios.get(base_url + "games");

    // console.log(JSON.stringify(response.data.data.items));
    setGamesData(response.data.data.items);

  };

  // Temperary Games Images
  let gamesBackgroundImgs = [{
    'football':{
        'game_icon':Football,
        'backgroundImg':FootballIcon
    },
    'cricket':{
        'game_icon':Cricket,
        'backgroundImg':CricketIcon
    },
    'tennis':{
        'game_icon':Tennis,
        'backgroundImg':TennisIcon
    },
    'basket-ball':{
        'game_icon':BasketBall,
        'backgroundImg':BasketBallIcon
    }
  }];

  const renderGamesData = (games, index) => {
    let selected_game = (game_slug == games.slug) ? true : false;
	if(selected_game){
		return;
	}

	var backgroundImage = gamesBackgroundImgs[0][games.slug].backgroundImg;
	var game_icon = gamesBackgroundImgs[0][games.slug].game_icon;


    return (
      <>
		<div className="col-md-6 col-xl-4 cursor-pointer"
		onClick={(event) => {
			localStorage.setItem('game_slug',games.slug);
			if(games.slug=='football'){
			  window.location.assign('/');
			}else{
			  window.location.assign("/"+games.slug);
			}
		  }}
		>
			<div
				className="item-boxed-img big"
				style={{ background: 'url("' + backgroundImage + '")' }}
			>
				<img src={game_icon} alt={games.name} />
				<h4>{games.name} </h4>
				<p>Best Sports Features</p>
			</div>
		</div>
     </>
    );
  };

  useEffect(() => {
    apigames();
  }, []);

  return (
    <>
		<div className="row no-gutters sport-new-icons">

			{gamesData.map(renderGamesData)}
			
		</div>

    </>
  );
};

export default GamesSection;
