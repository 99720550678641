import React, { useEffect } from "react";
const MobileTopNavbar = (props) => {
  useEffect(() => { }, []);

  return (
    <>
      {/*<!-- Mobile Nav--> */}
      <div id="mobile-nav" className="mm-menu mm-offcanvas"><div className="mm-panels"><div className="mm-panel mm-hasnavbar mm-opened mm-current" id="mm-0"><div className="mm-navbar"><a className="mm-title">Menu</a></div><ul className="mm-listview">
        <li>
          <a className="mm-next" href="#mm-1" data-target="#mm-1"></a><a href="index.html">Home</a>

        </li>

        <li>
          <a className="mm-next" href="#mm-2" data-target="#mm-2"></a><a href="/#">World Cup</a>

        </li>

        <li>
          <a className="mm-next" href="#mm-4" data-target="#mm-4"></a><a href="teams.html">Teams</a>

        </li>

        <li>
          <a className="mm-next" href="#mm-5" data-target="#mm-5"></a><a href="players.html">Players</a>

        </li>

        <li>
          <a href="fixtures.html">Fixtures</a>
        </li>

        <li>
          <a className="mm-next" href="#mm-6" data-target="#mm-6"></a><a href="results.html">Results</a>

        </li>

        <li>
          <a href="table-point.html">Point Table</a>
        </li>

        <li>
          <a href="groups.html">Groups</a>
        </li>

        <li>
          <a className="mm-next" href="#mm-7" data-target="#mm-7"></a><a href="/#">Features</a>

        </li>

        <li>
          <a href="contact.html">Contact</a>
        </li>
      </ul></div><div className="mm-panel mm-hasnavbar mm-hidden" id="mm-1"><div className="mm-navbar"><a className="mm-btn mm-prev" href="#mm-0" data-target="#mm-0"></a><a className="mm-title" href="#mm-0">Home</a></div><ul className="mm-listview">
        <li>
          <a href="index-1.html">Home 1</a>
        </li>
        <li>
          <a href="index-2.html">Home 2</a>
        </li>
        <li>
          <a href="index-3.html">Home 3</a>
        </li>
        <li>
          <a href="index-4.html">Home 4</a>
        </li>
        <li>
          <a href="index-5.html">Home 5</a>
        </li>
        <li>
          <a href="index-6.html">Home 6</a>
        </li>
      </ul></div><div className="mm-panel mm-hasnavbar mm-hidden" id="mm-2"><div className="mm-navbar"><a className="mm-btn mm-prev" href="#mm-0" data-target="#mm-0"></a><a className="mm-title" href="#mm-0">World Cup</a></div><ul className="mm-listview">
        <li>
          <a className="mm-next" href="#mm-3" data-target="#mm-3"></a><a href="/#">World Cup</a>

        </li>

        <li><a href="teams.html">Teams List</a></li>
        <li><a href="players.html">Players List</a></li>
        <li><a href="results.html">Results List</a></li>
      </ul></div><div className="mm-panel mm-hasnavbar mm-hidden" id="mm-3"><div className="mm-navbar"><a className="mm-btn mm-prev" href="#mm-2" data-target="#mm-2"></a><a className="mm-title" href="#mm-2">World Cup</a></div><ul className="mm-listview">
        <li><a href="table-point.html">Point Table</a></li>
        <li><a href="fixtures.html">Fixtures</a></li>
        <li><a href="groups.html">Groups</a></li>
        <li><a href="news-left-sidebar.html">News</a></li>
        <li><a href="contact.html">Contact Us</a></li>
      </ul></div><div className="mm-panel mm-hasnavbar mm-hidden" id="mm-4"><div className="mm-navbar"><a className="mm-btn mm-prev" href="#mm-0" data-target="#mm-0"></a><a className="mm-title" href="#mm-0">Teams</a></div><ul className="mm-listview">
        <li>
          <a href="teams.html">Teams List</a>
        </li>
        <li>
          <a href="single-team.html">Single Team</a>
        </li>
      </ul></div><div className="mm-panel mm-hasnavbar mm-hidden" id="mm-5"><div className="mm-navbar"><a className="mm-btn mm-prev" href="#mm-0" data-target="#mm-0"></a><a className="mm-title" href="#mm-0">Players</a></div><ul className="mm-listview">
        <li>
          <a href="players.html">Players List</a>
        </li>
        <li>
          <a href="single-player.html">Single Player</a>
        </li>
      </ul></div><div className="mm-panel mm-hasnavbar mm-hidden" id="mm-6"><div className="mm-navbar"><a className="mm-btn mm-prev" href="#mm-0" data-target="#mm-0"></a><a className="mm-title" href="#mm-0">Results</a></div><ul className="mm-listview">
        <li>
          <a href="results.html">Results List</a>
        </li>
        <li>
          <a href="single-result.html">Single Result</a>
        </li>
      </ul></div><div className="mm-panel mm-hasnavbar mm-hidden" id="mm-7"><div className="mm-navbar"><a className="mm-btn mm-prev" href="#mm-0" data-target="#mm-0"></a><a className="mm-title" href="#mm-0">Features</a></div><ul className="mm-listview">
        <li>
          <a className="mm-next" href="#mm-8" data-target="#mm-8"></a><a href="/#">Features</a>

        </li>

        <li>
          <a className="mm-next" href="#mm-9" data-target="#mm-9"></a><a href="/#">Headers &amp; Footers</a>

        </li>
        <li>
          <a className="mm-next" href="#mm-10" data-target="#mm-10"></a><a href="/#">Pages</a>

        </li>
        <li>
          <a className="mm-next" href="#mm-11" data-target="#mm-11"></a><a href="/#">News</a>

        </li>
      </ul></div><div className="mm-panel mm-hasnavbar mm-hidden" id="mm-8"><div className="mm-navbar"><a className="mm-btn mm-prev" href="#mm-7" data-target="#mm-7"></a><a className="mm-title" href="#mm-7">Features</a></div><ul className="mm-listview">
        <li><a href="page-full-width.html">Full Width</a></li>
        <li><a href="page-left-sidebar.html">Left Sidebar</a></li>
        <li><a href="page-right-sidebar.html">Right Sidebar</a></li>
        <li><a href="page-404.html">404 Page</a></li>
        <li><a href="page-faq.html">FAQ</a></li>
        <li><a href="sitemap.html">Sitemap</a></li>
        <li><a href="page-pricing.html">Pricing</a></li>
        <li><a href="page-register.html">Register Form</a></li>
      </ul></div><div className="mm-panel mm-hasnavbar mm-hidden" id="mm-9"><div className="mm-navbar"><a className="mm-btn mm-prev" href="#mm-7" data-target="#mm-7"></a><a className="mm-title" href="#mm-7">Headers &amp; Footers</a></div><ul className="mm-listview">
        <li><a href="feature-header-footer-1.html">Header Version 1</a></li>
        <li><a href="feature-header-footer-2.html">Header Version 2</a></li>
        <li><a href="feature-header-footer-3.html">Header Version 3</a></li>
        <li><a href="index-5.html">Header Version 4</a></li>
        <li><a href="feature-header-footer-1.html#footer">Footer Version 1</a></li>
        <li><a href="feature-header-footer-2.html#footer">Footer Version 2</a></li>
        <li><a href="feature-header-footer-3.html#footer">Footer Version 3</a></li>
      </ul></div><div className="mm-panel mm-hasnavbar mm-hidden" id="mm-10"><div className="mm-navbar"><a className="mm-btn mm-prev" href="#mm-7" data-target="#mm-7"></a><a className="mm-title" href="#mm-7">Pages</a></div><ul className="mm-listview">
        <li><a href="page-about.html">About Us</a></li>
        <li><a href="single-player.html">About Me</a></li>
        <li><a href="feature-header-footer-2.html#footer">Services</a></li>
        <li><a href="single-team.html">Club Info</a></li>
        <li><a href="single-result.html">Match Result</a></li>
        <li><a href="table-point.html">Positions</a></li>
      </ul></div><div className="mm-panel mm-hasnavbar mm-hidden" id="mm-11"><div className="mm-navbar"><a className="mm-btn mm-prev" href="#mm-7" data-target="#mm-7"></a><a className="mm-title" href="#mm-7">News</a></div><ul className="mm-listview">
        <li>
          <a href="news-left-sidebar.html">News Lef Sidebar</a>
        </li>
        <li>
          <a href="news-right-sidebar.html">News Right Sidebar</a>
        </li>
        <li>
          <a href="news-no-sidebar.html">News No Sidebar</a>
        </li>
        <li>
          <a href="single-news.html">Single News</a>
        </li>
      </ul></div></div>
      </div>
      {/*<!-- End Mobile Nav--> */}
    </>
  );
};

export default MobileTopNavbar;
