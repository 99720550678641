import React from "react";
import { useParams } from 'react-router';

import NewsLetter from "../../../components/news/news-letter";

import TestimonialOne from "../../../assets/img/cricket/testimonials/1.jpg";
import TestimonialTwo from "../../../assets/img/cricket/testimonials/2.jpg";
import SlideOne from "../../../assets/img/cricket/slide/1.jpg";

import TestimonialThree from "../../../assets/img/cricket/testimonials/3.jpg";
import Categories from "../../../components/categories/categories";
import SingleNewsContents from "../../../components/news/single-news";

const SingleNews = (props) => {

    const params = useParams();
    let news_slug = params.slug;

    let language_code = localStorage.getItem('i18nextLng');
    var game_slug = localStorage.getItem("game_slug");

    if(typeof game_slug == "undefined" || game_slug == null ){
        game_slug = "football";
    }
  return (
    <>
        {/* {<!-- Section Area - Content Central -->} */}
        <section className="content-info">

            <div className="container paddings-mini">
                <div className="row">

                    {/* {<!-- Sidebars -->} */}
                    <aside className="col-lg-3">

                        <div>
                            <h4>Searh Sidebar</h4>
                            <form className="search" action="#" method="Post">
                                <div className="input-group">
                                    <input className="form-control" placeholder="Search..." name="email"  type="email" required="required" />
                                    <span className="input-group-btn">
                                        <button className="btn btn-primary" type="submit" name="subscribe" >Go!</button>
                                    </span>
                                </div>
                            </form>
                        </div>

                        {/* {<!-- Widget Categories-->} */}
                        <div className="panel-box">
                            <div className="titles no-margin">
                                <h4>Categories</h4>
                            </div>
                            <div className="info-panel">
                                <Categories />
                            </div>
                        </div>
                        {/* {<!-- End Widget Categories-->} */}

                        {/* {<!-- Widget Text-->} */}
                        <div className="panel-box">
                            <div className="titles no-margin">
                                <h4>Widget Text</h4>
                            </div>
                            <div className="info-panel">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, incididunt ut labore et dolore.</p>
                            </div>
                        </div>
                        {/* {<!-- End Widget Text-->} */}

                        {/* {<!-- Widget img-->} */}
                        <div className="panel-box">
                            <div className="titles no-margin">
                                <h4>Widget Image</h4>
                            </div>
                            <img src={SlideOne} alt="" />
                            <div className="row">
                                <div className="info-panel">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,  ut sit amet, consectetur adipisicing elit, labore et dolore.</p>
                                </div>
                            </div>
                        </div>
                        {/* {<!-- End Widget img-->} */}
                    </aside>
                    {/* {<!-- End Sidebars -->} */}


                    <div className="col-lg-9">
                        {/* {<!-- Single news ->} */}
                        <SingleNewsContents slug={news_slug} game_id={game_slug} language_id={language_code} />
                        {/* {<!-- End Single news-->} */}

                        {/* {<!-- Comments -->} */}
                        <div className="panel-box">
                            {/* {<!-- Title Post -->} */}
                            <div className="titles">
                                <h4>Comments</h4>
                            </div>
                            {/* {<!-- Title Post -->} */}
                            <ul  className="testimonials">
                                <li>
                                    <blockquote><p>Lionel Messi and Cristiano Ronaldo will come face to face for the first time in the World Cup only if their two nations finish in the same position in the group phase, either first or second.!.</p>
                                    <img src={TestimonialOne} alt="" />
                                    <strong>Federic Gordon</strong><a href="!#">@iwthemes</a></blockquote>
                                </li>
                                <li>
                                    <blockquote><p>After two previous meetings on the big stage, it is honours even: Brazil won the Final of Korea/Japan 2002 2-0, while Germany recorded that famous victory at Brazil 2014!.</p>
                                    <img src={TestimonialTwo} alt="" />
                                    <strong>Federic Gordon</strong><a href="!#">@iwthemes</a></blockquote>
                                </li>
                                <li>
                                    <blockquote><p>To help fire your imagination and let you see if there is a potential knockout match you might be tempted to buy tickets for, FIFA.com has come up with a list of possible last-16 and quarter-final duels between some of the game’s biggest attractions.!.</p>
                                    <img src={TestimonialThree} alt="" />
                                    <strong>Federic Gordon</strong><a href="!#">@iwthemes</a></blockquote>
                                </li>
                            </ul>
                        </div>
                        {/* {<!-- End Comments -->} */}

                        {/* {<!-- Comment Form -->} */}
                        <div className="panel-box padding-b">
                            {/* {<!-- Title Post -->} */}
                            <div className="titles">
                                <h4>Publish Commnet</h4>
                            </div>

                            <div className="info-panel">
                                    {/* {<!-- Form coment -->} */}
                                <form className="form-theme">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Your name *</label>
                                            <input type="text"  required="required" value="" maxlength="100" className="form-control" name="name" id="name" />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Your email address *</label>
                                            <input type="email"  required="required" value="" maxlength="100" className="form-control" name="email" id="email" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <label>Comment *</label>
                                            <textarea maxlength="5000" rows="10" className="form-control" name="comment" id="comment" style={{height: "138px;"}} required="required" ></textarea>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input type="submit" value="Post Comment" className="btn btn-primary" />
                                        </div>
                                    </div>
                                </form>
                                {/* {<!-- End Form coment -->} */}
                            </div>
                        </div>
                        {/* {<!-- End Comment Form -->} */}

                    </div>
                </div>
            </div>

            {/* {<!-- Newsletter -->} */}
            <NewsLetter />
            {/* {<!-- End Newsletter -->} */}
        </section>
        {/* {<!-- End Section Area -  Content Central -->} */}

        <div className="instagram-btn">
            <div className="btn-instagram">
                <i className="fa fa-instagram"></i>
                FOLLOW
                <a href="https://www.instagram.com/fifaworldcup/" target="_blank">&#64;fifaworldcup</a>
            </div>
        </div>

        <div className="content-instagram">
            <div id="instafeed"></div>
        </div>
    </>
  );
};

export default SingleNews;
