import React from "react";
import { Link } from "react-router-dom";
import NewsLetter from "../../../components/news/news-letter";
import Accordion from 'react-bootstrap/Accordion';

import SlideOne from "../../../assets/img/basket-ball/slide/1.jpg";

const FaqPage = (props) => {
  return (
    <>
      {/* {<!-- Section Title -->} */}
      <div className="section-title" style={{ background: 'url("' + SlideOne + '")' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1>Page FAQ</h1>
            </div>

            <div className="col-md-4">
              <div className="breadcrumbs">
                <ul>
                  <li><Link to="/cricket/">Home</Link></li>
                  <li>Inner Page</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {<!-- End Section Title -->} */}

      {/* {<!-- Section Area - Content Central -->} */}
      <section className="content-info">

        {/* {<!-- Content Central -->} */}
        <section className="container">
          <div className="row padding-top">
            {/* {<!-- Accordion -->} */}
            <div className="col-md-12">
              {/* {<!-- Info -->} */}
              <div className="panel-box">
                <div className="titles no-margin">
                  <h4><i className="fa fa-coffee"></i>Who we are</h4>
                </div>
                {/* {<!-- Accorodion Faq -->} */}
                <div className="row">

                  <div className="col-sm-12 px-0">
                    <Accordion defaultActiveKey="0" flush id="accordian-faq" >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Mision</Accordion.Header>
                        <Accordion.Body>
                          Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec non enim in turpis pulvinar facilisis. Ut felis. Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat.

                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Vision</Accordion.Header>
                        <Accordion.Body>
                          Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec non enim in turpis pulvinar facilisis. Ut felis. Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>What we offer?</Accordion.Header>
                        <Accordion.Body>
                          Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec non enim in turpis pulvinar facilisis. Ut felis. Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>Our services</Accordion.Header>
                        <Accordion.Body>
                          Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec non enim in turpis pulvinar facilisis. Ut felis. Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>Features</Accordion.Header>
                        <Accordion.Body>
                          Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec non enim in turpis pulvinar facilisis. Ut felis. Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header>Product Sales</Accordion.Header>
                        <Accordion.Body>
                          Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec non enim in turpis pulvinar facilisis. Ut felis. Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="6">
                        <Accordion.Header >Vision</Accordion.Header>
                        <Accordion.Body>
                          Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec non enim in turpis pulvinar facilisis. Ut felis. Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>

                </div>


                {/* {<!-- End Accorodion Faq -->} */}
              </div>
              {/* {<!-- End Info-->} */}
            </div>
            {/* {<!-- End Accordion -->} */}
          </div>
        </section>
        {/* {<!-- End Content Central -->} */}

        {/* {<!-- Newsletter -->} */}
        <NewsLetter />
        {/* {<!-- End Newsletter -->} */}
      </section>
      {/* {<!-- End Section Area -  Content Central -->} */}

      <div className="instagram-btn">
        <div className="btn-instagram">
          <i className="fa fa-instagram"></i>
          FOLLOW
          <a href="https://www.instagram.com/fifaworldcup/" target="_blank">&#64;fifaworldcup</a>
        </div>
      </div>

      <div className="content-instagram">
        <div id="instafeed"></div>
      </div>

    </>
  );
};

export default FaqPage;
