import React from "react";
import { Link } from "react-router-dom";
import NewsLetter from "../../../components/news/news-letter";

import SlideOne from "../../../assets/img/cricket/slide/1.jpg";
import ColombiaClubLogo from "../../../assets/img/cricket/clubs-logos/colombia.png";
import JapanClubLogo  from "../../../assets/img/cricket/clubs-logos/japan.png";
import PolClubLogo  from "../../../assets/img/cricket/clubs-logos/pol.png";
import SenClubLogo  from "../../../assets/img/cricket/clubs-logos/sen.png";
const Fixtures = (props) => {
    return (
    <>
      {/* <!-- Section Title --> */}
            <div className="section-title" style={{ background: 'url("' + SlideOne + '")' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h1>Fixtures</h1>
                        </div>

                        <div className="col-md-4">
                            <div className="breadcrumbs">
                                <ul>
                                    <li><Link to="/cricket/">Home</Link></li>
                                    <li>Inner Page</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Section Title --> */}

            {/* <!-- Section Area - Content Central --> */}
            <section className="content-info">

                <div className="container paddings-mini">
                    <div className="row">

                        <div className="col-lg-12">
                            <h3 className="clear-title">Matches</h3>
                        </div>

                        <div className="col-lg-12">
                            <table className="table-striped table-responsive table-hover">
                                <thead>
                                    <tr>
                                        <th>Team A</th>
                                        <th className="text-center">VS</th>
                                        <th>Team B</th>
                                        <th>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <img src={ColombiaClubLogo} alt="icon"/>
                                            <strong>Colombia</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td className="text-center">Vs</td>
                                        <td>
                                            <img src={JapanClubLogo} alt="icon1"/>
                                            <strong>Japan</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td>
                                            Jun 19,  07:00<br/>
                                            <small className="meta-text">Mordovia Arena,Saransk</small>
                                        </td>
                                    </tr>
                                    <tr>
                                       <td>
                                            <img src={PolClubLogo} alt="icon1"/>
                                            <strong>Poland</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td className="text-center">Vs</td>
                                        <td>
                                            <img src={ColombiaClubLogo} alt="icon"/>
                                            <strong>Colombia</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td>
                                            Jun 24,  13:00<br/>
                                            <small className="meta-text">Kazan Arena,Kazan</small>
                                        </td>
                                    </tr>
                                    <tr>
                                       <td>
                                            <img src={SenClubLogo} alt="icon1"/>
                                            <strong>Senegal</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td className="text-center">Vs</td>
                                        <td>
                                            <img src={ColombiaClubLogo} alt="icon"/>
                                            <strong>Colombia</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td>
                                            Jun 28, 09:00<br/>
                                            <small className="meta-text">Samara Arena,Samara</small>
                                        </td>
                                    </tr>
                                    <tr>
                                       <td>
                                            <img src={ColombiaClubLogo} alt="icon"/>
                                            <strong>Colombia</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                       </td>
                                       <td className="text-center">Vs</td>
                                       <td>
                                            <img src={PolClubLogo} alt="icon1"/>
                                            <strong>Poland</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td>
                                            Jun 24,  13:00<br/>
                                            <small className="meta-text">Kazan Arena,Kazan</small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={ColombiaClubLogo} alt="icon"/>
                                            <strong>Colombia</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td className="text-center">Vs</td>
                                        <td>
                                            <img src={JapanClubLogo} alt="icon1"/>
                                            <strong>Japan</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td>
                                            Jun 19,  07:00<br/>
                                            <small className="meta-text">Mordovia Arena,Saransk</small>
                                        </td>
                                    </tr>
                                    <tr>
                                       <td>
                                            <img src={PolClubLogo} alt="icon1"/>
                                            <strong>Poland</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td className="text-center">Vs</td>
                                        <td>
                                            <img src={ColombiaClubLogo} alt="icon"/>
                                            <strong>Colombia</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td>
                                            Jun 24,  13:00<br/>
                                            <small className="meta-text">Kazan Arena,Kazan</small>
                                        </td>
                                    </tr>
                                    <tr>
                                       <td>
                                            <img src={SenClubLogo} alt="icon1"/>
                                            <strong>Senegal</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td className="text-center">Vs</td>
                                        <td>
                                            <img src={ColombiaClubLogo} alt="icon"/>
                                            <strong>Colombia</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td>
                                            Jun 28, 09:00<br/>
                                            <small className="meta-text">Samara Arena,Samara</small>
                                        </td>
                                    </tr>
                                    <tr>
                                       <td>
                                            <img src={ColombiaClubLogo} alt="icon"/>
                                            <strong>Colombia</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                       </td>
                                       <td className="text-center">Vs</td>
                                       <td>
                                            <img src={PolClubLogo} alt="icon1"/>
                                            <strong>Poland</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td>
                                            Jun 24,  13:00<br/>
                                            <small className="meta-text">Kazan Arena,Kazan</small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={ColombiaClubLogo} alt="icon"/>
                                            <strong>Colombia</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td className="text-center">Vs</td>
                                        <td>
                                            <img src={JapanClubLogo} alt="icon1"/>
                                            <strong>Japan</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td>
                                            Jun 19,  07:00<br/>
                                            <small className="meta-text">Mordovia Arena,Saransk</small>
                                        </td>
                                    </tr>
                                    <tr>
                                       <td>
                                            <img src={PolClubLogo} alt="icon1"/>
                                            <strong>Poland</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td className="text-center">Vs</td>
                                        <td>
                                            <img src={ColombiaClubLogo} alt="icon"/>
                                            <strong>Colombia</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td>
                                            Jun 24,  13:00<br/>
                                            <small className="meta-text">Kazan Arena,Kazan</small>
                                        </td>
                                    </tr>
                                    <tr>
                                       <td>
                                            <img src={SenClubLogo} alt="icon1"/>
                                            <strong>Senegal</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td className="text-center">Vs</td>
                                        <td>
                                            <img src={ColombiaClubLogo} alt="icon"/>
                                            <strong>Colombia</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td>
                                            Jun 28, 09:00<br/>
                                            <small className="meta-text">Samara Arena,Samara</small>
                                        </td>
                                    </tr>
                                    <tr>
                                       <td>
                                            <img src={ColombiaClubLogo} alt="icon"/>
                                            <strong>Colombia</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                       </td>
                                       <td className="text-center">Vs</td>
                                       <td>
                                            <img src={PolClubLogo} alt="icon1"/>
                                            <strong>Poland</strong><br/>
                                            <small className="meta-text">GROUP H.</small>
                                        </td>
                                        <td>
                                            Jun 24,  13:00<br/>
                                            <small className="meta-text">Kazan Arena,Kazan</small>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {/* <!-- Newsletter --> */}
                <NewsLetter />
               
                {/* <!-- End Newsletter --> */}
            </section>
            {/* <!-- End Section Area -  Content Central --> */}

            <div className="instagram-btn">
                <div className="btn-instagram">
                    <i className="fa fa-instagram"></i>
                    FOLLOW
                    <a href="https://www.instagram.com/fifaworldcup/" target="_blank">&#64;fifaworldcup</a>
                </div>
            </div>

            <div className="content-instagram">
                <div id="instafeed"></div>
            </div>
      
    </>
  );
};

export default Fixtures;
