import Isotope from "isotope-layout";
import React from "react";
import { Link } from "react-router-dom";
import TunisiaClubTeam from "../../assets/img/clubs-teams/tunisia.jpg";
import EnglandClubTeam from "../../assets/img/clubs-teams/england.jpg";
import PolandClubTeam from "../../assets/img/clubs-teams/poland.jpg";
import SenegalClubTeam from "../../assets/img/clubs-teams/senegal.jpg";
import ColombiaClubTeam from "../../assets/img/clubs-teams/colombia.jpg";
import JapanClubTeam from "../../assets/img/clubs-teams/japan.jpg";

import RussiaClubLogo from "../../assets/img/clubs-logos/rusia.png";
import ArabiaClubLogo from "../../assets/img/clubs-logos/arabia.png";
import EgyptClubLogo from "../../assets/img/clubs-logos/egy.png";
import UrugueyClubLogo from "../../assets/img/clubs-logos/uru.png";
import PortagalClubLogo from "../../assets/img/clubs-logos/por.png";
import SpainClubLogo from "../../assets/img/clubs-logos/esp.png";
import MorrocoClubLogo from "../../assets/img/clubs-logos/mar.png";
import IranClubLogo from "../../assets/img/clubs-logos/irn.png";
import FranceClubLogo from "../../assets/img/clubs-logos/fra.png";
import AustraliaClubLogo from "../../assets/img/clubs-logos/aus.png";
import PeruClubLogo from "../../assets/img/clubs-logos/per.png";
import DenmarkClubLogo from "../../assets/img/clubs-logos/den.png";
import ArgentinaClubLogo from "../../assets/img/clubs-logos/arg.png";
import IcelandClubLogo from "../../assets/img/clubs-logos/isl.png";
import CrotiaClubLogo from "../../assets/img/clubs-logos/cro.png";
import NigeriaClubLogo from "../../assets/img/clubs-logos/nga.png";
import BrazilClubLogo from "../../assets/img/clubs-logos/bra.png";
import SwitzerlandClubLogo from "../../assets/img/clubs-logos/sui.png";
import CostaRicaClubLogo from "../../assets/img/clubs-logos/costa-rica.png";
import SerbiaClubLogo from "../../assets/img/clubs-logos/sen.png";
import GermanyClubLogo from "../../assets/img/clubs-logos/ger.png";
import MexicoClubLogo from "../../assets/img/clubs-logos/mex.png";
import SwedonClubLogo from "../../assets/img/clubs-logos/swe.png";
import KoreaClubLogo from "../../assets/img/clubs-logos/kor.png";
import BelgiumClubLogo from "../../assets/img/clubs-logos/bel.png";
import PanamaClubLogo from "../../assets/img/clubs-logos/pan.png";

import TunisiaClubLogo from "../../assets/img/clubs-logos/tun.png";
import EnglanClubLogo from "../../assets/img/clubs-logos/eng.png";
import PolandClubLogo from "../../assets/img/clubs-logos/pol.png";
import SenegalClubLogo from "../../assets/img/clubs-logos/sen.png";
import ColombiaClubLogo from "../../assets/img/clubs-logos/colombia.png";
import JapanClubLogo from "../../assets/img/clubs-logos/japan.png";

import RussiaClubTeam from "../../assets/img/clubs-teams/rusia.jpg";
import ArabiaClubTeam from "../../assets/img/clubs-teams/arabia.jpg";
import EgyptClubTeam from "../../assets/img/clubs-teams/egypt.jpg";
import UrugueyClubTeam from "../../assets/img/clubs-teams/uruguay.jpg";
import PortugalClubTeam from "../../assets/img/clubs-teams/portugal.jpg";
import SpainClubTeam from "../../assets/img/clubs-teams/spain.jpg";
import MorrocoClubTeam from "../../assets/img/clubs-teams/morocco.jpg";
import IranClubTeam from "../../assets/img/clubs-teams/iran.jpg";
import FranceClubTeam from "../../assets/img/clubs-teams/france.jpg";
import AustraliaClubTeam from "../../assets/img/clubs-teams/australia.jpg";
import PeruClubTeam from "../../assets/img/clubs-teams/peru.jpeg";
import DenmarkClubTeam from "../../assets/img/clubs-teams/denmark.jpg";
import ArgentinaClubTeam from "../../assets/img/clubs-teams/argentina.jpg";
import IcelandClubTeam from "../../assets/img/clubs-teams/iceland.jpg";
import CrotiaClubTeam from "../../assets/img/clubs-teams/croatia.jpg";
import NigeriaClubTeam from "../../assets/img/clubs-teams/nigeria.jpg";
import BrazilClubTeam from "../../assets/img/clubs-teams/brazil.jpg";
import SwitzerlandClubTeam from "../../assets/img/clubs-teams/switzerland.jpg";
import CostaRicaClubTeam from "../../assets/img/clubs-teams/costa-rica.jpg";
import SerbiaClubTeam from "../../assets/img/clubs-teams/serbia.jpg";
import GermanyClubTeam from "../../assets/img/clubs-teams/germany.jpg";
import MexicoClubTeam from "../../assets/img/clubs-teams/mexico.jpg";
import SwedonClubTeam from "../../assets/img/clubs-teams/sweden.jpg";
import KoreaClubTeam from "../../assets/img/clubs-teams/corea.jpg";
import BelgiumClubTeam from "../../assets/img/clubs-teams/belgium.jpg";
import PanamaClubTeam from "../../assets/img/clubs-teams/panama.jpg";

const PortfolioFilter = () => {
  // init one ref to store the future isotope object
  const isotope = React.useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = React.useState("*");

  // initialize an Isotope object with configs
  React.useEffect(() => {
    isotope.current = new Isotope(".portfolioContainer", {
      itemSelector: ".filter-item",
      layoutMode: "fitRows",
    });
    // cleanup
    return () => isotope.current.destroy();
  }, []);

  // handling filter key change
  React.useEffect(() => {
    filterKey === "*"
      ? isotope.current.arrange({ filter: `*` })
      : isotope.current.arrange({ filter: `.${filterKey}` });
  }, [filterKey]);

  /**
   * set filter key
   * @param {*} key
   * @returns
   */
  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      {/* <!-- Nav Filters --> */}
      <div className="portfolioFilter">
        <div className="container">
          <h5>
            <i className="fa fa-filter" aria-hidden="true"></i>Filter By:
          </h5>
          <a
            href="javascript:;"
            className={`${filterKey === "*" ? "current" : ""}`}
            onClick={handleFilterKeyChange("*")}
          >
            Show All
          </a>
          <a
            href="javascript:;"
            onClick={handleFilterKeyChange("group-a")}
            className={`${filterKey === "group-a" ? "current" : ""}`}
          >
            Group A
          </a>
          <a
            href="javascript:;"
            className={`${filterKey === "group-b" ? "current" : ""}`}
            onClick={handleFilterKeyChange("group-b")}
          >
            Group B
          </a>
          <a
            href="javascript:;"
            className={`${filterKey === "group-c" ? "current" : ""}`}
            onClick={handleFilterKeyChange("group-c")}
          >
            Group C
          </a>
          <a
            href="javascript:;"
            className={`${filterKey === "group-d" ? "current" : ""}`}
            onClick={handleFilterKeyChange("group-d")}
          >
            Group D
          </a>
          <a
            href="javascript:;"
            className={`${filterKey === "group-e" ? "current" : ""}`}
            onClick={handleFilterKeyChange("group-e")}
          >
            Group E
          </a>
          <a
            href="javascript:;"
            className={`${filterKey === "group-f" ? "current" : ""}`}
            onClick={handleFilterKeyChange("group-f")}
          >
            Group F
          </a>
          <a
            href="javascript:;"
            className={`${filterKey === "group-g" ? "current" : ""}`}
            onClick={handleFilterKeyChange("group-g")}
          >
            Group G
          </a>
          <a
            href="javascript:;"
            className={`${filterKey === "group-h" ? "current" : ""}`}
            onClick={handleFilterKeyChange("group-h")}
          >
            Group H
          </a>
        </div>
      </div>
      {/* <!-- End Nav Filters --> */}

      <div className="container padding-top">
        <div className="row portfolioContainer h-100">
          {/* <!-- Item Team Group A--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-a">
            <div className="item-team">
              <div className="head-team">
                <img src={RussiaClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={RussiaClubLogo} alt="logo-team" />
                </span>
                <h4>Rusia</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group A
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group A--> */}

          {/* <!-- Item Team Group A--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-a">
            <div className="item-team">
              <div className="head-team">
                <img src={ArabiaClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={ArabiaClubLogo} alt="logo-team" />
                </span>
                <h4>Saudi Arabia</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group A
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group A--> */}

          {/* <!-- Item Team Group A--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-a">
            <div className="item-team">
              <div className="head-team">
                <img src={EgyptClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={EgyptClubLogo} alt="logo-team" />
                </span>
                <h4>Egypt</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group A
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group A--> */}

          {/* <!-- Item Team Group A--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-a">
            <div className="item-team">
              <div className="head-team">
                <img src={UrugueyClubTeam} alt="location-team" />
                <div className="overlay">
                  <Link to="/single-team">+</Link>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={UrugueyClubLogo} alt="logo-team" />
                </span>
                <h4>Uruguay</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group A
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group A--> */}

          {/* <!-- Item Team Group B--> */}

          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-b">
            <div className="item-team">
              <div className="head-team">
                <img src={PortugalClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={PortagalClubLogo} alt="logo-team" />
                </span>
                <h4>Portugal</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group B
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group B--> */}

          {/* <!-- Item Team Group B--> */}

          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-b">
            <div className="item-team">
              <div className="head-team">
                <img src={SpainClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={SpainClubLogo} alt="logo-team" />
                </span>
                <h4>Spain</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group B
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group B--> */}

          {/* <!-- Item Team Group B--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-b">
            <div className="item-team">
              <div className="head-team">
                <img src={MorrocoClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={MorrocoClubLogo} alt="logo-team" />
                </span>
                <h4>Morocco</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group B
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group B--> */}

          {/* <!-- Item Team Group B--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-b">
            <div className="item-team">
              <div className="head-team">
                <img src={IranClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={IranClubLogo} alt="logo-team" />
                </span>
                <h4>Ir Iran</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group B
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group B--> */}

          {/* <!-- Item Team Group C--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-c">
            <div className="item-team">
              <div className="head-team">
                <img src={FranceClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={FranceClubLogo} alt="logo-team" />
                </span>
                <h4>France</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group C
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group C--> */}

          {/* <!-- Item Team Group C--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-c">
            <div className="item-team">
              <div className="head-team">
                <img src={AustraliaClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={AustraliaClubLogo} alt="logo-team" />
                </span>
                <h4>Australia</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group C
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group C--> */}

          {/* <!-- Item Team Group C--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-c">
            <div className="item-team">
              <div className="head-team">
                <img src={PeruClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={PeruClubLogo} alt="logo-team" />
                </span>
                <h4>Peru</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group C
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group C--> */}

          {/* <!-- Item Team Group C--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-c">
            <div className="item-team">
              <div className="head-team">
                <img src={DenmarkClubTeam} alt="location-team" />
                <div className="overlay">
                  <Link to="/single-team">+</Link>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={DenmarkClubLogo} alt="logo-team" />
                </span>
                <h4>Denmark</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group C
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group C--> */}

          {/* <!-- Item Team Group D--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-d">
            <div className="item-team">
              <div className="head-team">
                <img src={ArgentinaClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={ArgentinaClubLogo} alt="logo-team" />
                </span>
                <h4>Argentina</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group D
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group D--> */}

          {/* <!-- Item Team Group D--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-d">
            <div className="item-team">
              <div className="head-team">
                <img src={IcelandClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={IcelandClubLogo} alt="logo-team" />
                </span>
                <h4>Iceland</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group D
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group D--> */}

          {/* <!-- Item Team Group D--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-d">
            <div className="item-team">
              <div className="head-team">
                <img src={CrotiaClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={CrotiaClubLogo} alt="logo-team" />
                </span>
                <h4>Croatia</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group D
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group D--> */}

          {/* <!-- Item Team Group D--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-d">
            <div className="item-team">
              <div className="head-team">
                <img src={NigeriaClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={NigeriaClubLogo} alt="logo-team" />
                </span>
                <h4>Nigeria</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group D
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group D--> */}

          {/* <!-- Item Team Group E--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-e">
            <div className="item-team">
              <div className="head-team">
                <img src={BrazilClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={BrazilClubLogo} alt="logo-team" />
                </span>
                <h4>Brazil</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group E
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group E--> */}

          {/* <!-- Item Team Group E--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-e">
            <div className="item-team">
              <div className="head-team">
                <img src={SwitzerlandClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={SwitzerlandClubLogo} alt="logo-team" />
                </span>
                <h4>Switzerland</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group E
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group E--> */}

          {/* <!-- Item Team Group E--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-e">
            <div className="item-team">
              <div className="head-team">
                <img src={CostaRicaClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={CostaRicaClubLogo} alt="logo-team" />
                </span>
                <h4>Costa Rica</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group E
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group E--> */}

          {/* <!-- Item Team Group E--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-e">
            <div className="item-team">
              <div className="head-team">
                <img src={SerbiaClubTeam} alt="location-team" />
                <div className="overlay">
                  <Link to="/single-team">+</Link>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={SerbiaClubLogo} alt="logo-team" />
                </span>
                <h4>Serbia</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group E
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group E--> */}

          {/* <!-- Item Team Group F--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-f">
            <div className="item-team">
              <div className="head-team">
                <img src={GermanyClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={GermanyClubLogo} alt="logo-team" />
                </span>
                <h4>Germany</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group F
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group F--> */}

          {/* <!-- Item Team Group F--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-f">
            <div className="item-team">
              <div className="head-team">
                <img src={MexicoClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={MexicoClubLogo} alt="logo-team" />
                </span>
                <h4>Mexico</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group F
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group F--> */}

          {/* <!-- Item Team Group F--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-f">
            <div className="item-team">
              <div className="head-team">
                <img src={SwedonClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={SwedonClubLogo} alt="logo-team" />
                </span>
                <h4>Sweden</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group F
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group F--> */}

          {/* <!-- Item Team Group F--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-f">
            <div className="item-team">
              <div className="head-team">
                <img src={KoreaClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={KoreaClubLogo} alt="logo-team" />
                </span>
                <h4>Korea Rep</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group F
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group F--> */}

          {/* <!-- Item Team Group G--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-g">
            <div className="item-team">
              <div className="head-team">
                <img src={BelgiumClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={BelgiumClubLogo} alt="logo-team" />
                </span>
                <h4>Belgium</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group G
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group G--> */}

          {/* <!-- Item Team Group G--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-g">
            <div className="item-team">
              <div className="head-team">
                <img src={PanamaClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={PanamaClubLogo} alt="logo-team" />
                </span>
                <h4>Panama</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group G
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group G--> */}

          {/* <!-- Item Team Group G--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-g">
            <div className="item-team">
              <div className="head-team">
                <img src={TunisiaClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={TunisiaClubLogo} alt="logo-team" />
                </span>
                <h4>Tunisia</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group G
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group G--> */}

          {/* <!-- Item Team Group G--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-g">
            <div className="item-team">
              <div className="head-team">
                <img src={EnglandClubTeam} alt="location-team" />
                <div className="overlay">
                  <Link to="/single-team">+</Link>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={EnglanClubLogo} alt="logo-team" />
                </span>
                <h4>England</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group G
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group G--> */}

          {/* <!-- Item Team Group H--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-h">
            <div className="item-team">
              <div className="head-team">
                <img src={PolandClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={PolandClubLogo} alt="logo-team" />
                </span>
                <h4>Poland</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group H
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group H--> */}

          {/* <!-- Item Team Group H--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-h">
            <div className="item-team">
              <div className="head-team">
                <img src={SenegalClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={SenegalClubLogo} alt="logo-team" />
                </span>
                <h4>Senegal</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group H
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group H--> */}

          {/* <!-- Item Team Group H--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-h">
            <div className="item-team">
              <div className="head-team">
                <img src={ColombiaClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={ColombiaClubLogo} alt="logo-team" />
                </span>
                <h4>Colombia</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group H
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group H--> */}

          {/* <!-- Item Team Group H--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-h">
            <div className="item-team">
              <div className="head-team">
                <img src={JapanClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={JapanClubLogo} alt="logo-team" />
                </span>
                <h4>Japan</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group H
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group H--> */}
        </div>
      </div>
    </>
  );
};

export default PortfolioFilter;
