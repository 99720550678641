import React, { Component } from "react";
import Slider from "react-slick";

import LocationOne from "../../assets/img/locations/1.jpg";
import LocationTwo from "../../assets/img/locations/2.jpg";
import LocationThree from "../../assets/img/locations/3.jpg";

import CR_LocationOne from "../../assets/img/cricket/locations/1.jpg";
import CR_LocationTwo from "../../assets/img/cricket/locations/2.jpg";
import CR_LocationThree from "../../assets/img/cricket/locations/3.jpg";


import TN_LocationOne from "../../assets/img/tennis/locations/1.jpg";
import TN_LocationTwo from "../../assets/img/tennis/locations/2.jpg";
import TN_LocationThree from "../../assets/img/tennis/locations/3.jpg";


export default class SimpleSlider extends Component {



    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        var game_slug = localStorage.getItem("game_slug");
        if(typeof game_slug == "undefined" || game_slug == null ){
            game_slug = "football";
        }

        var locations = [{
                "football" : [
                    {
                        "image" : LocationOne,
                        "title" : "Saint Petersburg",
                        "description" : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo cillum dolore eu fugiat nulla sit amet, consectetur adipisicing elit, pariatur."
                    },
                    {
                        "image" : LocationTwo,
                        "title" : "Ekaterinburg",
                        "description" : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo cillum dolore eu fugiat nulla sit amet, consectetur adipisicing elit, pariatur."
                    },
                    {
                        "image" : LocationThree,
                        "title" : "Nizhny Novgorod",
                        "description" : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo cillum dolore eu fugiat nulla pariatur."
                    }
                ],
            
                "cricket" : [
                    {
                        "image" : CR_LocationOne,
                        "title" : "Saint Petersburg",
                        "description" : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo cillum dolore eu fugiat nulla sit amet, consectetur adipisicing elit, pariatur."
                    },
                    {
                        "image" : CR_LocationTwo,
                        "title" : "Ekaterinburg",
                        "description" : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo cillum dolore eu fugiat nulla sit amet, consectetur adipisicing elit, pariatur."
                    },
                    {
                        "image" : CR_LocationThree,
                        "title" : "Nizhny Novgorod",
                        "description" : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo cillum dolore eu fugiat nulla pariatur."
                    }
                ],
                
                "tennis" : [
                    {
                        "image" : TN_LocationOne,
                        "title" : "Saint Petersburg",
                        "description" : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo cillum dolore eu fugiat nulla sit amet, consectetur adipisicing elit, pariatur."
                    },
                    {
                        "image" : TN_LocationTwo,
                        "title" : "Ekaterinburg",
                        "description" : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo cillum dolore eu fugiat nulla sit amet, consectetur adipisicing elit, pariatur."
                    },
                    {
                        "image" : TN_LocationThree,
                        "title" : "Nizhny Novgorod",
                        "description" : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo cillum dolore eu fugiat nulla pariatur."
                    }
                ],
            
                "basket-ball" : [
                    {
                        "image" : CR_LocationOne,
                        "title" : "Saint Petersburg",
                        "description" : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo cillum dolore eu fugiat nulla sit amet, consectetur adipisicing elit, pariatur."
                    },
                    {
                        "image" : CR_LocationTwo,
                        "title" : "Ekaterinburg",
                        "description" : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo cillum dolore eu fugiat nulla sit amet, consectetur adipisicing elit, pariatur."
                    },
                    {
                        "image" : CR_LocationThree,
                        "title" : "Nizhny Novgorod",
                        "description" : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo cillum dolore eu fugiat nulla pariatur."
                    }
                ]
            }];
        


        return (
            <div>
                <Slider {...settings} className="single-carousel">

                    {/* {location_html} */}

                    
                    {locations[0][game_slug].map(locationitem => (  
                        <li>
                            <img src={locationitem.image} alt="" className="img-responsive" />
                            <div className="info-single-carousel">
                                <h4>{locationitem.title}</h4>
                                <p>
                                    {locationitem.description}
                                </p>
                            </div>
                        </li> 
                    ))}  

                    


                    {/* <li>
                        <img src={LocationOne} alt="" className="img-responsive" />
                        <div className="info-single-carousel">
                            <h4>Saint Petersburg</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing
                                elit, incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam,quis nostrud exercitation ullamco
                                laboris nisi ut aliquip ex ea commodo cillum dolore eu
                                fugiat nulla sit amet, consectetur adipisicing elit,
                                pariatur.
                            </p>
                        </div>
                    </li>
                    <li>
                        <img src={LocationTwo} alt="" className="img-responsive" />
                        <div className="info-single-carousel">
                            <h4>Ekaterinburg</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing
                                elit, incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam,quis nostrud exercitation ullamco
                                laboris nisi ut aliquip ex ea commodo cillum dolore eu
                                fugiat nulla pariatur.
                            </p>
                        </div>
                    </li>
                    <li>
                        <img
                            src={LocationThree}
                            alt=""
                            className="img-responsive"
                        />  
                        <div className="info-single-carousel">
                            <h4>Nizhny Novgorod</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing
                                elit, incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam,quis nostrud exercitation ullamco
                                laboris nisi ut aliquip ex ea commodo cillum dolore eu
                                fugiat nulla pariatur.
                            </p>
                        </div>
                    </li><li>
                        <img src={LocationOne} alt="" className="img-responsive" />
                        <div className="info-single-carousel">
                            <h4>Saint Petersburg</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing
                                elit, incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam,quis nostrud exercitation ullamco
                                laboris nisi ut aliquip ex ea commodo cillum dolore eu
                                fugiat nulla sit amet, consectetur adipisicing elit,
                                pariatur.
                            </p>
                        </div>
                    </li>
                    <li>
                        <img src={LocationTwo} alt="" className="img-responsive" />
                        <div className="info-single-carousel">
                            <h4>Ekaterinburg</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing
                                elit, incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam,quis nostrud exercitation ullamco
                                laboris nisi ut aliquip ex ea commodo cillum dolore eu
                                fugiat nulla pariatur.
                            </p>
                        </div>
                    </li>
                    <li>
                        <img
                            src={LocationThree}
                            alt=""
                            className="img-responsive"
                        />
                        <div className="info-single-carousel">
                            <h4>Nizhny Novgorod</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing
                                elit, incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam,quis nostrud exercitation ullamco
                                laboris nisi ut aliquip ex ea commodo cillum dolore eu
                                fugiat nulla pariatur.
                            </p>
                        </div>
                    </li> */}

                </Slider>
            </div>
        );
    }
}