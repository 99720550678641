import HomePage from "../../views/tennis/home/index";
import TeamsPage from "../../views/tennis/team/teams";
import FixturePage from "../../views/tennis/fixture/fixtures";
import ResultsPage from "../../views/tennis/result/results";
import SingleResultPage from "../../views/tennis/singleresult/single-results";
import GroupsPage from "../../views/tennis/groups/groups";
import PointTablePage from "../../views/tennis/pointtable/point-table";
import NewsPage from "../../views/tennis/news/news";
import ContactPage from "../../views/tennis/contact/contact";
import AboutPage from "../../views/tennis/about/about";
import FaqPage from "../../views/tennis/faq/faq";
import RegisterPage from "../../views/tennis/register/register";
import PricingPage from "../../views/tennis/pricing/pricing";
import ServicesPage from "../../views/tennis/services/services";
import SingleNewsPage from "../../views/tennis/singlenews/single-news";
import PlayersPage from "../../views/tennis/players/players";
import SinglePlayerPage from "../../views/tennis/players/single-player";
import SingleTeamPage from "../../views/tennis/team/single-team";
import PredictionContestPage from "../../views/tennis/predictioncontest/prediction-contest";
import LeaguesPage from "../../views/tennis/leagues/leagues";
import PredictionLeaguePage from "../../views/tennis/predictionleague/prediction-league";
import MatchResultsPage from "../../views/tennis/matchresults/match-results";

import IsotopePage from "../../views/tennis/isotope/isotope";
import LiveStreamPage from "../../views/tennis/livestream/live-stream";
import SingleLeaguePage from "../../views/tennis/singleleague/single-league";

const cricketRoutes = [
  {
    component: HomePage,
    exact: true,
    path: "/tennis/",
  },
  {
    component: TeamsPage,
    exact: true,
    path: "/tennis/teams",
  },
  {
    component: FixturePage,
    exact: true,
    path: "/tennis/fixtures",
  },
  {
    component: ResultsPage,
    exact: true,
    path: "/tennis/results",
  },
  {
    component: SingleResultPage,
    exact: true,
    path: "/tennis/single-results",
  },

  {
    component: GroupsPage,
    exact: true,
    path: "/tennis/groups",
  },
  {
    component: PointTablePage,
    exact: true,
    path: "/tennis/point-table",
  },
  {
    component: NewsPage,
    exact: true,
    path: "/tennis/news",
  },
  {
    component: ContactPage,
    exact: true,
    path: "/tennis/contact",
  },
  {
    component: AboutPage,
    exact: true,
    path: "/tennis/about",
  },
  {
    component: FaqPage,
    exact: true,
    path: "/tennis/faq",
  },

  {
    component: RegisterPage,
    exact: true,
    path: "/tennis/register",
  },
  {
    component: PricingPage,
    exact: true,
    path: "/tennis/pricing",
  },
  {
    component: ServicesPage,
    exact: true,
    path: "/tennis/services",
  },
  {
    component: IsotopePage,
    exact: true,
    path: "/tennis/isotope",
  },
  {
    component: PlayersPage,
    exact: true,
    path: "/tennis/players",
  },
  {
    component: SingleNewsPage,
    exact: true,
    path: "/tennis/single-news/:slug",
  },
  {
    component: SinglePlayerPage,
    exact: true,
    path: "/tennis/single-player",
  },
  {
    component: SingleTeamPage,
    exact: true,
    path: "/tennis/single-team",
  },
  {
    component: LiveStreamPage,
    exact: true,
    path: "/tennis/live-stream",
  },
  {
    component: PredictionContestPage,
    exact: true,
    path: "/tennis/prediction-contest",
  },
  {
    component: LeaguesPage,
    exact: true,
    path: "/tennis/leagues",
  },
  {
    component: SingleLeaguePage,
    exact: true,
    path: "/tennis/single-league",
  },
  {
    component: PredictionLeaguePage,
    exact: true,
    path: "/tennis/prediction-league",
  },
  {
    component: MatchResultsPage,
    exact: true,
    path: "/tennis/match-results",
  },
];

export default cricketRoutes;
