import HomePage from "../../views/basket-ball/home/index";
import TeamsPage from "../../views/basket-ball/team/teams";
import FixturePage from "../../views/basket-ball/fixture/fixtures";
import ResultsPage from "../../views/basket-ball/result/results";
import SingleResultPage from "../../views/basket-ball/singleresult/single-results";
import GroupsPage from "../../views/basket-ball/groups/groups";
import PointTablePage from "../../views/basket-ball/pointtable/point-table";
import NewsPage from "../../views/basket-ball/news/news";
import ContactPage from "../../views/basket-ball/contact/contact";
import AboutPage from "../../views/basket-ball/about/about";
import FaqPage from "../../views/basket-ball/faq/faq";
import RegisterPage from "../../views/basket-ball/register/register";
import PricingPage from "../../views/basket-ball/pricing/pricing";
import ServicesPage from "../../views/basket-ball/services/services";
import SingleNewsPage from "../../views/basket-ball/singlenews/single-news";
import PlayersPage from "../../views/basket-ball/players/players";
import SinglePlayerPage from "../../views/basket-ball/players/single-player";
import SingleTeamPage from "../../views/basket-ball/team/single-team";
import PredictionContestPage from "../../views/basket-ball/predictioncontest/prediction-contest";
import LeaguesPage from "../../views/basket-ball/leagues/leagues";
import PredictionLeaguePage from "../../views/basket-ball/predictionleague/prediction-league";
import MatchResultsPage from "../../views/basket-ball/matchresults/match-results";
import IsotopePage from "../../views/basket-ball/isotope/isotope";
import LiveStreamPage from "../../views/basket-ball/livestream/live-stream";
import SingleLeaguePage from "../../views/basket-ball/singleleague/single-league";

const basketBallRoutes = [
  {
    component: HomePage,
    exact: true,
    path: "/basket-ball/",
  },
  {
    component: TeamsPage,
    exact: true,
    path: "/basket-ball/teams",
  },
  {
    component: FixturePage,
    exact: true,
    path: "/basket-ball/fixtures",
  },
  {
    component: ResultsPage,
    exact: true,
    path: "/basket-ball/results",
  },
  {
    component: SingleResultPage,
    exact: true,
    path: "/basket-ball/single-results",
  },
  {
    component: GroupsPage,
    exact: true,
    path: "/basket-ball/groups",
  },
  {
    component: PointTablePage,
    exact: true,
    path: "/basket-ball/point-table",
  },
  {
    component: NewsPage,
    exact: true,
    path: "/basket-ball/news",
  },
  {
    component: ContactPage,
    exact: true,
    path: "/basket-ball/contact",
  },
  {
    component: AboutPage,
    exact: true,
    path: "/basket-ball/about",
  },
  {
    component: FaqPage,
    exact: true,
    path: "/basket-ball/faq",
  },
  {
    component: RegisterPage,
    exact: true,
    path: "/basket-ball/register",
  },
  {
    component: PricingPage,
    exact: true,
    path: "/basket-ball/pricing",
  },
  {
    component: ServicesPage,
    exact: true,
    path: "/basket-ball/services",
  },
  {
    component: IsotopePage,
    exact: true,
    path: "/basket-ball/isotope",
  },
  {
    component: PlayersPage,
    exact: true,
    path: "/basket-ball/players",
  },
  {
    component: SingleNewsPage,
    exact: true,
    path: "/basket-ball/single-news/:slug",
  },
  {
    component: SinglePlayerPage,
    exact: true,
    path: "/basket-ball/single-player",
  },
  {
    component: SingleTeamPage,
    exact: true,
    path: "/basket-ball/single-team",
  },
  {
    component: LiveStreamPage,
    exact: true,
    path: "/basket-ball/live-stream",
  },
  {
    component: PredictionContestPage,
    exact: true,
    path: "/basket-ball/prediction-contest",
  },
  {
    component: LeaguesPage,
    exact: true,
    path: "/basket-ball/leagues",
  },
  {
    component: SingleLeaguePage,
    exact: true,
    path: "/basket-ball/single-league",
  },
  {
    component: PredictionLeaguePage,
    exact: true,
    path: "/basket-ball/prediction-league",
  },
  {
    component: MatchResultsPage,
    exact: true,
    path: "/basket-ball/match-results",
  },
];

export default basketBallRoutes;
