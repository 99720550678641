import axios from "axios";
import Slider from "react-slick";

import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Countdown from 'react-countdown';

import Slide1 from "../../assets/img/slide/1.jpg";
import Slide2 from "../../assets/img/slide/2.jpg";
import Slide3 from "../../assets/img/slide/3.jpg";

import ColombiaClubLogo from "../../assets/img/clubs-logos/colombia.png";
import RussiaClubLogo from "../../assets/img/clubs-logos/rusia.png";

const Completionist = () => <span>You are good to go!</span>;

// export default class HeroSlider extends Component 
const HeroSlider = ({ game_slug, language_code }) => {

    const [sliderData, setSliderData] = useState([]);
    const apislider = async () => {
  
      var base_url = "https://sportsbackend.pixeltechnosol.com/api/";
      var response = await axios.get(base_url+'slider/'+game_slug+'/'+language_code);
  
      // console.log(JSON.stringify(response.data.data.items));
      setSliderData(response.data.data.items)
    }

    // Temperary Slide Images
    let slidesImgs = [Slide1, Slide2, Slide3];

    const renderSliderData = (slider, index) => {
        const renderer = ({ hours, minutes, seconds, completed }) => {
            if (completed) {
                // Render a completed state
                return <Completionist />;
            } else {
                // Render a countdown
                return <div> <span>00 <br /> <small>days</small> </span> <span>{hours} <br /> <small>hr</small> </span>  <span>{minutes} <br /> <small>min</small> </span>  <span>{seconds} <br /> <small>sec</small></span> </div>;
            }
        };

        return (
          <>
           <div className="slider-item">
                <div
                className="hero-header hero-bg"
                style={{ background: 'url("' + slidesImgs[index]+ '")' }}
            >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 no-margin">
                                <h1>{slider.contents}</h1>
                            </div>
                            <div className="col-lg-12">
                                <div className="box-start">
                                    <div className="row align-items-center">
                                        <div className="col-lg-4">
                                            <div className="info-match">
                                                <div className="row">
                                                    <div className="col">
                                                        <h4>
                                                            <span>Starting Date</span>
                                                            30 JAN 2022
                                                        </h4>
                                                    </div>
                                                    <div className="col">
                                                        <h4>
                                                            <span>Luzhniki Stadium</span>
                                                            Moscow
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="horizontal-next-teams">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <Link to="/single-team">
                                                            <img src={ColombiaClubLogo} alt="" />
                                                            <span>Colombia</span>
                                                        </Link>
                                                    </div>
                                                    <div className="col">
                                                        <strong>Vs</strong>
                                                    </div>
                                                    <div className="col">
                                                        <Link to="/single-team">
                                                            <img src={RussiaClubLogo} alt="" />
                                                            <span>Rusia</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="horizontal-next-counter">
                                                <div id="event-one" className="counter">
                                                    <Countdown
                                                        date={Date.now() + 25000 * 24 * 356}
                                                        renderer={renderer}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </>
        );
    
    }

    useEffect(() => {
        apislider();
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (

        <Slider {...settings} className="hero-slider">
            {sliderData.map(renderSliderData)}
        </Slider>
        
    );
    
}

export default HeroSlider;
