import axios from "axios";
import { React, useEffect, useState } from "react";

const GamesDropdown = () => {

  let game_slug = localStorage.getItem("game_slug");
  // console.log(game_slug)

  const [gamesData, setGamesData] = useState([]);
  const apigames = async () => {
    var base_url = "https://sportsbackend.pixeltechnosol.com/api/";
    var response = await axios.get(base_url + "games");

    // console.log(JSON.stringify(response.data.data.items));
    setGamesData(response.data.data.items);

    /*
      saveGame({
        data: {
        },
        onSuccess: (response) => {
        },
        onError: (error) => {
          
        },
      });

      deleteGame({
        data: {
        },
        onSuccess: (response) => {
        },
        onError: (error) => {
          
        },
      });

      getGames({
        data: {
        },
        onSuccess: (response) => {
        },
        onError: (error) => {
          
        },
      });

      */
  };

  const renderGamesData = (games, index) => {
    let selected_game = (game_slug == games.slug) ? "selected" : "";
    return (
      <>
        
        <option
          selected={selected_game}
          value={games.slug}>{games.name}</option>
      </>
    );
  };

  useEffect(() => {
    apigames();
  }, []);

  return (
    <>
      <label htmlFor="exampleFormControlSelect1">Select Game</label>
      <select className="form-control"
      onChange={(event) => {
        console.log(event.target.value);
        localStorage.setItem('game_slug',event.target.value);
        if(event.target.value=='football'){
          window.location.assign('/');
        }else{
          window.location.assign(event.target.value);
        }
      }}
      >
        {gamesData.map(renderGamesData)}
      </select>
    </>
  );
};

export default GamesDropdown;
