import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


import BlogOne from "../../assets/img/blog/1.jpg";
// import axiosInstance from "../../axios-instance";

const RecentNews = ({ limit,game_id,language_id }) => {

  const [newsData, setNewsData] = useState([]);
  const apinews = async () => {

    // console.log(axiosInstance.create);

    var base_url = "https://sportsbackend.pixeltechnosol.com/api/";
    limit = (limit > 0) ?  limit : 0;
    var response = await axios.get(base_url+'news/'+limit+'/'+game_id+'/'+language_id);

    // console.log(JSON.stringify(response.data.data.items));
    setNewsData(response.data.data.items);

  }

  const renderNewsData = (news, index) => {

    return (
      <>
      {/* <!-- Post Item --> */}
      <div className="post-item">
        <div className="row">
          <div className="col-md-4">
            <div className="img-hover">
              <img src={BlogOne} alt="" className="img-responsive" />
              <div className="overlay">
                <Link to={`/single-news/${news.slug}`}>+</Link>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <h5>
              <Link to={`/single-news/${news.slug}`}>{news.title}</Link>
            </h5>
            <span className="data-info">
              January 3, 2014 / <i className="fa fa-comments"></i>
              <a href="/#">0</a>
            </span>
            <p>
              {news.description}
              <Link to={`/single-news/${news.slug}`}>Read More [+]</Link>
            </p>
          </div>
        </div>
      </div>
      {/* <!-- End Post Item --> */}
    </>
    );

  } 

  useEffect(() => {
    apinews();
  }, []);

  

  return (

    <>
      {/* <!-- Recent Post --> */}
      <div className="panel-box">
        <div className="titles">
          <h4>Recent News</h4>
        </div>

        {newsData.map(renderNewsData)}
        {/* {console.log(renderNewsData)} */}

      </div>
      {/* <!-- End Recent Post --> */}
    </>
  );
};

export default RecentNews;
