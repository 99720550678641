import React from "react";
import { Link } from "react-router-dom";
import NewsLetter from "../../../components/news/news-letter";
import RecentNews from "../../../components/news/recent-news";
import SlideOne from "../../../assets/img/football/slide/1.jpg";

import EuropeanChampionshipQualifiers  from "../../../assets/img/football/leagues/images1.png";
import UEFAChampionsLeague  from "../../../assets/img/football/leagues/images2.png";
import AfricanChampionsLeague  from "../../../assets/img/football/leagues/images3.png";
import FIBABasketballWorldCupQualifiers  from "../../../assets/img/football/leagues/images4.png";
import UEFANationsLeague  from "../../../assets/img/football/leagues/images5.png";
import AfricaChampionshipQualifiers  from "../../../assets/img/football/leagues/images6.jpg";
import SerieA  from "../../../assets/img/football/leagues/images7.jpg";
import LaLiga  from "../../../assets/img/football/leagues/images8.jpg";
import PremierLeague  from "../../../assets/img/football/leagues/images9.png";
import TurkishLeague  from "../../../assets/img/football/leagues/images10.png";
import Bundesliga  from "../../../assets/img/football/leagues/images11.png";
import FrenchLeague  from "../../../assets/img/football/leagues/images12.png";

const LeaguesPage = (props) => {

    let language_code = localStorage.getItem('i18nextLng');
    var game_slug = localStorage.getItem("game_slug");

    if(typeof game_slug == "undefined" || game_slug == null ){
        game_slug = "football";
    }

  return (
    <>
        {/* {<!-- Section Title -->} */}
        <div className="section-title" style={{background:'url("'+SlideOne+'")'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <h1>League</h1>
                    </div>

                    <div className="col-md-4">
                        <div className="breadcrumbs">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>League</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* {<!-- End Section Title -->} */}

        {/* {<!-- Section Area - Content Central -->} */}
        <section className="content-info">

            <div className="container paddings-mini">
                <div className="row">
                    {/* {<!-- Sidebars -->} */}
                    <aside className="col-lg-3">

                        <div className="panel-box">
                            <div className="titles no-margin">
                                <h4>News that interests you</h4>
                            </div>
                            <div className="info-panel">
                                <ul className="list htn">
                                    <li><i className="fa fa-arrow-right"></i><Link to="#">Between the eagles of Al-Ahli and the lions of Morocco. How did the heads of the Arabs rise on their lands?</Link></li>
                                    <li><i className="fa fa-arrow-right"></i><Link to="#">Walker and Stones adopted her and she will travel to live in London. The story of the luckiest cat in World Cup (video)</Link></li>
                                    <li><i className="fa fa-arrow-right"></i><Link to="#">"The best but not the hero." A sentence that Messi wishes not to repeat</Link></li>
                                    <li><i className="fa fa-arrow-right"></i><Link to="#">Reports: UAE to host Club World Cup in February</Link></li>
                                    <li><i className="fa fa-arrow-right"></i><Link to="#">Yalla Kora reveals the position of Ammar Hamdi, Safio and Taher from the match between Al-Ahli and Future</Link></li>
                                </ul>
                            </div>
                        </div>

                        
                        
                    </aside>
                    {/* {<!-- End Sidebars -->} */}

                    <div className="col-lg-9">
                        {/* {<!-- Content Text-->} */}
                        <div className="panel-box padding-b ">
                            <div className="titles">
                                <h4>Continental</h4>
                            </div>

                            <div className="col-md-12 mx-auto">
                                <div className="card-deck league-maser justify-content-center">
                                    <Link to="/single-league" className="card text-center p-3">
                                        <img className="card-img-top mx-auto" src={EuropeanChampionshipQualifiers} alt="Card image cap" />
                                        <div className="card-body">
                                            <h5 className="card-title">European Championship Qualifiers</h5>
                                        </div>
                                    </Link>
                                    <Link to="/single-league" className="card text-center p-3">
                                        <img className="card-img-top mx-auto" src={UEFAChampionsLeague} alt="Card image cap" />
                                        <div className="card-body">
                                            <h5 className="card-title">UEFA Champions League</h5>
                                        </div>
                                    </Link>
                                    <Link to="/single-league" className="card text-center p-3">
                                        <img className="card-img-top mx-auto" src={AfricanChampionsLeague} alt="Card image cap" />
                                        <div className="card-body">
                                            <h5 className="card-title">African Champions League</h5>
                                        </div>
                                    </Link>
                                    <Link to="/single-league" className="card text-center p-3">
                                        <img className="card-img-top mx-auto" src={FIBABasketballWorldCupQualifiers} alt="Card image cap" />
                                        <div className="card-body">
                                            <h5 className="card-title">FIBA Basketball World Cup Qualifiers - Africa</h5>
                                        </div>
                                    </Link>
                                    <Link to="/single-league" className="card text-center p-3">
                                        <img className="card-img-top mx-auto" src={UEFANationsLeague} alt="Card image cap" />
                                        <div className="card-body">
                                            <h5 className="card-title">UEFA Nations League</h5>
                                        </div>
                                    </Link>
                                    <Link to="/single-league" className="card text-center p-3">
                                        <img className="card-img-top mx-auto" src={AfricaChampionshipQualifiers} alt="Card image cap" />
                                        <div className="card-body">
                                            <h5 className="card-title">Africa U-23 Championship Qualifiers</h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="panel-box padding-b ">
                            <div className="titles">
                                <h4>European</h4>
                            </div>
            
                            <div className="col-md-12 mx-auto">
                                <div className="card-deck league-maser justify-content-center">
                                    <Link to="/single-league" className="card text-center p-3">
                                        <img className="card-img-top mx-auto" src={SerieA} alt="Card image cap" />
                                        <div className="card-body">
                                            <h5 className="card-title">Serie A</h5>
                                        </div>
                                    </Link>
                                    <Link to="/single-league" className="card text-center p-3">
                                        <img className="card-img-top mx-auto" src={LaLiga} alt="Card image cap" />
                                        <div className="card-body">
                                            <h5 className="card-title">La Liga</h5>
                                        </div>
                                    </Link>
                                    <Link to="/single-league" className="card text-center p-3">
                                        <img className="card-img-top mx-auto" src={PremierLeague} alt="Card image cap" />
                                        <div className="card-body">
                                            <h5 className="card-title">Premier League</h5>
                                        </div>
                                    </Link>
                                    <Link to="/single-league" className="card text-center p-3">
                                        <img className="card-img-top mx-auto" src={TurkishLeague} alt="Card image cap" />
                                        <div className="card-body">
                                            <h5 className="card-title">Turkish League</h5>
                                        </div>
                                    </Link>
                                    <Link to="/single-league" className="card text-center p-3">
                                        <img className="card-img-top mx-auto" src={Bundesliga} alt="Card image cap" />
                                        <div className="card-body">
                                            <h5 className="card-title">Bundesliga</h5>
                                        </div>
                                    </Link>
                                    <Link to="/single-league" className="card text-center p-3">
                                        <img className="card-img-top mx-auto" src={FrenchLeague} alt="Card image cap" />
                                        <div className="card-body">
                                            <h5 className="card-title">French League</h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                            
                        {/* recent news section start */}
                        <RecentNews limit={3} game_id={game_slug} language_id={language_code} />

                        {/* {recent news section end} */}

                        {/* {<!-- End Content Text-->} */}
                    </div>
                </div>
            </div>

            {/* {<!-- Newsletter -->} */}
            <NewsLetter />
            {/* {<!-- End Newsletter -->} */}
        </section>
        {/* {<!-- End Section Area -  Content Central -->} */}
    </>
  );
};

export default LeaguesPage;
