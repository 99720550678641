import axios from "axios";
import { React, useEffect, useState } from "react";

const Categories = () => {

    const [categoriesData, setCategoriesData] = useState([]);
    const apicategories = async () => {
  
      var base_url = "https://sportsbackend.pixeltechnosol.com/api/";
      var response = await axios.get(base_url+'categories');
  
      // console.log(JSON.stringify(response.data.data.items));
      setCategoriesData(response.data.data.items);
  
    }

    const renderCategoriesData = (categories, index) => {
        return (
          <>
          <li><i className="fa fa-check"></i><a href={categories.slug}>{categories.name}</a></li>
         </>
        );
    
    }

    useEffect(() => {
        apicategories();
    }, []);

  

  return (

    <>
        <ul className="list">
            {categoriesData.map(renderCategoriesData)}
        </ul>
    </>
  );
};

export default Categories;
