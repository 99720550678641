import React from "react";
import { Link } from "react-router-dom";
import NewsLetter from "../../../components/news/news-letter";
import MatchesSlider from "../../../components/matchesslider/matchesslider";
import MatchesTeamSlider from "../../../components/matchesteamslider/matchesteamslider";
import SlideOne from "../../../assets/img/football/slide/1.jpg";

import rusiaClubLogos from "../../../assets/img/football/clubs-logos/rusia.png";
import braClubLogos from "../../../assets/img/football/clubs-logos/bra.png";
import costaClubLogos from "../../../assets/img/football/clubs-logos/costa-rica.png";
import colombiaClubLogos from "../../../assets/img/football/clubs-logos/colombia.png";
import japanClubLogos from "../../../assets/img/football/clubs-logos/japan.png";
import fraClubLogos from "../../../assets/img/football/clubs-logos/fra.png";
import ausClubLogos from "../../../assets/img/football/clubs-logos/aus.png";
import argClubLogos from "../../../assets/img/football/clubs-logos/arg.png";
import senClubLogos from "../../../assets/img/football/clubs-logos/sen.png";
import irnClubLogos from "../../../assets/img/football/clubs-logos/irn.png";
import espClubLogos from "../../../assets/img/football/clubs-logos/esp.png";
import arabiaClubLogos from "../../../assets/img/football/clubs-logos/arabia.png";
import egyClubLogos from "../../../assets/img/football/clubs-logos/egy.png";
import marClubLogos from "../../../assets/img/football/clubs-logos/mar.png";
import porClubLogos from "../../../assets/img/football/clubs-logos/por.png";
import uruClubLogos from "../../../assets/img/football/clubs-logos/uru.png";

import BlogImage1 from "../../../assets/img/football/blog/1.jpg";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const SingleLeague = (props) => {

    return (
        <>
            {/* {<!-- Section Title -->} */}
            <div className="section-title" style={{background:'url("'+SlideOne+'")'}}>
                <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <h1>League Inner Page</h1>
                    </div>
                    <div className="col-md-4">
                        <div className="breadcrumbs">
                            <ul>
                            <li><a href="index.html">Home</a></li>
                            <li>League Inner Page</li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            {/* {<!-- End Section Title -->} */}
            <div className="container if-tabs">
                <div className="row">
                <div className="col-sm-12">
                    <ul className="d-flex justify-content-center flex-row pt-4" id="myDIV">
                        <li className="bv"><a href="#important-news">Important News</a></li>
                        <li className="bv"><a href="#tab-table-matchs">Group Matches</a></li>
                        <li className="bv"><a href="#group-details">Group Details</a></li>
                        <li className="bv"><a href="#latest-news">Recent News</a></li>
                        <li className="bv"><a href="#statics-uy">Statistics</a></li>
                        <li className="bv"><a href="#clb-vdr"> Club Videos</a></li>
                    </ul>
                </div>
                </div>
            </div>
            {/* {<!-- Section Area - Content Central -->} */}
            <section className="content-info">
                <div className="paddings-mini">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* {<!--Sponsors CLub -->} */}
                            <div className="row no-line-height">
                            <div className="col-md-12">
                                <h3 className="clear-title">Matches</h3>
                            </div>
                            </div>
                            {/* {<!--End Sponsors CLub -->} */}
                            <MatchesSlider />
                        </div>
                    </div>
                </div>
                </div>
                <div className="paddings-mini">
                    <div className="container px-5">
                        <div className="row">
                            <div className="col-lg-12">
                                <MatchesTeamSlider />    
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container paddings-mini">
                <div className="row">
                    {/* {<!-- Sidebars -->} */}
                    <aside className="col-lg-4">
                        <div className="panel-box">
                            <div className="titles">
                            <h4><i className="fa fa-calendar"></i>Matches</h4>
                            </div>
                            {/* {<!-- List Diary -->} */}
                            <ul className="list-diary">
                            {/* {<!-- Item List Diary -->} */}
                            <li>
                                <h6>GROUP A <span>14 JUN 2022 - 18:00</span></h6>
                                <ul className="club-logo">
                                    <li>
                                        <img src={rusiaClubLogos} alt="" />
                                        <span>RUSSIA</span>
                                    </li>
                                    <li>
                                        <img src={arabiaClubLogos} alt="" />
                                        <span>SAUDI ARABIA</span>
                                    </li>
                                </ul>
                            </li>
                            {/* {<!-- End Item List Diary -->} */}
                            {/* {<!-- Item List Diary -->} */}
                            <li>
                                <h6>GROUP E <span>22 JUN 2022 - 15:00</span></h6>
                                <ul className="club-logo">
                                    <li>
                                        <img src={braClubLogos} alt="" />
                                        <span>BRAZIL</span>
                                    </li>
                                    <li>
                                        <img src={costaClubLogos} alt="" />
                                        <span>COSTA RICA</span>
                                    </li>
                                </ul>
                            </li>
                            {/* {<!-- End Item List Diary -->} */}
                            {/* {<!-- Item List Diary -->} */}
                            <li>
                                <h6>GROUP H <span>19 JUN 2022 - 15:00</span></h6>
                                <ul className="club-logo">
                                    <li>
                                        <img src={colombiaClubLogos} alt="" />
                                        <span>COLOMBIA</span>
                                    </li>
                                    <li>
                                        <img src={japanClubLogos} alt="" />
                                        <span>JAPAN</span>
                                    </li>
                                </ul>
                            </li>
                            {/* {<!-- End Item List Diary -->} */}
                            {/* {<!-- Item List Diary -->} */}
                            <li>
                                <h6>GROUP C <span>16 JUN 2022 - 15:00</span></h6>
                                <ul className="club-logo">
                                    <li>
                                        <img src={fraClubLogos} alt="" />
                                        <span>FRANCE</span>
                                    </li>
                                    <li>
                                        <img src={ausClubLogos} alt="" />
                                        <span>AUSTRALIA</span>
                                    </li>
                                </ul>
                            </li>
                            {/* {<!-- End Item List Diary -->} */}
                            </ul>
                            {/* {<!-- End List Diary -->} */}
                        </div>
                        <div id="important-news"></div>
                        <div className="panel-box">
                            <div className="titles no-margin">
                            <h4>Important News</h4>
                            </div>
                            <div className="info-panel">
                            <ul className="list htn">
                                <li><i className="fa fa-arrow-right"></i><a href="!#">Between the eagles of Al-Ahli and the lions of Morocco. How did the heads of the Arabs rise on their lands?</a></li>
                                <li><i className="fa fa-arrow-right"></i><a href="!#">Walker and Stones adopted her and she will travel to live in London. The story of the luckiest cat in World Cup (video)</a></li>
                                <li><i className="fa fa-arrow-right"></i><a href="!#">"The best but not the hero." A sentence that Messi wishes not to repeat</a></li>
                                <li><i className="fa fa-arrow-right"></i><a href="!#">Reports: UAE to host Club World Cup in February</a></li>
                                <li><i className="fa fa-arrow-right"></i><a href="!#">Yalla Kora reveals the position of Ammar Hamdi, Safio and Taher from the match between Al-Ahli and Future</a></li>
                            </ul>
                            </div>
                        </div>
                    </aside>
                    {/* {<!-- End Sidebars -->} */}
                    <div className="col-lg-8">
                        {/* {<!-- Content Text-->} */}
                        <div className="panel-box blog-type">
                            <a href="!#">
                            <img src={BlogImage1} alt="" />
                            <h2>Fans from all around the world can apply for 2022 FIFA World Cup™ tickets as the first window of sales.</h2>
                            </a>
                        </div>
                        <div className="panel-box">
                            {/* {<!-- Post Item -->} */}
                            <div className="post-item pl-0 pb-0">
                            <div className="row">
                                <div className="col-md-4 pl-0">
                                    <div className="img-hover">
                                        <img src={BlogImage1} alt="" className="img-responsive" />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <h5><Link to="/single-news">Group Stage Breakdown</Link></h5>
                                    <span className="data-info">January 3, 2014  / <i className="fa fa-comments"></i><a href="!#">0</a></span>
                                    <p>While familiar with fellow European nation France, Hareide admits that South American side Peru.<a href="!#">Read More [+]</a></p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="panel-box">
                            {/* {<!-- Post Item -->} */}
                            <div className="post-item pl-0 pb-0">
                            <div className="row">
                                <div className="col-md-4 pl-0">
                                    <div className="img-hover">
                                        <img src={BlogImage1} alt="" className="img-responsive" />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <h5><Link to="/single-news">Group Stage Breakdown</Link></h5>
                                    <span className="data-info">January 3, 2014  / <i className="fa fa-comments"></i><a href="!#">0</a></span>
                                    <p>While familiar with fellow European nation France, Hareide admits that South American side Peru.<a href="!#">Read More [+]</a></p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div id="tab-table-matchs"></div>
                        <div className="panel-box" >
                            <div className="container-fluid px-0">
                            <div className="row">
                                {/* {<!-- Top player -->} */}
                                <div className="col-lg-12 px-0">
                                    {/* <ul className="nav nav-tabs mb-4" id="myTab">
                                        <li className="active"><a href="#grp1" data-toggle="tab">Group 1</a></li>
                                        <li><a href="#grp2" data-toggle="tab">Group 2</a></li>
                                        <li><a href="#grp3" data-toggle="tab">Group 3</a></li>
                                    </ul> */}

                                    <Tabs defaultActiveKey="grp1" id="uncontrolled-tab-example" className="nav nav-tabs mb-4">

                                    {/* {<!-- End Nav Tabs -->} */}
                                    {/* {<!-- Content Tabs -->} */}
                                    {/* <div className="tab-content"> */}
                                        {/* {<!-- Tab Theree - statistics -->} */}
                                        <Tab eventKey="grp1" title="Group 1">
                                            <div className="tab-pane active" id="grp1">
                                                <div className="row">
                                                    <div className="col-sm-12 px-0">
                                                        <div className="titles no-margin border-0">
                                                            <h4>Points Table</h4>
                                                        </div>
                                                        <table className="table-striped table-responsive table-hover result-point small">
                                                            <thead className="point-table-head">
                                                            <tr>
                                                                <th className="text-left">No</th>
                                                                <th className="text-left">TEAM</th>
                                                                <th className="text-center">P</th>
                                                                <th className="text-center">W</th>
                                                                <th className="text-center">D</th>
                                                                <th className="text-center">PTS</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="text-center">
                                                            <tr>
                                                                <td className="text-left number">1 <i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                                                <td className="text-left">
                                                                    <a href="!#">
                                                                    <img src={colombiaClubLogos} alt="Colombia" /><span>Colombia</span>
                                                                    </a>
                                                                </td>
                                                                <td>38</td>
                                                                <td>26</td>
                                                                <td>9</td>
                                                                <td>3</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left number">2 <i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                                                <td className="text-left">
                                                                    <a href="!#">
                                                                    <img src={braClubLogos} alt="Brazil" /><span>Brazil</span>
                                                                    </a>
                                                                </td>
                                                                <td>38</td>
                                                                <td>24</td>
                                                                <td>7</td>
                                                                <td>7</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left number">3 <i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                                                <td className="text-left">
                                                                    <a href="!#">
                                                                    <img src={argClubLogos} alt="Argentina" /><span>Argentina</span>
                                                                    </a>
                                                                </td>
                                                                <td>38</td>
                                                                <td>22</td>
                                                                <td>9</td>
                                                                <td>7</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left number">4<i className="fa fa-caret-down" aria-hidden="true"></i></td>
                                                                <td className="text-left">
                                                                    <a href="!#">
                                                                    <img src={japanClubLogos} alt="Japan" /><span>Japan</span>
                                                                    </a>
                                                                </td>
                                                                <td>38</td>
                                                                <td>20</td>
                                                                <td>10</td>
                                                                <td>8</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left number">5  <i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                                                <td className="text-left">
                                                                    <a href="!#">
                                                                    <img src={senClubLogos} alt="Senegal" /><span>Senegal</span>
                                                                    </a>
                                                                </td>
                                                                <td>38</td>
                                                                <td>19</td>
                                                                <td>7</td>
                                                                <td>12</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="grp2" title="Group 2">
                                            <div className="tab-pane " id="grp2">
                                                <div className="row">
                                                    <div className="col-sm-12 px-0">
                                                        <div className="titles no-margin border-0">
                                                            <h4>Points Table2</h4>
                                                        </div>
                                                        <table className="table-striped table-responsive table-hover result-point small">
                                                            <thead className="point-table-head">
                                                            <tr>
                                                                <th className="text-left">No</th>
                                                                <th className="text-left">TEAM</th>
                                                                <th className="text-center">P</th>
                                                                <th className="text-center">W</th>
                                                                <th className="text-center">D</th>
                                                                <th className="text-center">PTS</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="text-center">
                                                            <tr>
                                                                <td className="text-left number">1 <i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                                                <td className="text-left">
                                                                    <a href="!#">
                                                                    <img src={japanClubLogos} alt="Japan" /><span>Japan</span>
                                                                    </a>
                                                                </td>
                                                                <td>38</td>
                                                                <td>20</td>
                                                                <td>10</td>
                                                                <td>8</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left number">2 <i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                                                <td className="text-left">
                                                                    <a href="!#">
                                                                    <img src={braClubLogos} alt="Brazil" /><span>Brazil</span>
                                                                    </a>
                                                                </td>
                                                                <td>38</td>
                                                                <td>24</td>
                                                                <td>7</td>
                                                                <td>7</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left number">3 <i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                                                <td className="text-left">
                                                                    <a href="!#">
                                                                    <img src={argClubLogos} alt="Argentina" /><span>Argentina</span>
                                                                    </a>
                                                                </td>
                                                                <td>38</td>
                                                                <td>22</td>
                                                                <td>9</td>
                                                                <td>7</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left number">4<i className="fa fa-caret-down" aria-hidden="true"></i></td>
                                                                <td className="text-left">
                                                                    <a href="!#">
                                                                    <img src={senClubLogos} alt="Senegal" /><span>Senegal</span>
                                                                    </a>
                                                                </td>
                                                                <td>38</td>
                                                                <td>19</td>
                                                                <td>7</td>
                                                                <td>12</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left number">5  <i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                                                <td className="text-left">
                                                                    <a href="!#">
                                                                    <img src={argClubLogos} alt="Argentina" /><span>Argentina</span>
                                                                    </a>
                                                                </td>
                                                                <td>38</td>
                                                                <td>22</td>
                                                                <td>9</td>
                                                                <td>7</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="grp3" title="Group 3">
                                            <div className="tab-pane " id="grp3">
                                                <div className="row">
                                                    <div className="col-sm-12 px-0">
                                                        <div className="titles no-margin border-0">
                                                            <h4>Points Table3</h4>
                                                        </div>
                                                        <table className="table-striped table-responsive table-hover result-point small">
                                                            <thead className="point-table-head">
                                                            <tr>
                                                                <th className="text-left">No</th>
                                                                <th className="text-left">TEAM</th>
                                                                <th className="text-center">P</th>
                                                                <th className="text-center">W</th>
                                                                <th className="text-center">D</th>
                                                                <th className="text-center">PTS</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="text-center">
                                                            <tr>
                                                                <td className="text-left number">1 <i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                                                <td className="text-left">
                                                                    <a href="!#">
                                                                    <img src={senClubLogos} alt="Senegal" /><span>Senegal</span>
                                                                    </a>
                                                                </td>
                                                                <td>38</td>
                                                                <td>19</td>
                                                                <td>7</td>
                                                                <td>12</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left number">2 <i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                                                <td className="text-left">
                                                                    <a href="!#">
                                                                    <img src={braClubLogos} alt="Brazil" /><span>Brazil</span>
                                                                    </a>
                                                                </td>
                                                                <td>38</td>
                                                                <td>24</td>
                                                                <td>7</td>
                                                                <td>7</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left number">3 <i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                                                <td className="text-left">
                                                                    <a href="!#">
                                                                    <img src={colombiaClubLogos} alt="Colombia" /><span>Colombia</span>
                                                                    </a>
                                                                </td>
                                                                <td>38</td>
                                                                <td>26</td>
                                                                <td>9</td>
                                                                <td>3</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left number">4<i className="fa fa-caret-down" aria-hidden="true"></i></td>
                                                                <td className="text-left">
                                                                    <a href="!#">
                                                                    <img src={japanClubLogos} alt="Japan" /><span>Japan</span>
                                                                    </a>
                                                                </td>
                                                                <td>38</td>
                                                                <td>20</td>
                                                                <td>10</td>
                                                                <td>8</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left number">5  <i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                                                <td className="text-left">
                                                                    <a href="!#">
                                                                    <img src={argClubLogos} alt="Argentina" /><span>Argentina</span>
                                                                    </a>
                                                                </td>
                                                                <td>38</td>
                                                                <td>22</td>
                                                                <td>9</td>
                                                                <td>7</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    {/* </div> */}
                                    </Tabs>
                                </div>
                                {/* {<!-- End Top player -->} */}
                            </div>
                            </div>
                        </div>
                        <div id="group-details"></div>
                        <div className="panel-box">
                            <div className="titles">
                            <h4>Arabia Group Details</h4>
                            </div>
                            <div className="row detail-mn text-center">
                            <div className="col-md-6">
                                <div className="card text-white mb-3" >
                                    <div className="card-header bg-primary ">Total Matches</div>
                                    <div className="card-body">
                                        <h5 className="card-title">0</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card text-white mb-3" >
                                    <div className="card-header bg-warning  ">Goal Maker</div>
                                    <div className="card-body">
                                        <h5 className="card-title">0</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card text-white mb-3" >
                                    <div className="card-header bg-success ">Scorer</div>
                                    <div className="card-body">
                                        <h5 className="card-title">0</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card text-white mb-3" >
                                    <div className="card-header bg-danger ">Games</div>
                                    <div className="card-body">
                                        <h5 className="card-title">0</h5>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div id="Matches-resultso"></div>
                        <div className="panel-box">
                            <div className="titles">
                            <h4>Match Results</h4>
                            </div>
                            <div className="row text-center">
                            <div className="col-sm-12">
                                <div className="recent-results">
                                    <div className="info-results bg-transparent">
                                        <ul>
                                        <li>
                                            <span className="head"> <Link to="/match-results" className="btn btn-success float-left">View Details</Link>
                                            Portugal Vs Spain <span className="date">27 Jun 2017</span>
                                            </span>
                                            <div className="goals-result">
                                                <span>
                                                <img src={porClubLogos} alt="" /> <br/>
                                                Portugal
                                                </span>
                                                <span className="goals">
                                                <b>0</b> - <b>0</b>
                                                </span>
                                                <span>
                                                <img src={espClubLogos} alt="" /><br/>
                                                Spain
                                                </span>
                                            </div>
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div id="latest-news"></div>
                        <div className="panel-box">
                            <div className="titles">
                            <h4>Recent News</h4>
                            </div>
                            {/* {<!-- Post Item -->} */}
                            <div className="post-item">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="img-hover">
                                        <img src={BlogImage1} alt="" className="img-responsive" />
                                        <div className="overlay"><Link to="/single-news">+</Link></div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <h5><Link to="/single-news">Group Stage Breakdown</Link></h5>
                                    <span className="data-info">January 3, 2014  / <i className="fa fa-comments"></i><a href="!#">0</a></span>
                                    <p>While familiar with fellow European nation France, Hareide admits that South American side Peru.<Link to="/single-news">Read More [+]</Link></p>
                                </div>
                            </div>
                            </div>
                            {/* {<!-- End Post Item -->} */}
                        </div>
                        <div id="statics-uy"></div>
                        <div className="panel-box">
                            <div className="titles">
                            <h4>Statistics</h4>
                            </div>
                            {/* {<!-- Post Item -->} */}
                            <div className="post-item">
                            <div className="row">
                                {/* {<!-- Club Ranking -->} */}
                                <div className="col-lg-6">
                                    <div className="club-ranking">
                                        <h5 className="text-dark mb-3">Club Ranking</h5>
                                        <div className="info-ranking">
                                        <ul>
                                            <li>
                                                <span className="position">
                                                1
                                                </span>
                                                <Link to="/single-team">
                                                <img src={rusiaClubLogos} alt="" />
                                                Russia
                                                </Link>
                                                <span className="points">
                                                90
                                                </span>
                                            </li>
                                            <li>
                                                <span className="position">
                                                2
                                                </span>
                                                <Link to="/single-team">
                                                <img src={arabiaClubLogos} alt="" />
                                                Saudi Arabia
                                                </Link>
                                                <span className="points">
                                                86
                                                </span>
                                            </li>
                                            <li>
                                                <span className="position">
                                                3
                                                </span>
                                                <Link to="/single-team">
                                                <img src={egyClubLogos} alt="" />
                                                Egypt
                                                </Link>
                                                <span className="points">
                                                84
                                                </span>
                                            </li>
                                            <li>
                                                <span className="position">
                                                4
                                                </span>
                                                <Link to="/single-team">
                                                <img src={uruClubLogos} alt="" />
                                                Uruguay
                                                </Link>
                                                <span className="points">
                                                70
                                                </span>
                                            </li>
                                            <li>
                                                <span className="position">
                                                5
                                                </span>
                                                <Link to="/single-team">
                                                <img src={porClubLogos} alt="" />
                                                Portugal
                                                </Link>
                                                <span className="points">
                                                67
                                                </span>
                                            </li>
                                            <li>
                                                <span className="position">
                                                6
                                                </span>
                                                <Link to="/single-team">
                                                <img src={espClubLogos} alt="" />
                                                Spain
                                                </Link>
                                                <span className="points">
                                                60
                                                </span>
                                            </li>
                                            <li>
                                                <span className="position">
                                                55
                                                </span>
                                                <Link to="/single-team">
                                                <img src={marClubLogos} alt="" />
                                                Morocco
                                                </Link>
                                                <span className="points">
                                                90
                                                </span>
                                            </li>
                                            <li>
                                                <span className="position">
                                                8
                                                </span>
                                                <Link to="/single-team">
                                                <img src={irnClubLogos} alt="" />
                                                IR Iran
                                                </Link>
                                                <span className="points">
                                                53
                                                </span>
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* {<!-- End Club Ranking -->} */}
                                {/* {<!-- recent-results -->} */}
                                <div className="col-lg-6">
                                    <div className="recent-results">
                                        <h5 className="text-dark mb-3">Recent Result</h5>
                                        <div className="info-results">
                                        <ul>
                                            <li>
                                                <span className="head">
                                                Portugal Vs Spain <span className="date">27 Jun 2017</span>
                                                </span>
                                                <div className="goals-result">
                                                    <Link to="/single-team">
                                                    <img src={porClubLogos} alt="" />
                                                    Portugal
                                                    </Link>
                                                    <span className="goals">
                                                    <b>2</b> - <b>3</b>
                                                    </span>
                                                    <Link to="/single-team">
                                                    <img src={espClubLogos} alt="" />
                                                    Spain
                                                    </Link>
                                                </div>
                                            </li>
                                            <li>
                                                <span className="head">
                                                Rusia Vs Colombia <span className="date">30 Jun 2017</span>
                                                </span>
                                                <div className="goals-result">
                                                    <Link to="/single-team">
                                                    <img src={rusiaClubLogos} alt="" />
                                                    Rusia
                                                    </Link>
                                                    <span className="goals">
                                                    <b>2</b> - <b>3</b>
                                                    </span>
                                                    <Link to="/single-team">
                                                    <img src={colombiaClubLogos} alt="" />
                                                    Colombia
                                                    </Link>
                                                </div>
                                            </li>
                                            <li>
                                                <span className="head">
                                                Uruguay Vs Portugal <span className="date">31 Jun 2017</span>
                                                </span>
                                                <div className="goals-result">
                                                    <Link to="/single-team">
                                                    <img src={uruClubLogos} alt="" />
                                                    Uruguay
                                                    </Link>
                                                    <span className="goals">
                                                    <b>2</b> - <b>3</b>
                                                    </span>
                                                    <Link to="/single-team">
                                                    <img src={porClubLogos} alt="" />
                                                    Portugal
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* {<!-- end recent-results -->} */}
                            </div>
                            </div>
                            {/* {<!-- End Post Item -->} */}
                        </div>
                        <div id="clb-vdr"></div>
                        <div className="panel-box">
                            <div className="titles">
                            <h4>Latest Club Videos</h4>
                            </div>
                            <div className="row ">
                            {/* {<!--Item Club News -->} */}
                            <div className="col-lg-6 col-xl-4">
                                {/* {<!-- Widget Text-->} */}
                                <div className="panel-box">
                                    <div className="titles no-margin">
                                        <h4 className="px-2"><a href="!#">Eliminatory to the world.</a></h4>
                                    </div>
                                    <iframe className="video" src="https://www.youtube.com/embed/Ln8rXkeeyP0" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen=""></iframe>
                                </div>
                                {/* {<!-- End Widget Text-->} */}
                            </div>
                            {/* {<!--End Item Club News -->} */}
                            {/* {<!--Item Club News -->} */}
                            <div className="col-lg-6 col-xl-4">
                                {/* {<!-- Widget Text-->} */}
                                <div className="panel-box">
                                    <div className="titles no-margin">
                                        <h4 className="px-2"><a href="!#">Colombia classification</a></h4>
                                    </div>
                                    <iframe className="video" src="https://www.youtube.com/embed/Z5cackyUfgk" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen=""></iframe>
                                </div>
                                {/* {<!-- End Widget Text-->} */}
                            </div>
                            {/* {<!--End Item Club News -->} */}
                            {/* {<!--Item Club News -->} */}
                            <div className="col-lg-6 col-xl-4">
                                {/* {<!-- Widget Text-->} */}
                                <div className="panel-box">
                                    <div className="titles no-margin">
                                        <h4 className="px-2"><a href="!#">World Cup goal</a></h4>
                                    </div>
                                    <iframe className="video" src="https://www.youtube.com/embed/hW3hnUoUS0k" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen=""></iframe>
                                </div>
                                {/* {<!-- End Widget Text-->} */}
                            </div>
                            {/* {<!--End Item Club News -->} */}
                            </div>
                        </div>
                        {/* {<!-- End Content Text-->} */}
                    </div>
                </div>
                </div>

                {/* {<!-- Newsletter -->} */}
                
                <NewsLetter />
               
                {/* {<!-- End Newsletter -->} */}
            </section>
            {/* {<!-- End Section Area -  Content Central -->} */}
        </>
    )
}


export default SingleLeague;

