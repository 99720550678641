import React from "react";
import { Link } from "react-router-dom";
import NewsLetter from "../../../components/news/news-letter";
import LogoSlider from "../../../components/logoslider/logoslider";
import Three from "../../../assets/img/football/slide/3.jpg";
import ColombiaClubLogo from "../../../assets/img/football/clubs-logos/colombia.png";

import CristianoImg from "../../../assets/img/football/players/1.jpg";
import MessiImg from "../../../assets/img/football/players/2.jpg";
import NeymarImg from "../../../assets/img/football/players/3.jpg";
import LuisImg from "../../../assets/img/football/players/4.jpg";

import LocationOne from "../../../assets/img/football/locations/1.jpg";
import LocationThree from "../../../assets/img/football/locations/3.jpg";

import BlogOne from "../../../assets/img/football/blog/1.jpg";
import BlogTwo from "../../../assets/img/football/blog/2.jpg";
import BlogThree from "../../../assets/img/football/blog/3.jpg";

const AboutPage = (props) => {
  return (
    <>
        {/* {<!-- Section Title -->} */}
        <div className="section-title big-title" style={{background:'url("'+LocationThree+'")'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <h1>Page About Us</h1>
                    </div>

                    <div className="col-md-4">
                        <div className="breadcrumbs">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Inner Page</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* {<!-- End Section Title -->} */}

        {/* {<!-- Section Area - Content Central -->} */}
        <section className="content-info">

            {/* {<!-- White Section -->} */}
            <div className="white-section paddings">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <img src={LocationOne} alt="" />
                        </div>
                        <div className="col-lg-7">
                            <h4 className="subtitle">
                                <span>Company Value</span>
                                Who Are You
                            </h4>
                            <p>The top seeds in Groups C and D both have designs on winning their respective sections. But if one of them advances as a group winner and the other as a runner-up, then we will be seeing Lionel Messi facing off against Antoine Griezmann either in Kazan (30 June) or Nizhny Novgorod (1 July), depending on their team’s group placings.</p>

                            <div className="row">
                                <div className="col-lg-6">
                                    <h5>Our Mission</h5>
                                    <p>Lorem iur adipiscing elit. Ut vehicula dapibus augue nec maximustiam eleifend magna erat, quis vestibulum lacus mattis sit ametec pellentesque lorem sapien.</p>
                                </div>
                                <div className="col-lg-6">
                                    <h5>Our Vision</h5>
                                    <p>Lorem iur adipiscing elit. Ut vehicula dapibus augue nec maximustiam eleifend magna erat, quis vestibulum lacus mattis sit ametec pellentesque lorem sapien.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row padding-top">
                        <div className="col-md-6 col-xl-3">
                            <div className="item-boxed-service">
                                <h4>Soccer Team </h4>
                                <p>Best Sports Features</p>
                                <a href="!#"><i className="fa fa-plus-circle"></i>View More</a>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-3">
                            <div className="item-boxed-service">
                                <h4>Club Features</h4>
                                <p>Best Sports Features</p>
                                <a href="!#"><i className="fa fa-plus-circle"></i>View More</a>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-3">
                            <div className="item-boxed-service">
                                <h4>Won cups</h4>
                                <p>Best Sports Features</p>
                                <a href="!#"><i className="fa fa-plus-circle"></i>View More</a>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-3">
                            <div className="item-boxed-service">
                                <h4>Technical body</h4>
                                <p>Best Sports Features</p>
                                <a href="!#"><i className="fa fa-plus-circle"></i>View More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {<!-- End White Section -->} */}

            {/* {<!-- Parallax Section -->} */}
            <div className="parallax-section" style={{background:'url("'+Three+'")'}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <h1 className="big-title">We are a great team</h1>
                        </div>
                    </div>
                </div>
            </div>
            {/* {<!-- End Gray Section -->} */}

            {/* {<!-- End gray Section -->} */}
            <div className="default-section paddings">
                <div className="container">
                    <div className="row">
                            {/* {<!-- Item Player -->} */}
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="item-player">
                                <div className="head-player">
                                    <img src={CristianoImg} alt="location-team" />
                                    <div className="overlay"><Link to="/single-player">+</Link></div>
                                </div>
                                <div className="info-player">
                                    <span className="number-player">
                                        13
                                    </span>
                                    <h4>
                                        Cristiano Ronaldo
                                        <span>Forward</span>
                                    </h4>
                                    <ul>
                                        <li>
                                            <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                        </li>
                                        <li><strong>MATCHES:</strong> <span>90</span></li>
                                        <li><strong>AGE:</strong> <span>28</span></li>
                                    </ul>
                                </div>
                                <Link to="/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                            </div>
                        </div>
                        {/* {<!-- End Item Player -->} */}

                        {/* {<!-- Item Player -->} */}
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="item-player">
                                <div className="head-player">
                                    <img src={MessiImg} alt="location-team" />
                                    <div className="overlay"><Link to="/single-player">+</Link></div>
                                </div>
                                <div className="info-player">
                                    <span className="number-player">
                                        10
                                    </span>
                                    <h4>
                                        Lionel Messi
                                        <span>Defender</span>
                                    </h4>
                                    <ul>
                                        <li>
                                            <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                        </li>
                                        <li><strong>MATCHES:</strong> <span>90</span></li>
                                        <li><strong>AGE:</strong> <span>28</span></li>
                                    </ul>
                                </div>
                                <Link to="/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                            </div>
                        </div>
                        {/* {<!-- End Item Player -->} */}

                        {/* {<!-- Item Player -->} */}
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="item-player">
                                <div className="head-player">
                                    <img src={NeymarImg} alt="location-team" />
                                    <div className="overlay"><Link to="/single-player">+</Link></div>
                                </div>
                                <div className="info-player">
                                    <span className="number-player">
                                        2
                                    </span>
                                    <h4>
                                        Neymar
                                        <span>Midfielder</span>
                                    </h4>
                                    <ul>
                                        <li>
                                            <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                        </li>
                                        <li><strong>MATCHES:</strong> <span>90</span></li>
                                        <li><strong>AGE:</strong> <span>28</span></li>
                                    </ul>
                                </div>
                                <Link to="/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                            </div>
                        </div>
                        {/* {<!-- End Item Player -->} */}

                        {/* {<!-- Item Player -->} */}
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="item-player">
                                <div className="head-player">
                                    <img src={LuisImg} alt="location-team" />
                                    <div className="overlay"><Link to="/single-player">+</Link></div>
                                </div>
                                <div className="info-player">
                                    <span className="number-player">
                                        2
                                    </span>
                                    <h4>
                                        Luis Suárez
                                        <span>Goalkeeper</span>
                                    </h4>
                                    <ul>
                                        <li>
                                            <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                        </li>
                                        <li><strong>MATCHES:</strong> <span>90</span></li>
                                        <li><strong>AGE:</strong> <span>28</span></li>
                                    </ul>
                                </div>
                                <Link to="/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                            </div>
                        </div>
                        {/* {<!-- End Item Player -->} */}
                    </div>
                </div>
            </div>
            {/* {<!-- End Gray Section -->} */}

            {/* {<!-- White Section -->} */}
            <div className="white-section paddings">
                <div className="container">
                    {/* {<!--Items Club News -->} */}
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="clear-title">Latest News</h3>
                        </div>

                        {/* {<!--Item Club News -->} */}
                        <div className="col-lg-6 col-xl-4">
                            {/* {<!-- Widget Text-->} */}
                            <div className="panel-box">
                                <div className="titles no-margin">
                                    <h4><a href="!#">World football's dates.</a></h4>
                                </div>
                                <a href="!#"><img src={BlogOne} alt="" /></a>
                                <div className="row">
                                    <div className="info-panel">
                                        <p>Fans from all around the world can apply for 2022 FIFA World Cup™ tickets as the first window of sales.</p>
                                    </div>
                                </div>
                            </div>
                            {/* {<!-- End Widget Text-->} */}
                        </div>
                        {/* {<!--End Item Club News -->} */}

                        {/* {<!--Item Club News -->} */}
                        <div className="col-lg-6 col-xl-4">
                            {/* {<!-- Widget Text-->} */}
                            <div className="panel-box">
                                <div className="titles no-margin">
                                    <h4><a href="!#">Mbappe’s year to remember</a></h4>
                                </div>
                                <a href="!#"><img src={BlogTwo} alt="" /></a>
                                <div className="row">
                                    <div className="info-panel">
                                        <p>Tickets may be purchased online by using Visa payment cards or Visa Checkout. Visa is the official.</p>
                                    </div>
                                </div>
                            </div>
                            {/* {<!-- End Widget Text-->} */}
                        </div>
                        {/* {<!--End Item Club News -->} */}

                        {/* {<!--Item Club News -->} */}
                        <div className="col-lg-6 col-xl-4">
                            {/* {<!-- Widget Text-->} */}
                            <div className="panel-box">
                                <div className="titles no-margin">
                                    <h4><a href="!#">Egypt are one family</a></h4>
                                </div>
                                <a href="!#"><img src={BlogThree} alt="" /></a>
                                <div className="row">
                                    <div className="info-panel">
                                        <p>Successful applicants who have applied for supporter tickets and conditional supporter tickets will.</p>
                                    </div>
                                </div>
                            </div>
                            {/* {<!-- End Widget Text-->} */}
                        </div>
                        {/* {<!--End Item Club News -->} */}
                    </div>
                    {/* {<!--End Items Club News -->} */}
                </div>
            </div>
            {/* {<!-- End White Section -->} */}

            {/* {<!-- Sponsors-->} */}
            <div className="default-section paddings">
                <div className="container">
                    {/* {<!--Sponsors CLub -->} */}
                    <div className="row no-line-height">
                            <div className="col-md-12">
                                <h3 className="clear-title">Sponsors</h3>
                            </div>
                    </div>
                    {/* {<!--End Sponsors CLub -->} */}

                    <LogoSlider />

                </div>
            </div>
            {/* {<!-- End Sponsors -->} */}

            {/* {<!-- Newsletter -->} */}
            <NewsLetter />
            {/* {<!-- End Newsletter -->} */}
        </section>
        {/* {<!-- End Section Area -  Content Central -->} */}

        <div className="instagram-btn">
            <div className="btn-instagram">
                <i className="fa fa-instagram"></i>
                FOLLOW
                <a href="https://www.instagram.com/fifaworldcup/" target="_blank">&#64;fifaworldcup</a>
            </div>
        </div>

        <div className="content-instagram">
            <div id="instafeed"></div>
        </div>
      
    </>
  );
};

export default AboutPage;
