import Isotope from "isotope-layout";
import React from "react";

import BlogOne from "../../assets/img/blog/1.jpg";
import BlogTwo from "../../assets/img/blog/2.jpg";
import BlogThree from "../../assets/img/blog/3.jpg";
import BlogFour from "../../assets/img/blog/4.jpg";

import SlideThree from "../../assets/img/slide/3.jpg";
import ClubTeamArgentina from "../../assets/img/clubs-teams/argentina.jpg";
import ClubTeamRussia from "../../assets/img/clubs-teams/rusia.jpg";
import ClubTeamBrazil from "../../assets/img/clubs-teams/brazil.jpg";

const SportsGallery = (props) => {

    // init one ref to store the future isotope object
    const isotope = React.useRef();
    // store the filter keyword in a state
    const [filterKey, setFilterKey] = React.useState("*");

    // initialize an Isotope object with configs
    React.useEffect(() => {
    isotope.current = new Isotope(".portfolioContainer", {
        itemSelector: ".filter-item",
        layoutMode: "fitRows",
    });
    // cleanup
    return () => isotope.current.destroy();
    }, []);

    // handling filter key change
    React.useEffect(() => {
    filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }, [filterKey]);

    /**
     * set filter key
     * @param {*} key
     * @returns
     */
    const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <div className="white-section paddings">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <h3 className="clear-title no-margin">Sports Gallery</h3>
            </div>

            <div className="col-lg-8">
              {/* <!-- Nav Filters --> */}
              <div className="portfolioFilter no-margin no-bg pull-right">
                <a href="javascript:;"
                    className={`${filterKey === "*" ? "current" : ""}`}
                    onClick={handleFilterKeyChange("*")}
                >
                    All
                </a>
                <a href="javascript:;"
                    onClick={handleFilterKeyChange("soccer")}
                    className={`${filterKey === "soccer" ? "current" : ""}`}
                >
                    Soccer
                </a>
                <a href="javascript:;"
                    onClick={handleFilterKeyChange("moto")}
                    className={`${filterKey === "moto" ? "current" : ""}`}
                >
                    Moto Cross
                </a>
                <a href="javascript:;"
                    onClick={handleFilterKeyChange("bmx")}
                    className={`${filterKey === "bmx" ? "current" : ""}`}
                >
                    Bmx
                </a>
              </div>
              {/* <!-- End Nav Filters --> */}
            </div>
          </div>

          <div className="row portfolioContainer margin-top h-100">
            {/* <!-- Item Gallery --> */}
            <div className="col-sm-6 col-lg-4 col-xl-3 filter-item soccer">
              <div className="item-gallery">
                <div className="hover small">
                  <img src={SlideThree} alt="Club Team" />
                  <a
                    className="swipebox-ligbox"
                    href="img/clubs-teams/colombia.jpg"
                  >
                    <div className="overlay">
                      <i className="fa fa-plus"></i>
                    </div>
                  </a>
                </div>
                <div className="info-gallery">
                  <p>Soccer</p>
                  <i className="fa fa-picture-o"></i>
                </div>
              </div>
            </div>
            {/* <!-- Item Gallery --> */}

            {/* <!-- Item Gallery --> */}
            <div className="col-sm-6 col-lg-4 col-xl-3 filter-item moto">
              <div className="item-gallery">
                <div className="hover small">
                  <img src={BlogThree} alt="Club Team" />
                  <a
                    className="swipebox-ligbox"
                    href="img/clubs-teams/rusia.jpg"
                  >
                    <div className="overlay">
                      <i className="fa fa-plus"></i>
                    </div>
                  </a>
                </div>
                <div className="info-gallery">
                  <p>Moto Cross</p>
                  <i className="fa fa-picture-o"></i>
                </div>
              </div>
            </div>
            {/* <!-- Item Gallery --> */}

            {/* <!-- Item Gallery --> */}
            <div className="col-sm-6 col-lg-4 col-xl-3 filter-item soccer">
              <div className="item-gallery">
                <div className="hover small">
                  <img src={BlogOne} alt="Club Team" />
                  <a
                    className="swipebox-ligbox"
                    href="img/clubs-teams/brazil.jpg"
                  >
                    <div className="overlay">
                      <i className="fa fa-plus"></i>
                    </div>
                  </a>
                </div>
                <div className="info-gallery">
                  <p>Soccer</p>
                  <i className="fa fa-picture-o"></i>
                </div>
              </div>
            </div>
            {/* <!-- Item Gallery --> */}

            {/* <!-- Item Gallery --> */}
            <div className="col-sm-6 col-lg-4 col-xl-3 filter-item moto">
              <div className="item-gallery">
                <div className="hover small">
                  <img src={BlogTwo} alt="Club Team" />
                  <a
                    className="swipebox-ligbox"
                    href="https://www.youtube.com/watch?v=AfOlAUd7u4o"
                  >
                    <div className="overlay">
                      <i className="fa fa-plus"></i>
                    </div>
                  </a>
                </div>
                <div className="info-gallery">
                  <p>Moto Cross</p>
                  <i className="fa fa-video-camera"></i>
                </div>
              </div>
            </div>
            {/* <!-- Item Gallery --> */}

            {/* <!-- Item Gallery --> */}
            <div className="col-sm-6 col-lg-4 col-xl-3 filter-item soccer">
              <div className="item-gallery">
                <div className="hover small">
                  <img src={ClubTeamArgentina} alt="Club Team" />
                  <a
                    className="swipebox-ligbox"
                    href="https://www.youtube.com/watch?v=AfOlAUd7u4o"
                  >
                    <div className="overlay">
                      <i className="fa fa-plus"></i>
                    </div>
                  </a>
                </div>
                <div className="info-gallery">
                  <p>Soccer</p>
                  <i className="fa fa-video-camera"></i>
                </div>
              </div>
            </div>
            {/* <!-- Item Gallery --> */}

            {/* <!-- Item Gallery --> */}
            <div className="col-sm-6 col-lg-4 col-xl-3 filter-item soccer">
              <div className="item-gallery">
                <div className="hover small">
                  <img src={ClubTeamRussia} alt="Club Team" />
                  <a
                    className="swipebox-ligbox"
                    href="img/clubs-teams/rusia.jpg"
                  >
                    <div className="overlay">
                      <i className="fa fa-plus"></i>
                    </div>
                  </a>
                </div>
                <div className="info-gallery">
                  <p>Soccer</p>
                  <i className="fa fa-picture-o"></i>
                </div>
              </div>
            </div>
            {/* <!-- Item Gallery --> */}

            {/* <!-- Item Gallery --> */}
            <div className="col-sm-6 col-lg-4 col-xl-3 filter-item bmx">
              <div className="item-gallery">
                <div className="hover small">
                  <img src={BlogFour} alt="Club Team" />
                  <a
                    className="swipebox-ligbox"
                    href="img/clubs-teams/colombia.jpg"
                  >
                    <div className="overlay">
                      <i className="fa fa-plus"></i>
                    </div>
                  </a>
                </div>
                <div className="info-gallery">
                  <p>Bmx</p>
                  <i className="fa fa-picture-o"></i>
                </div>
              </div>
            </div>
            {/* <!-- Item Gallery --> */}

            {/* <!-- Item Gallery --> */}
            <div className="col-sm-6 col-lg-4 col-xl-3 filter-item soccer">
              <div className="item-gallery">
                <div className="hover small">
                  <img src={ClubTeamBrazil} alt="Club Team" />
                  <a
                    className="swipebox-ligbox"
                    href="img/clubs-teams/brazil.jpg"
                  >
                    <div className="overlay">
                      <i className="fa fa-plus"></i>
                    </div>
                  </a>
                </div>
                <div className="info-gallery">
                  <p>Soccer</p>
                  <i className="fa fa-picture-o"></i>
                </div>
              </div>
            </div>
            {/* <!-- Item Gallery --> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SportsGallery;
