import React from "react";
import { Link } from "react-router-dom";
import NewsLetter from "../../../components/news/news-letter";

import SlideOne from "../../../assets/img/basket-ball/slide/1.jpg";
import ColombiaClubLogo from "../../../assets/img/basket-ball/clubs-logos/colombia.png";
import RusiaClubLogo from "../../../assets/img/basket-ball/clubs-logos/rusia.png";
import SpainClubLogo from "../../../assets/img/basket-ball/clubs-logos/esp.png";

import PortugalClubLogo from "../../../assets/img/basket-ball/clubs-logos/por.png";
import UruguayClubLogo from "../../../assets/img/basket-ball/clubs-logos/uru.png";

const Results = (props) => {
  return (
    <>
      {/* <!-- Section Title --> */}
      {/* <!-- Section Title --> */}
        <div className="section-title" style={{background:'url("' + SlideOne + '")'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <h1>Points Table</h1>
                    </div>

                    <div className="col-md-4">
                        <div className="breadcrumbs">
                            <ul>
                                <li><Link to="/cricket/">Home</Link></li>
                                <li>Inner Page</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Section Title --> */}

        {/* <!-- Section Area - Content Central --> */}
            <section className="content-info">

                <div className="container padding-top">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="recent-results results-page">
                                <div className="info-results">
                                    <ul>
                                        <li>
                                            <span className="head">
                                                Portugal Vs Spain <span className="date">27 Jun 2017</span>
                                            </span>

                                            <div className="goals-result">
                                                <Link to="/cricket/single-results">
                                                    <img src={PortugalClubLogo} alt=""/>
                                                    Portugal
                                                </Link>

                                                <span className="goals">
                                                    <b>2</b> - <b>3</b>
                                                    <Link to="/cricket/single-results" className="btn theme">View More</Link>
                                                </span>

                                                <Link to="/cricket/single-results">
                                                    <img src={SpainClubLogo} alt=""/>
                                                    Spain
                                                </Link>
                                            </div>
                                        </li>

                                        <li>
                                            <span className="head">
                                                Rusia Vs Colombia <span className="date">30 Jun 2017</span>
                                            </span>

                                            <div className="goals-result">
                                                <Link to="/cricket/single-results">
                                                    <img src={RusiaClubLogo} alt=""/>
                                                    Rusia
                                                </Link>

                                                <span className="goals">
                                                    <b>2</b> - <b>3</b>
                                                    <Link to="/cricket/single-results" className="btn theme">View More</Link>
                                                </span>

                                                <Link to="/cricket/single-results">
                                                    <img src={ColombiaClubLogo} alt=""/>
                                                     Colombia
                                                </Link>
                                            </div>
                                        </li>

                                        <li>
                                            <span className="head">
                                                Uruguay Vs Portugal <span className="date">31 Jun 2017</span>
                                            </span>

                                            <div className="goals-result">
                                                <Link to="/cricket/single-results">
                                                    <img src={UruguayClubLogo} alt=""/>
                                                    Uruguay
                                                </Link>

                                                <span className="goals">
                                                    <b>2</b> - <b>3</b>
                                                    <Link to="/cricket/single-results" className="btn theme">View More</Link>
                                                </span>

                                                <Link to="/cricket/single-results">
                                                    <img src={PortugalClubLogo} alt=""/>
                                                     Portugal
                                                </Link>
                                            </div>
                                        </li>

                                        <li>
                                            <span className="head">
                                                Uruguay Vs Portugal <span className="date">31 Jun 2017</span>
                                            </span>

                                            <div className="goals-result">
                                                <Link to="/cricket/single-results">
                                                    <img src={UruguayClubLogo} alt=""/>
                                                    Uruguay
                                                </Link>

                                                <span className="goals">
                                                    <b>2</b> - <b>3</b>
                                                    <Link to="/cricket/single-results" className="btn theme">View More</Link>
                                                </span>

                                                <Link to="/cricket/single-results">
                                                    <img src={PortugalClubLogo} alt=""/>
                                                     Portugal
                                                </Link>
                                            </div>
                                        </li>

                                        <li>
                                            <span className="head">
                                                Uruguay Vs Portugal <span className="date">31 Jun 2017</span>
                                            </span>

                                            <div className="goals-result">
                                                <Link to="/cricket/single-results">
                                                    <img src={UruguayClubLogo} alt=""/>
                                                    Uruguay
                                                </Link>

                                                <span className="goals">
                                                    <b>2</b> - <b>3</b>
                                                    <Link to="/cricket/single-results" className="btn theme">View More</Link>
                                                </span>

                                                <Link to="/cricket/single-results">
                                                    <img src={PortugalClubLogo} alt=""/>
                                                     Portugal
                                                </Link>
                                            </div>
                                        </li>

                                        <li>
                                            <span className="head">
                                                Uruguay Vs Portugal <span className="date">31 Jun 2017</span>
                                            </span>

                                            <div className="goals-result">
                                                <Link to="/cricket/single-results">
                                                    <img src={UruguayClubLogo} alt=""/>
                                                    Uruguay
                                                </Link>

                                                <span className="goals">
                                                    <b>2</b> - <b>3</b>
                                                    <Link to="/cricket/single-results" className="btn theme">View More</Link>
                                                </span>

                                                <Link to="/cricket/single-results">
                                                    <img src={PortugalClubLogo} alt=""/>
                                                     Portugal
                                                </Link>
                                            </div>
                                        </li>

                                        <li>
                                            <span className="head">
                                                Uruguay Vs Portugal <span className="date">31 Jun 2017</span>
                                            </span>

                                            <div className="goals-result">
                                                <Link to="/cricket/single-results">
                                                    <img src={UruguayClubLogo} alt=""/>
                                                    Uruguay
                                                </Link>

                                                <span className="goals">
                                                    <b>2</b> - <b>3</b>
                                                    <Link to="/cricket/single-results" className="btn theme">View More</Link>
                                                </span>

                                                <Link to="/cricket/single-results">
                                                    <img src={PortugalClubLogo} alt=""/>
                                                     Portugal
                                                </Link>
                                            </div>
                                        </li>

                                         <li>
                                            <span className="head">
                                                Portugal Vs Spain <span className="date">27 Jun 2017</span>
                                            </span>

                                            <div className="goals-result">
                                                <Link to="/cricket/single-results">
                                                    <img src={PortugalClubLogo} alt=""/>
                                                    Portugal
                                                </Link>

                                                <span className="goals">
                                                    <b>2</b> - <b>3</b>
                                                    <Link to="/cricket/single-results" className="btn theme">View More</Link>
                                                </span>

                                                <Link to="/cricket/single-results">
                                                    <img src={SpainClubLogo} alt=""/>
                                                    Spain
                                                </Link>
                                            </div>
                                        </li>

                                        <li>
                                            <span className="head">
                                                Uruguay Vs Portugal <span className="date">31 Jun 2017</span>
                                            </span>

                                            <div className="goals-result">
                                                <Link to="/cricket/single-results">
                                                    <img src={UruguayClubLogo} alt=""/>
                                                    Uruguay
                                                </Link>

                                                <span className="goals">
                                                    <b>2</b> - <b>3</b>
                                                    <Link to="/cricket/single-results" className="btn theme">View More</Link>
                                                </span>

                                                <Link to="/cricket/single-results">
                                                    <img src={PortugalClubLogo} alt=""/>
                                                     Portugal
                                                </Link>
                                            </div>
                                        </li>

                                         <li>
                                            <span className="head">
                                                Portugal Vs Spain <span className="date">27 Jun 2017</span>
                                            </span>

                                            <div className="goals-result">
                                                <Link to="/cricket/single-results">
                                                    <img src={PortugalClubLogo} alt=""/>
                                                    Portugal
                                                </Link>

                                                <span className="goals">
                                                    <b>2</b> - <b>3</b>
                                                    <Link to="/cricket/single-results" className="btn theme">View More</Link>
                                                </span>

                                                <Link to="/cricket/single-results">
                                                    <img src={SpainClubLogo} alt=""/>
                                                    Spain
                                                </Link>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                           </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Newsletter --> */}
                
                    <NewsLetter />
                {/* <!-- End Newsletter --> */}
            </section>
            {/* <!-- End Section Area -  Content Central --> */}


        <div className="instagram-btn">
            <div className="btn-instagram">
                <i className="fa fa-instagram"></i>
                FOLLOW
                <a href="https://www.instagram.com/fifaworldcup/" target="_blank">&#64;fifaworldcup</a>
            </div>
        </div>

        <div className="content-instagram">
            <div id="instafeed"></div>
        </div>
      
    </>
  );
};

export default Results;
