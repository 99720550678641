import React from "react";
import { Link } from "react-router-dom";
import NewsLetter from "../../../components/news/news-letter";

import SlideOne from "../../../assets/img/football/slide/1.jpg";
import CristianoImg from "../../../assets/img/football/players/1.jpg";
import MessiImg from "../../../assets/img/football/players/2.jpg";
import NeymarImg from "../../../assets/img/football/players/3.jpg";
import espClubLogos from "../../../assets/img/football/clubs-logos/esp.png";
import porClubLogos from "../../../assets/img/football/clubs-logos/por.png";

const PredictionLeague = (props) => {
  return (
    <>
        <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title" id="exampleModalLabel">Contest Rules</h2>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body py-4 px-5">
                    <ul className="list-group">
                        <li >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</li>
                        <li >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</li>
                        <li >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</li>
                        <li >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</li>
                        <li >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</li>
                    </ul>
                </div>
                </div>
            </div>
        </div>
        {/* {<!-- layout-->} */}
        <div id="layout">
            {/* {<!-- Header-->} */}
            {/* {<!-- Section Title -->} */}
            <div className="section-title" style={{background:'url("'+SlideOne+'")'}}>
                <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <h1>World Cup 2022</h1>
                    </div>
                    <div className="col-md-4">
                        <div className="breadcrumbs">
                            <ul>
                            <li><Link to="/index">Home</Link></li>
                            <li>World Cup 2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            {/* {<!-- End Section Title -->} */}
            <div className="container-fluid if-tabs world-ul">
                <div className="row">
                <div className="col-sm-12">
                    <ul className="d-flex justify-content-center flex-row mb-0" id="myDIV">
                        <li className="bv"><a href="#loginkj">Login</a></li>
                        <li className="bv"><a href="!#" data-toggle="modal" data-target="#exampleModal">Contest Rules</a></li>
                        <li className="bv"><a href="#eneral-eriodicals">General Periodicals</a></li>
                        <li className="bv"><a href="#bgPeriodicals">My Periodicals</a></li>
                        <li className="bv"><a href="#oorder"> Order</a></li>
                        <li className="bv"><a href="#expectation"> Expectation</a></li>
                        <li className="bv"><a href="#clb-vdr"> Home</a></li>
                    </ul>
                </div>
                </div>
            </div>
            {/* {<!-- Section Area - Content Central -->} */}
            <section className="content-info">
                <div className="container-fluid paddings-mini">
                <div className="row">
                    {/* {<!-- Sidebars -->} */}
                    <aside className="col-lg-3">
                        <div id="loginkj"></div>
                        <h4>Login</h4>
                        <div className="card  mb-4">
                            <div className="card-body">
                            <ul className="social-loh">
                                <li><a href="!#" className="bg-primary">Facebook </a></li>
                                <li><a href="!#" className="bg-danger">Google</a></li>
                            </ul>
                            </div>
                        </div>
                        <div id="bgPeriodicals"></div>
                        <h4>My Periodicals</h4>
                        <div className="card  mb-4 periodical">
                            <div className="card-body">
                            <div className="social-loh">
                                <h4>Login First</h4>
                                <a href="!#" className="bg-primary">Login</a>
                            </div>
                            </div>
                        </div>
                        <div className="player-ranking ">
                            <h4>Predictions Contest</h4>
                            <div className="info-player">
                            <ul>
                                <li>
                                    <span className="position">
                                    1
                                    </span>
                                    <Link to="/single-team">
                                    <img src={CristianoImg} alt="" />
                                    Cristiano R.
                                    </Link>
                                    <span className="points">
                                    90
                                    </span>
                                </li>
                                <li>
                                    <span className="position">
                                    2
                                    </span>
                                    <Link to="/single-team">
                                    <img src={MessiImg} alt="" />
                                    Lionel Messi
                                    </Link>
                                    <span className="points">
                                    88
                                    </span>
                                </li>
                                <li>
                                    <span className="position">
                                    3
                                    </span>
                                    <Link to="/single-team">
                                    <img src={NeymarImg} alt="" />
                                    Neymar
                                    </Link>
                                    <span className="points">
                                    86
                                    </span>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </aside>
                    {/* {<!-- End Sidebars -->} */}
                    <div className="col-lg-9">
                        {/* {<!-- Content Text-->} */}
                        <div id="expectation"></div>
                        <div className="panel-box padding-b">
                            <div className="info-panel">
                            <div className="card bg-dark">
                                <div className="card-body text-white text-center">
                                    <h3 className="card-title text-white">Register to participate in the predictions competition</h3>
                                    <p className="card-text">Predict match results and win valuable prizes</p>
                                    <a href="!#" className="btn btn-success">Login</a>
                                </div>
                            </div>
                            <div id="main">
                                <div className="">
                                    <div className="accordion mt-5" id="faq">
                                        <div className="card">
                                        <div className="card-header" id="faqhead1">
                                            <a href="!#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq1"
                                                aria-expanded="true" aria-controls="faq1">
                                                <div className="recent-results p-0">
                                                    <div className="info-results bg-transparent">
                                                    <ul className="p-0">
                                                        <li>
                                                            <span className="head">
                                                            Portugal Vs Spain <span className="date">27 Jun 2017</span>
                                                            </span>
                                                            <div className="goals-result">
                                                                <span>
                                                                <img src={porClubLogos} alt="" />
                                                                Portugal
                                                                </span>
                                                                <span className="goals">
                                                                <b>2</b> - <b>3</b>
                                                                </span>
                                                                <span>
                                                                <img src={espClubLogos} alt="" />
                                                                Spain
                                                                </span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div id="faq1" className="collapse" aria-labelledby="faqhead1" data-parent="#faq">
                                            <div className="card-body">
                                                <div className="recent-results">
                                                    <h5><a href="group-list.html">Recent Result</a></h5>
                                                    <div className="info-results">
                                                    <ul>
                                                        <li>
                                                            <span className="head">
                                                            Portugal Vs Spain <span className="date">27 Jun 2017</span>
                                                            </span>
                                                            <div className="goals-result">
                                                                <span>
                                                                <img src={porClubLogos} alt="" />
                                                                Portugal
                                                                </span>
                                                                <div className="goals">
                                                                <ul className="d-flex flex-row adult-child justify-content-between flex-wrap">
                                                                    <li >
                                                                        <button onclick="increment()">+</button><input id="demoInput" type="number" min="0" max="11" value="0" />
                                                                        <button onclick="decrement()">-</button>
                                                                    </li>
                                                                    <li >
                                                                        <button onclick="increment1()">+</button><input id="demoInput1" type="number" min="0" max="11" value="0" />
                                                                        <button onclick="decrement1()">-</button>
                                                                    </li>
                                                                </ul>
                                                                </div>
                                                                <span>
                                                                <img src={espClubLogos} alt="" />
                                                                Spain
                                                                </span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="card">
                                        <div className="card-header" id="faqhead2">
                                            <a href="!#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq2"
                                                aria-expanded="true" aria-controls="faq2">S.S.S</a>
                                        </div>
                                        <div id="faq2" className="collapse" aria-labelledby="faqhead2" data-parent="#faq">
                                            <div className="card-body">
                                                abc
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="ooder"></div>
                            <div className="card bg-dark">
                                <div className="card-body text-white text-center">
                                    <h3 className="card-title text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</h3>
                                </div>
                            </div>
                            <div className="player-ranking mt-5 orderfth">
                                <div className="titles no-margin border-0">
                                    <h4>Overall Order </h4>
                                </div>
                                <div className="info-player">
                                    <ul>
                                        <li>
                                        <span className="position">
                                        1
                                        </span>
                                        <span>
                                        <img src={CristianoImg} alt="" />
                                        Cristiano R.
                                        </span>
                                        <span className="points">
                                        90
                                        </span>
                                        </li>
                                        <li>
                                        <span className="position">
                                        2
                                        </span>
                                        <span>
                                        <img src={MessiImg} alt="" />
                                        Lionel Messi
                                        </span>
                                        <span className="points">
                                        88
                                        </span>
                                        </li>
                                        <li>
                                        <span className="position">
                                        3
                                        </span>
                                        <span>
                                        <img src={NeymarImg} alt="" />
                                        Neymar
                                        </span>
                                        <span className="points">
                                        86
                                        </span>
                                        </li>
                                        <li>
                                        <span className="position">
                                        1
                                        </span>
                                        <span>
                                        <img src={CristianoImg} alt="" />
                                        Cristiano R.
                                        </span>
                                        <span className="points">
                                        90
                                        </span>
                                        </li>
                                        <li>
                                        <span className="position">
                                        2
                                        </span>
                                        <span>
                                        <img src={MessiImg} alt="" />
                                        Lionel Messi
                                        </span>
                                        <span className="points">
                                        88
                                        </span>
                                        </li>
                                        <li>
                                        <span className="position">
                                        3
                                        </span>
                                        <span>
                                        <img src={NeymarImg} alt="" />
                                        Neymar
                                        </span>
                                        <span className="points">
                                        86
                                        </span>
                                        </li>
                                        <li>
                                        <span className="position">
                                        1
                                        </span>
                                        <span>
                                        <img src={CristianoImg} alt="" />
                                        Cristiano R.
                                        </span>
                                        <span className="points">
                                        90
                                        </span>
                                        </li>
                                        <li>
                                        <span className="position">
                                        2
                                        </span>
                                        <span>
                                        <img src={MessiImg} alt="" />
                                        Lionel Messi
                                        </span>
                                        <span className="points">
                                        88
                                        </span>
                                        </li>
                                        <li>
                                        <span className="position">
                                        3
                                        </span>
                                        <span>
                                        <img src={NeymarImg} alt="" />
                                        Neymar
                                        </span>
                                        <span className="points">
                                        86
                                        </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div id="eneral-eriodicals"></div>
                            <div className="panel-box mt-5">
                                <div className="titles no-margin">
                                    <h4>Find the league</h4>
                                </div>
                                <div className="info-panel">
                                    {/* {<!-- Form Contact -->} */}
                                    <form className="form-theme" >
                                        <div className="row">
                                        <div className="col-md-12 px-0">
                                            <input type="search" placeholder="Find the league here" value=""  className="form-control"  />
                                        </div>
                                        </div>
                                        <div className="row">
                                        <div className="col-md-12 px-0">
                                            <h4>General Periodicals</h4>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  full-spk">
                                                    <thead className="bg-dark ">
                                                    <tr>
                                                        <th scope="col" className="text-white">Periodic</th>
                                                        <th scope="col" className="text-white">PeriodicalsPeriodicParticipantsPoints</th>
                                                        <th scope="col" className="text-white">PeriodicalsPeriodicParticipantsPoints</th>
                                                        <th scope="col" className="text-white">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>Space world champions league</td>
                                                        <td>736</td>
                                                        <td>league73665927JoinWorld </td>
                                                        <td><a href="!#" className="btn btn-success"><i className="fa fa-plus" aria-hidden="true"></i> Join</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>World Champions League </td>
                                                        <td>76</td>
                                                        <td>ague73665927JoinWorld </td>
                                                        <td><a href="!#" className="btn btn-success"><i className="fa fa-plus" aria-hidden="true"></i> Join</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>English </td>
                                                        <td>76</td>
                                                        <td>73665927JoinWorld </td>
                                                        <td><a href="!#" className="btn btn-success"><i className="fa fa-plus" aria-hidden="true"></i> Join</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Winter World Cup.</td>
                                                        <td>7</td>
                                                        <td>65927JoinWorld </td>
                                                        <td><a href="!#" className="btn btn-success"><i className="fa fa-plus" aria-hidden="true"></i> Join</a></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        </div>
                                    </form>
                                    {/* {<!-- End Form Contact -->} */}
                                </div>
                            </div>
                            </div>
                        </div>
                        {/* {<!-- End Content Text-->} */}
                    </div>
                </div>
                </div>
                {/* {<!-- Newsletter -->} */}
                <NewsLetter />
                {/* {<!-- End Newsletter -->} */}
            </section>
            {/* {<!-- End Section Area -  Content Central -->} */}
            <div className="instagram-btn">
                <div className="btn-instagram">
                <i className="fa fa-instagram"></i>
                FOLLOW
                <a href="https://www.instagram.com/fifaworldcup/" target="_blank">&#64;fifaworldcup</a>
                </div>
            </div>
            <div className="content-instagram">
                <div id="instafeed"></div>
            </div>
        </div>
    </>
  );
};

export default PredictionLeague;
