import React, { isValidElement } from "react";
import { Link } from "react-router-dom";
import NewsLetter from "../../../components/news/news-letter";
import LogoSlider from "../../../components/logoslider/logoslider";

import SlideThree from "../../../assets/img/football/slide/3.jpg";

import ColombiaClubLogo from "../../../assets/img/football/clubs-logos/colombia.png";
import ArabiaClubLogo from "../../../assets/img/football/clubs-logos/arabia.png";
import UrugueyClubLogo from "../../../assets/img/football/clubs-logos/uru.png";

import SingleTeam from "../../../assets/img/football/clubs-teams/single-team.jpg";
import JamezImg from "../../../assets/img/football/players/7.jpg";

import BrazilClubLogo from "../../../assets/img/football/clubs-logos/bra.png";
import RussiaClubLogo from "../../../assets/img/football/clubs-logos/rusia.png";
import ArgentinaClubLogo from "../../../assets/img/football/clubs-logos/arg.png";
import JapanClubLogo from "../../../assets/img/football/clubs-logos/japan.png";
import AustraliaClubLogo from "../../../assets/img/football/clubs-logos/aus.png";
import MexicoClubLogo from "../../../assets/img/football/clubs-logos/mex.png";
import ngaClubLogo from "../../../assets/img/football/clubs-logos/nga.png";

import BlogOne from "../../../assets/img/football/blog/1.jpg";
import BlogTwo from "../../../assets/img/football/blog/2.jpg";
import BlogThree from "../../../assets/img/football/blog/3.jpg";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
  
const SinglePlayer = (props) => {

  return (
    <>
        {/* {<!-- Section Title -->} */}
        <div className="section-title single-player" style={{background:'url("'+SlideThree+'")'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <h1>Single Player</h1>
                    </div>

                    <div className="col-md-4">
                        <div className="breadcrumbs">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Inner Page</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* {<!-- End Section Title -->} */}

        {/* {<!-- Section Area - Content Central -->} */}
        <section className="content-info">

            {/* {<!-- Single Team Tabs -->} */}
            <div className="single-player-tabs">
                <div className="container">
                    <div className="row">
                        {/* {<!-- Side info single team-->} */}
                        <div className="col-lg-4 col-xl-3">

                            <div className="item-player single-player">
                                <div className="head-player">
                                    <img src={JamezImg} alt="location-team" />
                                </div>
                                <div className="info-player">
                                    <span className="number-player">
                                        10
                                    </span>
                                    <h4>
                                        Jamez Rodriguez
                                        <span>Forward</span>
                                    </h4>
                                    <ul>
                                        <li>
                                            <strong>CLUB NAME:</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                        </li>
                                        <li><strong>MATCHES:</strong> <span>90</span></li>
                                        <li><strong>AGE:</strong> <span>28</span></li>
                                        <li><strong>Goals:</strong> <span>108</span></li>
                                        <li><strong>Discipline:</strong> <span>4 fouls against</span></li>
                                        <li><strong>Passing:</strong> <span>40 free kicks</span></li>
                                    </ul>

                                    <h6>Follow Jamez Rodriguez</h6>

                                    <ul className="social-player">
                                        <li>
                                            <div>
                                                <a href="!#" className="facebook">
                                                    <i className="fa fa-facebook"></i>
                                                </a>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <a href="!#" className="twitter-icon">
                                                    <i className="fa fa-twitter"></i>
                                                </a>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <a href="!#" className="youtube">
                                                    <i className="fa fa-youtube"></i>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* {<!-- Attack -->} */}
                            <div className="panel-box">
                                <div className="titles no-margin">
                                    <h4><i className="fa fa-user"></i>Personal Info</h4>
                                </div>
                                <ul className="list-panel">
                                    <li><p>Weight <span>70 Kg</span></p></li>
                                    <li><p>Height <span>1.70 Mts</span></p></li>
                                    <li><p>Nationality <span>Colombia</span></p></li>
                                    <li><p>Place of Birth <span>Cucuta</span></p></li>
                                    <li><p>Date of Birth <span>March 5th, 1989</span></p></li>
                                </ul>
                            </div>
                            {/* {<!-- End Attack -->} */}
                        </div>
                        {/* {<!-- end Side info single team-->} */}

                        <div className="col-lg-8 col-xl-9">

                        <Tabs
                            defaultActiveKey="overview"
                            id="uncontrolled-tab-example"
                            className="nav nav-tabs mb-4"
                        >

                               
                                {/* <div className="tab-content"> */}
                                <Tab eventKey="overview" title="Overview">
                                    {/* <!-- Tab One - overview --> */}
                                    <div className="tab-pane active" id="overview">

                                        <div className="panel-box padding-b">
                                            <div className="titles">
                                                <h4>Jamez overview</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-xl-4">
                                                    <img src={SingleTeam} alt="" />
                                                </div>

                                                <div className="col-lg-12 col-xl-8">
                                                    <p>The Colombia national football team (Spanish: Selección de fútbol de Colombia) represents Colombia in international football competitions and is overseen by the Colombian Football Federation. It is a member of the CONMEBOL and is currently ranked thirteenth in the FIFA World Rankings.[3] The team are nicknamed Los Cafeteros due to the coffee production in their country.</p>

                                                    <p>Since the mid-1980s, the national team has been a symbol fighting the country's negative reputation. This has made the sport popular and made the national team a sign of nationalism, pride and passion for many Colombians worldwide.</p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* {<!--Items Club News -->} */}
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h3 className="clear-title">Latest Player News</h3>
                                            </div>

                                            {/* {<!--Item Club News -->} */}
                                            <div className="col-lg-6 col-xl-4">
                                                {/* {<!-- Widget Text-->} */}
                                                <div className="panel-box">
                                                    <div className="titles no-margin">
                                                        <h4><a href="!#">World football's dates.</a></h4>
                                                    </div>
                                                    <a href="!#"><img src={BlogOne} alt="" /></a>
                                                    <div className="row">
                                                        <div className="info-panel">
                                                            <p>Fans from all around the world can apply for 2022 FIFA World Cup™ tickets as the first window of sales.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* {<!-- End Widget Text-->} */}
                                            </div>
                                            {/* {<!--End Item Club News -->} */}

                                            {/* {<!--Item Club News -->} */}
                                            <div className="col-lg-6 col-xl-4">
                                                {/* {<!-- Widget Text-->} */}
                                                <div className="panel-box">
                                                    <div className="titles no-margin">
                                                        <h4><a href="!#">Mbappe’s year to remember</a></h4>
                                                    </div>
                                                    <a href="!#"><img src={BlogTwo} alt="" /></a>
                                                    <div className="row">
                                                        <div className="info-panel">
                                                            <p>Tickets may be purchased online by using Visa payment cards or Visa Checkout. Visa is the official.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* {<!-- End Widget Text-->} */}
                                            </div>
                                            {/* {<!--End Item Club News -->} */}

                                            {/* {<!--Item Club News -->} */}
                                            <div className="col-lg-6 col-xl-4">
                                                {/* {<!-- Widget Text-->} */}
                                                <div className="panel-box">
                                                    <div className="titles no-margin">
                                                        <h4><a href="!#">Egypt are one family</a></h4>
                                                    </div>
                                                    <a href="!#"><img src={BlogThree} alt="" /></a>
                                                    <div className="row">
                                                        <div className="info-panel">
                                                            <p>Successful applicants who have applied for supporter tickets and conditional supporter tickets will.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* {<!-- End Widget Text-->} */}
                                            </div>
                                            {/* {<!--End Item Club News -->} */}
                                        </div>
                                        {/* {<!--End Items Club News -->} */}


                                        {/* {<!--Items Club video -->} */}
                                        <div className="row no-line-height">
                                            <div className="col-md-12">
                                                <h3 className="clear-title">Latest Player Videos</h3>
                                            </div>

                                            {/* {<!--Item Club News -->} */}
                                            <div className="col-lg-6 col-xl-4">
                                                {/* {<!-- Widget Text-->} */}
                                                <div className="panel-box">
                                                    <div className="titles no-margin">
                                                        <h4><a href="!#">Eliminatory to the world.</a></h4>
                                                    </div>
                                                    <iframe className="video" src="https://www.youtube.com/embed/Ln8rXkeeyP0" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe>
                                                </div>
                                                {/* {<!-- End Widget Text-->} */}
                                            </div>
                                            {/* {<!--End Item Club News -->} */}

                                            {/* {<!--Item Club News -->} */}
                                            <div className="col-lg-6 col-xl-4">
                                                {/* {<!-- Widget Text-->} */}
                                                <div className="panel-box">
                                                    <div className="titles no-margin">
                                                        <h4><a href="!#">Colombia classification</a></h4>
                                                    </div>
                                                    <iframe className="video" src="https://www.youtube.com/embed/Z5cackyUfgk" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe>
                                                </div>
                                                {/* {<!-- End Widget Text-->} */}
                                            </div>
                                            {/* {<!--End Item Club News -->} */}

                                            {/* {<!--Item Club News -->} */}
                                            <div className="col-lg-6 col-xl-4">
                                                {/* {<!-- Widget Text-->} */}
                                                <div className="panel-box">
                                                    <div className="titles no-margin">
                                                        <h4><a href="!#">World Cup goal</a></h4>
                                                    </div>
                                                    <iframe className="video" src="https://www.youtube.com/embed/hW3hnUoUS0k" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe>
                                                </div>
                                                {/* {<!-- End Widget Text-->} */}
                                            </div>
                                            {/* {<!--End Item Club News -->} */}
                                        </div>
                                        {/* {<!--End Items Club video -->} */}

                                        {/* {<!--Sponsors CLub -->} */}
                                        <div className="row no-line-height">
                                                <div className="col-md-12">
                                                    <h3 className="clear-title">Sponsors Player</h3>
                                                </div>
                                        </div>
                                        {/* {<!--End Sponsors CLub -->} */}

                                        <LogoSlider />

                                    </div>
                                    {/* <!-- Tab One - overview --> */}
                                </Tab>

                                <Tab eventKey="career" title="CAREER">
                                    {/* <!-- Tab Theree - career --> */}
                                    <div className="tab-pane" id="career">
                                        <div className="col-lg-12">
                                            <table className="table-striped table-responsive table-hover career">
                                                <thead>
                                                    <tr>
                                                        <th>Season</th>
                                                        <th>Club</th>
                                                        <th>Apps(Subs)</th>
                                                        <th>Goals</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            2017/2018
                                                        </td>
                                                        <td>
                                                            <img src={ColombiaClubLogo} alt="icon1" />
                                                            Japan
                                                        </td>
                                                        <td>22(0)</td>
                                                        <td>
                                                            50
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            2017/2018
                                                        </td>
                                                        <td>
                                                            <img src={JapanClubLogo} alt="icon1" />
                                                            Japan
                                                        </td>
                                                        <td>22(0)</td>
                                                        <td>
                                                            50
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            2017/2018
                                                        </td>
                                                        <td>
                                                            <img src={BrazilClubLogo} alt="icon1" />
                                                            Japan
                                                        </td>
                                                        <td>22(0)</td>
                                                        <td>
                                                            50
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            2017/2018
                                                        </td>
                                                        <td>
                                                            <img src={ArgentinaClubLogo} alt="icon1" />
                                                            Japan
                                                        </td>
                                                        <td>22(0)</td>
                                                        <td>
                                                            50
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            2017/2018
                                                        </td>
                                                        <td>
                                                            <img src={UrugueyClubLogo} alt="icon1" />
                                                            Japan
                                                        </td>
                                                        <td>22(0)</td>
                                                        <td>
                                                            50
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            2017/2018
                                                        </td>
                                                        <td>
                                                            <img src={ngaClubLogo} alt="icon1" />
                                                            Japan
                                                        </td>
                                                        <td>22(0)</td>
                                                        <td>
                                                            50
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            2017/2018
                                                        </td>
                                                        <td>
                                                            <img src={MexicoClubLogo} alt="icon1" />
                                                            Japan
                                                        </td>
                                                        <td>22(0)</td>
                                                        <td>
                                                            50
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            2017/2018
                                                        </td>
                                                        <td>
                                                            <img src={RussiaClubLogo} alt="icon1" />
                                                            Japan
                                                        </td>
                                                        <td>22(0)</td>
                                                        <td>
                                                            50
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            2017/2018
                                                        </td>
                                                        <td>
                                                            <img src={AustraliaClubLogo} alt="icon1" />
                                                            Japan
                                                        </td>
                                                        <td>22(0)</td>
                                                        <td>
                                                            50
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            2017/2018
                                                        </td>
                                                        <td>
                                                            <img src={ArabiaClubLogo} alt="icon1" />
                                                            Japan
                                                        </td>
                                                        <td>22(0)</td>
                                                        <td>
                                                            50
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <!-- Tab Theree - career --> */}
                                </Tab>

                                <Tab eventKey="stats" title="STATS">


                                    {/* <!-- Tab Theree - stats --> */}
                                    <div className="tab-pane" id="stats">
                                        
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="stats-info">
                                                    <ul>
                                                        <li>
                                                            Appearances
                                                            <h3>50</h3>
                                                        </li>

                                                        <li>
                                                            Goals
                                                            <h3>10</h3>
                                                        </li>

                                                        <li>
                                                            Wins
                                                            <h3>16</h3>
                                                        </li>

                                                        <li>
                                                            Losses
                                                            <h3>5</h3>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-xl-4">
                                                {/* {<!-- Attack -->} */}
                                                <div className="panel-box">
                                                    <div className="titles no-margin">
                                                        <h4><i className="fa fa-calendar"></i>Attack</h4>
                                                    </div>
                                                    <ul className="list-panel">
                                                        <li><p>Goals <span>60</span></p></li>
                                                        <li><p>Goals Per Match <span>1.37</span></p></li>
                                                        <li><p>Shots <span>4,621</span></p></li>
                                                        <li><p>Shooting Accuracy % <span>32%</span></p></li>
                                                        <li><p>Penalties Scored <span>30</span></p></li>
                                                        <li><p>Big Chances Created <span>293</span></p></li>
                                                        <li><p>Hit Woodwork <span>107</span></p></li>
                                                    </ul>
                                                </div>
                                                {/* {<!-- End Attack -->} */}
                                            </div>

                                            <div className="col-lg-6 col-xl-4">
                                                {/* {<!-- Attack -->} */}
                                                <div className="panel-box">
                                                    <div className="titles no-margin">
                                                        <h4><i className="fa fa-calendar"></i>Team Play</h4>
                                                    </div>
                                                    <ul className="list-panel">
                                                        <li><p>Passes <span>140,417</span></p></li>
                                                        <li><p>Passes Per Match <span>162.14</span></p></li>
                                                        <li><p>Pass Accuracy % <span>76%</span></p></li>
                                                        <li><p>Crosses <span>8,148</span></p></li>
                                                        <li><p>Cross Accuracy % <span>22%</span></p></li>
                                                    </ul>
                                                </div>
                                                {/* {<!-- End Attack -->} */}
                                            </div>

                                            <div className="col-lg-6 col-xl-4">
                                                {/* {<!-- Attack -->} */}
                                                <div className="panel-box">
                                                    <div className="titles no-margin">
                                                        <h4><i className="fa fa-calendar"></i>Defence</h4>
                                                    </div>
                                                    <ul className="list-panel">
                                                        <li><p>Clean Sheets <span>226</span></p></li>
                                                        <li><p>Goals Conceded <span>1,170</span></p></li>
                                                        <li><p>Goals Conceded Per Match <span>1.35</span></p></li>
                                                        <li><p>Saves <span>392</span></p></li>
                                                        <li><p>Tackles <span>7,438</span></p></li>
                                                        <li><p>Tackle Success % <span>75%</span></p></li>
                                                        <li><p>Blocked Shots <span>1,208</span></p></li>
                                                        <li><p>Interceptions <span>5,334</span></p></li>
                                                        <li><p>Clearances <span>11,436</span></p></li>
                                                        <li><p>Headed Clearance <span>3,710</span></p></li>
                                                        <li><p>Aerial Battles/Duels Won <span>25,401</span></p></li>
                                                        <li><p>Errors Leading To Goal <span>59</span></p></li>
                                                        <li><p>Own Goals <span>27</span></p></li>
                                                    </ul>
                                                </div>
                                                {/* {<!-- End Attack -->} */}
                                            </div>
                                        </div>

                                    </div>
                                    {/* <!-- End Tab Theree - stats --> */}
                                    </Tab>
                                {/* </div> */}
                                {/* <!-- Content Tabs --> */}

                            </Tabs>

                            
                            
                            {/* {<!-- Content Tabs -->} */}
                        </div>
                    </div>
                </div>
            </div>
            {/* {<!-- Single Team Tabs -->} */}

            {/* {<!-- Newsletter -->} */}
            <NewsLetter />
            {/* {<!-- End Newsletter -->} */}
        </section>
        {/* {<!-- End Section Area -  Content Central -->} */}

        <div className="instagram-btn">
            <div className="btn-instagram">
                <i className="fa fa-instagram"></i>
                FOLLOW
                <a href="https://www.instagram.com/fifaworldcup/" target="_blank">&#64;fifaworldcup</a>
            </div>
        </div>

        <div className="content-instagram">
            <div id="instafeed"></div>
        </div>          
    </>
  );
};

export default SinglePlayer;
