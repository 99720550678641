import React, { isValidElement } from "react";
import SlideOne from "../../../assets/img/basket-ball/slide/1.jpg";
import NewsLetter from "../../../components/news/news-letter";

import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { useEffect } from "react";

import PortfolioFilter from "../../../components/portfoliofilter/portfoliofilter";
  
const Teams = (props) => {

    useEffect(() => { 
     
    }, []);
  
  
  

  return (
    <>
      {/* <!-- Section Title --> */}
      <div
        className="section-title"
        style={{ background: 'url("' + SlideOne + '")' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1>Teams List</h1>
            </div>

            <div className="col-md-4">
              <div className="breadcrumbs">
                <ul>
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>Inner Page</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Section Title --> */}

      {/* <!-- Section Area - Content Central --> */}
      <section className="content-info">
        
        <PortfolioFilter />

        <NewsLetter />
      </section>
    </>
  );
};

export default Teams;
