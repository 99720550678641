import Isotope from "isotope-layout";
import React from "react";
import { Link } from "react-router-dom";

import RussiaClubLogo from "../../assets/img/cricket/clubs-logos/rusia.png";
import ArabiaClubLogo from "../../assets/img/cricket/clubs-logos/arabia.png";
import EgyptClubLogo from "../../assets/img/cricket/clubs-logos/egy.png";
import UrugueyClubLogo from "../../assets/img/cricket/clubs-logos/uru.png";
import PortagalClubLogo from "../../assets/img/cricket/clubs-logos/por.png";
import SpainClubLogo from "../../assets/img/cricket/clubs-logos/esp.png";
import MorrocoClubLogo from "../../assets/img/cricket/clubs-logos/mar.png";
import IranClubLogo from "../../assets/img/cricket/clubs-logos/irn.png";
import FranceClubLogo from "../../assets/img/cricket/clubs-logos/fra.png";
import AustraliaClubLogo from "../../assets/img/cricket/clubs-logos/aus.png";
import PeruClubLogo from "../../assets/img/cricket/clubs-logos/per.png";
import DenmarkClubLogo from "../../assets/img/cricket/clubs-logos/den.png";
import ArgentinaClubLogo from "../../assets/img/cricket/clubs-logos/arg.png";
import IcelandClubLogo from "../../assets/img/cricket/clubs-logos/isl.png";
import CrotiaClubLogo from "../../assets/img/cricket/clubs-logos/cro.png";
import NigeriaClubLogo from "../../assets/img/cricket/clubs-logos/nga.png";
import BrazilClubLogo from "../../assets/img/cricket/clubs-logos/bra.png";
import SwitzerlandClubLogo from "../../assets/img/cricket/clubs-logos/sui.png";
import CostaRicaClubLogo from "../../assets/img/cricket/clubs-logos/costa-rica.png";
import SerbiaClubLogo from "../../assets/img/cricket/clubs-logos/sen.png";
import GermanyClubLogo from "../../assets/img/cricket/clubs-logos/ger.png";
import MexicoClubLogo from "../../assets/img/cricket/clubs-logos/mex.png";
import SwedonClubLogo from "../../assets/img/cricket/clubs-logos/swe.png";
import KoreaClubLogo from "../../assets/img/cricket/clubs-logos/kor.png";
import BelgiumClubLogo from "../../assets/img/cricket/clubs-logos/bel.png";
import PanamaClubLogo from "../../assets/img/cricket/clubs-logos/pan.png";

import TunisiaClubLogo from "../../assets/img/cricket/clubs-logos/tun.png";
import EnglanClubLogo from "../../assets/img/cricket/clubs-logos/eng.png";
import PolandClubLogo from "../../assets/img/cricket/clubs-logos/pol.png";
import SenegalClubLogo from "../../assets/img/cricket/clubs-logos/sen.png";
import ColombiaClubLogo from "../../assets/img/cricket/clubs-logos/colombia.png";
import JapanClubLogo from "../../assets/img/cricket/clubs-logos/japan.png";

import RussiaClubTeam from "../../assets/img/cricket/clubs-teams/Afghanistan.png";
import ArabiaClubTeam from "../../assets/img/cricket/clubs-teams/AUS.png";
import EgyptClubTeam from "../../assets/img/cricket/clubs-teams/BAN.png";
import UrugueyClubTeam from "../../assets/img/cricket/clubs-teams/ENG.png";
import PortugalClubTeam from "../../assets/img/cricket/clubs-teams/IND.png";
import SpainClubTeam from "../../assets/img/cricket/clubs-teams/IRE.png";
import MorrocoClubTeam from "../../assets/img/cricket/clubs-teams/NZ.png";
import IranClubTeam from "../../assets/img/cricket/clubs-teams/PAK.png";
import FranceClubTeam from "../../assets/img/cricket/clubs-teams/SA.png";
import AustraliaClubTeam from "../../assets/img/cricket/clubs-teams/SL.png";
import PeruClubTeam from "../../assets/img/cricket/clubs-teams/WI.png";
import DenmarkClubTeam from "../../assets/img/cricket/clubs-teams/denmark.jpg";


const CricketPortfoliofilter = () => {
  // init one ref to store the future isotope object
  const isotope = React.useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = React.useState("*");

  // initialize an Isotope object with configs
  React.useEffect(() => {
    isotope.current = new Isotope(".portfolioContainer", {
      itemSelector: ".filter-item",
      layoutMode: "fitRows",
    });
    // cleanup
    return () => isotope.current.destroy();
  }, []);

  // handling filter key change
  React.useEffect(() => {
    filterKey === "*"
      ? isotope.current.arrange({ filter: `*` })
      : isotope.current.arrange({ filter: `.${filterKey}` });
  }, [filterKey]);

  /**
   * set filter key
   * @param {*} key
   * @returns
   */
  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      {/* <!-- Nav Filters --> */}
      <div className="portfolioFilter">
        <div className="container">
          <h5>
            <i className="fa fa-filter" aria-hidden="true"></i>Filter By:
          </h5>
          <a
            href="javascript:;"
            className={`${filterKey === "*" ? "current" : ""}`}
            onClick={handleFilterKeyChange("*")}
          >
            Show All
          </a>
          <a
            href="javascript:;"
            onClick={handleFilterKeyChange("group-a")}
            className={`${filterKey === "group-a" ? "current" : ""}`}
          >
            Group A
          </a>
          <a
            href="javascript:;"
            className={`${filterKey === "group-b" ? "current" : ""}`}
            onClick={handleFilterKeyChange("group-b")}
          >
            Group B
          </a>
          <a
            href="javascript:;"
            className={`${filterKey === "group-c" ? "current" : ""}`}
            onClick={handleFilterKeyChange("group-c")}
          >
            Group C
          </a>
  

        </div>
      </div>
      {/* <!-- End Nav Filters --> */}

      <div className="container padding-top">
        <div className="row portfolioContainer h-100">
          {/* <!-- Item Team Group A--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-a">
            <div className="item-team">
              <div className="head-team">
                <img src={RussiaClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={RussiaClubLogo} alt="logo-team" />
                </span>
                <h4> Afghanistan </h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group A
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group A--> */}

          {/* <!-- Item Team Group A--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-a">
            <div className="item-team">
              <div className="head-team">
                <img src={ArabiaClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={ArabiaClubLogo} alt="logo-team" />
                </span>
                <h4> Australia </h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group A
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group A--> */}

          {/* <!-- Item Team Group A--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-a">
            <div className="item-team">
              <div className="head-team">
                <img src={EgyptClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={EgyptClubLogo} alt="logo-team" />
                </span>
                <h4> Bangladesh </h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group A
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group A--> */}

          {/* <!-- Item Team Group A--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-a">
            <div className="item-team">
              <div className="head-team">
                <img src={UrugueyClubTeam} alt="location-team" />
                <div className="overlay">
                  <Link to="/single-team">+</Link>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={UrugueyClubLogo} alt="logo-team" />
                </span>
                <h4> England </h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group A
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group A--> */}

          {/* <!-- Item Team Group B--> */}

          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-b">
            <div className="item-team">
              <div className="head-team">
                <img src={PortugalClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={PortagalClubLogo} alt="logo-team" />
                </span>
                <h4>India</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group B
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group B--> */}

          {/* <!-- Item Team Group B--> */}

          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-b">
            <div className="item-team">
              <div className="head-team">
                <img src={SpainClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={SpainClubLogo} alt="logo-team" />
                </span>
                <h4>Ireland</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group B
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group B--> */}

          {/* <!-- Item Team Group B--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-b">
            <div className="item-team">
              <div className="head-team">
                <img src={MorrocoClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={MorrocoClubLogo} alt="logo-team" />
                </span>
                <h4>New Zealand</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group B
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group B--> */}

          {/* <!-- Item Team Group B--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-b">
            <div className="item-team">
              <div className="head-team">
                <img src={IranClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={IranClubLogo} alt="logo-team" />
                </span>
                <h4>Pakistan</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group B
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group B--> */}

          {/* <!-- Item Team Group C--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-c">
            <div className="item-team">
              <div className="head-team">
                <img src={FranceClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={FranceClubLogo} alt="logo-team" />
                </span>
                <h4>South Africa</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group C
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group C--> */}

          {/* <!-- Item Team Group C--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-c">
            <div className="item-team">
              <div className="head-team">
                <img src={AustraliaClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={AustraliaClubLogo} alt="logo-team" />
                </span>
                <h4>Sri Lanka</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group C
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group C--> */}

          {/* <!-- Item Team Group C--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-c">
            <div className="item-team">
              <div className="head-team">
                <img src={PeruClubTeam} alt="location-team" />
                <div className="overlay">
                  <a href="/single-team">+</a>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={PeruClubLogo} alt="logo-team" />
                </span>
                <h4>West Indies</h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group C
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group C--> */}

          {/* <!-- Item Team Group C--> */}
          <div className="col-md-6 col-lg-4 col-xl-3 filter-item group-c">
            <div className="item-team">
              <div className="head-team">
                <img src={DenmarkClubTeam} alt="location-team" />
                <div className="overlay">
                  <Link to="/single-team">+</Link>
                </div>
              </div>
              <div className="info-team">
                <span className="logo-team">
                  <img src={DenmarkClubLogo} alt="logo-team" />
                </span>
                <h4> Zimbabwe </h4>
                <span className="location-team">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  Stadium
                </span>
                <span className="group-team">
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Group C
                </span>
              </div>
              <a href="/single-team" className="btn">
                Team Profile
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          {/* <!-- End Item Team Group C--> */}





          {/* <!-- Item Team Group G--> */}
         
          {/* <!-- End Item Team Group G--> */}

         

         
        </div>
      </div>
    </>
  );
};

export default CricketPortfoliofilter;
