import axios from "axios";
import React, { useEffect, useState } from "react";

import SlideOne from "../../assets/img/slide/1.jpg";

const SingleNewsContents = ({ slug,game_id,language_id }) => {

  // alert(slug);

  const [singleNewsData, setSingleNewsData] = useState([]);
  const apisinglenews = async () => {

    var base_url = "https://sportsbackend.pixeltechnosol.com/api/";
    var response = await axios.get(base_url+'single-news/'+slug+'/'+game_id+'/'+language_id);

    // console.log(JSON.stringify(response.data.data.items));
    setSingleNewsData(response.data.data.items);

  }

  const renderSingleNewsData = (singlenews, index) => {
    return (
      <>
        <div className="titles no-margin">
            <h4>{singlenews.title}</h4>
        </div>
        <img src={SlideOne} alt="" />
        <div className="info-panel">
            {singlenews.description}
        </div>
    </>
    );

  } 

  useEffect(() => {
    apisinglenews();
  }, []);

  

  return (

    <>
      {/* <!-- Single News Contents --> */}
      <div className="panel-box">
        {singleNewsData.map(renderSingleNewsData)}
      </div>
      {/* <!-- End Single News Contents --> */}
    </>
  );
};

export default SingleNewsContents;
