/*
import HomePage from "../views/football/home/index";
import TeamsPage from "../views/football/team/teams";
import FixturePage from "../views/football/fixture/fixtures";
import ResultsPage from "../views/football/result/results";
import SingleResultPage from "../views/football/singleresult/single-results";
import GroupsPage from "../views/football/groups/groups";
import PointTablePage from "../views/football/pointtable/point-table";
import NewsPage from "../views/football/news/news";
import ContactPage from "../views/football/contact/contact";
import AboutPage from "../views/football/about/about";
import FaqPage from "../views/football/faq/faq";
import PageNotFound from "../views/errors/page-not-found";
import RegisterPage from "../views/football/register/register";
import PricingPage from "../views/football/pricing/pricing";
import ServicesPage from "../views/football/services/services";
import SingleNewsPage from "../views/football/singlenews/single-news";
import PlayersPage from "../views/football/players/players";
import SinglePlayerPage from "../views/football/players/single-player";
import SingleTeamPage from "../views/football/team/single-team";
import PredictionContestPage from "../views/football/predictioncontest/prediction-contest";
import LeaguesPage from "../views/football/leagues/leagues";
import PredictionLeaguePage from "../views/football/predictionleague/prediction-league";
import MatchResultsPage from "../views/football/matchresults/match-results";

import IsotopePage from "../views/football/isotope/isotope";
import LiveStreamPage from "../views/football/livestream/live-stream";
import SingleLeaguePage from "../views/football/singleleague/single-league";
*/

import footballRoutes from "./categories/football";
import basketBallRoutes from "./categories/basket-ball";
import cricketRoutes from "./categories/cricket";
import tennisRoutes from "./categories/tennis";

/* Basket Ball Routes */
/*

import BS_HomePage from "../views/basket-ball/home/index";
import BS_TeamsPage from "../views/basket-ball/team/teams";
import BS_FixturePage from "../views/basket-ball/fixture/fixtures";
import BS_ResultsPage from "../views/basket-ball/result/results";
import BS_SingleResultPage from "../views/basket-ball/singleresult/single-results";
import BS_GroupsPage from "../views/basket-ball/groups/groups";
import BS_PointTablePage from "../views/basket-ball/pointtable/point-table";
import BS_NewsPage from "../views/basket-ball/news/news";
import BS_ContactPage from "../views/basket-ball/contact/contact";
import BS_AboutPage from "../views/basket-ball/about/about";tennisRoutes
import BS_FaqPage from "../views/basket-ball/faq/faq";
import BS_RegisterPage from "../views/basket-ball/register/register";
import BS_PricingPage from "../views/basket-ball/pricing/pricing";
import BS_ServicesPage from "../views/basket-ball/services/services";
import BS_SingleNewsPage from "../views/basket-ball/singlenews/single-news";
import BS_PlayersPage from "../views/basket-ball/players/players";
import BS_SinglePlayerPage from "../views/basket-ball/players/single-player";
import BS_SingleTeamPage from "../views/basket-ball/team/single-team";
import BS_PredictionContestPage from "../views/basket-ball/predictioncontest/prediction-contest";
import BS_LeaguesPage from "../views/basket-ball/leagues/leagues";
import BS_PredictionLeaguePage from "../views/basket-ball/predictionleague/prediction-league";
import BS_MatchResultsPage from "../views/basket-ball/matchresults/match-results";
import BS_IsotopePage from "../views/basket-ball/isotope/isotope";
import BS_LiveStreamPage from "../views/basket-ball/livestream/live-stream";
import BS_SingleLeaguePage from "../views/basket-ball/singleleague/single-league";

*/

/**
 * Cricket routes
 */

/*
import CR_HomePage from "../views/cricket/home/index";
import CR_TeamsPage from "../views/cricket/team/teams";
import CR_FixturePage from "../views/cricket/fixture/fixtures";
import CR_ResultsPage from "../views/cricket/result/results";
import CR_SingleResultPage from "../views/cricket/singleresult/single-results";
import CR_GroupsPage from "../views/cricket/groups/groups";
import CR_PointTablePage from "../views/cricket/pointtable/point-table";
import CR_NewsPage from "../views/cricket/news/news";
import CR_ContactPage from "../views/cricket/contact/contact";
import CR_AboutPage from "../views/cricket/about/about";
import CR_FaqPage from "../views/cricket/faq/faq";
import CR_RegisterPage from "../views/cricket/register/register";
import CR_PricingPage from "../views/cricket/pricing/pricing";
import CR_ServicesPage from "../views/cricket/services/services";
import CR_SingleNewsPage from "../views/cricket/singlenews/single-news";
import CR_PlayersPage from "../views/cricket/players/players";
import CR_SinglePlayerPage from "../views/cricket/players/single-player";
import CR_SingleTeamPage from "../views/cricket/team/single-team";
import CR_PredictionContestPage from "../views/cricket/predictioncontest/prediction-contest";
import CR_LeaguesPage from "../views/cricket/leagues/leagues";
import CR_PredictionLeaguePage from "../views/cricket/predictionleague/prediction-league";
import CR_MatchResultsPage from "../views/cricket/matchresults/match-results";

import CR_IsotopePage from "../views/cricket/isotope/isotope";
import CR_LiveStreamPage from "../views/cricket/livestream/live-stream";
import CR_SingleLeaguePage from "../views/cricket/singleleague/single-league";
*/

const routes = [
  ...footballRoutes,
  ...basketBallRoutes,
  ...cricketRoutes,
  ...tennisRoutes,
  /*
  {
    component: HomePage,
    exact: true,
    path: "/",
  },
  {
    component: TeamsPage,
    exact: true,
    path: "/teams",
  },
  {
    component: FixturePage,
    exact: true,
    path: "/fixtures",
  },
  {
    component: ResultsPage,
    exact: true,
    path: "/results",
  },
  {
    component: SingleResultPage,
    exact: true,
    path: "/single-results",
  },

  {
    component: GroupsPage,
    exact: true,
    path: "/groups",
  },
  {
    component: PointTablePage,
    exact: true,
    path: "/point-table",
  },
  {
    component: NewsPage,
    exact: true,
    path: "/news",
  },
  {
    component: ContactPage,
    exact: true,
    path: "/contact",
  },
  {
    component: AboutPage,
    exact: true,
    path: "/about",
  },
  {
    component: FaqPage,
    exact: true,
    path: "/faq",
  },
  {
    component: PageNotFound,
    exact: true,
    path: "/page-not-found",
  },
  {
    component: RegisterPage,
    exact: true,
    path: "/register",
  },
  {
    component: PricingPage,
    exact: true,
    path: "/pricing",
  },
  {
    component: ServicesPage,
    exact: true,
    path: "/services",
  },
  {
    component: IsotopePage,
    exact: true,
    path: "/isotope",
  },
  {
    component: PlayersPage,
    exact: true,
    path: "/players",
  },
  {
    component: SingleNewsPage,
    exact: true,
    path: "/single-news/:slug",
  },
  {
    component: SinglePlayerPage,
    exact: true,
    path: "/single-player",
  },
  {
    component: SingleTeamPage,
    exact: true,
    path: "/single-team",
  },
  {
    component: LiveStreamPage,
    exact: true,
    path: "/live-stream",
  },
  {
    component: PredictionContestPage,
    exact: true,
    path: "/prediction-contest",
  },
  {
    component: LeaguesPage,
    exact: true,
    path: "/leagues",
  },
  {
    component: SingleLeaguePage,
    exact: true,
    path: "/single-league",
  },
  {
    component: PredictionLeaguePage,
    exact: true,
    path: "/prediction-league",
  },
  {
    component: MatchResultsPage,
    exact: true,
    path: "/match-results",
  },

  */

  // Routes For Basket Ball
  /*
  {
    component: BS_HomePage,
    exact: true,
    path: "/basket-ball/",
  },
  {
    component: BS_TeamsPage,
    exact: true,
    path: "/basket-ball/teams",
  },
  {
    component: BS_FixturePage,
    exact: true,
    path: "/basket-ball/fixtures",
  },
  {
    component: BS_ResultsPage,
    exact: true,
    path: "/basket-ball/results",
  },
  {
    component: BS_SingleResultPage,
    exact: true,
    path: "/basket-ball/single-results",
  },
  {
    component: BS_GroupsPage,
    exact: true,
    path: "/basket-ball/groups",
  },
  {
    component: BS_PointTablePage,
    exact: true,
    path: "/basket-ball/point-table",
  },
  {
    component: BS_NewsPage,
    exact: true,
    path: "/basket-ball/news",
  },
  {
    component: BS_ContactPage,
    exact: true,
    path: "/basket-ball/contact",
  },
  {
    component: BS_AboutPage,
    exact: true,
    path: "/basket-ball/about",
  },
  {
    component: BS_FaqPage,
    exact: true,
    path: "/basket-ball/faq",
  },
  {
    component: BS_RegisterPage,
    exact: true,
    path: "/basket-ball/register",
  },
  {
    component: BS_PricingPage,
    exact: true,
    path: "/basket-ball/pricing",
  },
  {
    component: BS_ServicesPage,
    exact: true,
    path: "/basket-ball/services",
  },
  {
    component: BS_IsotopePage,
    exact: true,
    path: "/basket-ball/isotope",
  },
  {
    component: BS_PlayersPage,
    exact: true,
    path: "/basket-ball/players",
  },
  {
    component: BS_SingleNewsPage,
    exact: true,
    path: "/basket-ball/single-news/:slug",
  },
  {
    component: BS_SinglePlayerPage,
    exact: true,
    path: "/basket-ball/single-player",
  },
  {
    component: BS_SingleTeamPage,
    exact: true,
    path: "/basket-ball/single-team",
  },
  {
    component: BS_LiveStreamPage,
    exact: true,
    path: "/basket-ball/live-stream",
  },
  {
    component: BS_PredictionContestPage,
    exact: true,
    path: "/basket-ball/prediction-contest",
  },
  {
    component: BS_LeaguesPage,
    exact: true,
    path: "/basket-ball/leagues",
  },
  {
    component: BS_SingleLeaguePage,
    exact: true,
    path: "/basket-ball/single-league",
  },
  {
    component: BS_PredictionLeaguePage,
    exact: true,
    path: "/basket-ball/prediction-league",
  },
  {
    component: BS_MatchResultsPage,
    exact: true,
    path: "/basket-ball/match-results",
  },
  */

  // Routes For Cricket
  /*
  {
    component: CR_HomePage,
    exact: true,
    path: "/cricket/",
  },
  {
    component: CR_TeamsPage,
    exact: true,
    path: "/cricket/teams",
  },
  {
    component: CR_FixturePage,
    exact: true,
    path: "/cricket/fixtures",
  },
  {
    component: CR_ResultsPage,
    exact: true,
    path: "/cricket/results",
  },
  {
    component: CR_SingleResultPage,
    exact: true,
    path: "/cricket/single-results",
  },

  {
    component: CR_GroupsPage,
    exact: true,
    path: "/cricket/groups",
  },
  {
    component: CR_PointTablePage,
    exact: true,
    path: "/cricket/point-table",
  },
  {
    component: CR_NewsPage,
    exact: true,
    path: "/cricket/news",
  },
  {
    component: CR_ContactPage,
    exact: true,
    path: "/cricket/contact",
  },
  {
    component: CR_AboutPage,
    exact: true,
    path: "/cricket/about",
  },
  {
    component: CR_FaqPage,
    exact: true,
    path: "/cricket/faq",
  },

  {
    component: CR_RegisterPage,
    exact: true,
    path: "/cricket/register",
  },
  {
    component: CR_PricingPage,
    exact: true,
    path: "/cricket/pricing",
  },
  {
    component: CR_ServicesPage,
    exact: true,
    path: "/cricket/services",
  },
  {
    component: CR_IsotopePage,
    exact: true,
    path: "/cricket/isotope",
  },
  {
    component: CR_PlayersPage,
    exact: true,
    path: "/cricket/players",
  },
  {
    component: CR_SingleNewsPage,
    exact: true,
    path: "/cricket/single-news/:slug",
  },
  {
    component: CR_SinglePlayerPage,
    exact: true,
    path: "/cricket/single-player",
  },
  {
    component: CR_SingleTeamPage,
    exact: true,
    path: "/cricket/single-team",
  },
  {
    component: CR_LiveStreamPage,
    exact: true,
    path: "/cricket/live-stream",
  },
  {
    component: CR_PredictionContestPage,
    exact: true,
    path: "/cricket/prediction-contest",
  },
  {
    component: CR_LeaguesPage,
    exact: true,
    path: "/cricket/leagues",
  },
  {
    component: CR_SingleLeaguePage,
    exact: true,
    path: "/cricket/single-league",
  },
  {
    component: CR_PredictionLeaguePage,
    exact: true,
    path: "/cricket/prediction-league",
  },
  {
    component: CR_MatchResultsPage,
    exact: true,
    path: "/cricket/match-results",
  },
  */
];

export default routes;
