import React, { Fragment } from "react";

import Header from "./Header";
//import Footer from "./Footer";

const Layout = ({ Component, route, props }) => (
  <>
    <div className="">
      <Header />
      <section>
        <Component route={route} />
      </section>

      {/* <Footer /> */}
    </div>
  </>
);

export default Layout;
