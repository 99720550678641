import React from "react";

const NewsLetter = (props) => {
  return (
    <>
      {/* <!-- Newsletter --> */}
      <div className="section-newsletter no-margin">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-center">
                <h2>
                  Enter your e-mail and
                  <span className="text-resalt"> subscribe</span> to our
                  newsletter.
                </h2>
                <p>
                  Duis non lorem porta, eros sit amet, tempor sem. Donec nunc
                  arcu, semper a tempus et, consequat.
                </p>
              </div>
              <form
                id="newsletterForm"
                action="php/mailchip/newsletter-subscribe.php"
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group">
                      <span className="input-group-addon">
                        <i className="fa fa-envelope"></i>
                      </span>
                      <input
                        className="form-control"
                        placeholder="Your Name"
                        name="name"
                        type="text"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <span className="input-group-addon">
                        <i className="fa fa-envelope"></i>
                      </span>
                      <input
                        className="form-control"
                        placeholder="Your  Email"
                        name="email"
                        type="email"
                        required="required"
                      />
                      <span className="input-group-btn">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          name="subscribe"
                        >
                          SIGN UP
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
              <div id="result-newsletter"></div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Newsletter --> */}
    </>
  );
};

export default NewsLetter;
