import React from "react";
import { Link } from "react-router-dom";
import NewsLetter from "../../../components/news/news-letter";
import LogoSlider from "../../../components/logoslider/logoslider";

import LocationThree from "../../../assets/img/tennis/locations/3.jpg";

const PricingPage = (props) => {
  return (
    <>
        {/* {<!-- Section Title -->} */}
        <div className="section-title" style={{background:'url("'+LocationThree+'")'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <h1>Pricing Tables</h1>
                    </div>

                    <div className="col-md-4">
                        <div className="breadcrumbs">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Pricing</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* {<!-- End Section Title -->} */}

        {/* {<!-- Section Area - Content Central -->} */}
        <section className="content-info">

            {/* {<!-- White Section -->} */}
            <div className="white-section paddings">
                <div className="container">
                    <div className="row">
                        {/* {<!-- Item Table-->} */}
                        <div className="col-lg-3">
                            <div className="item-price">
                            <h3>Independent</h3>
                            <ul className="features">
                                <li><span className="brand-color">2</span> Family members</li>
                                <li><span className="brand-color">4</span> Transportation allowance</li>
                                <li>Assets Delivery</li><li>&nbsp;</li>
                            </ul>
                            <div className="price"><sup>$</sup><span>9</span></div>
                            <div className="period">per month</div>
                            <a href="!#" className="btn btn-iw">Try It Free!</a>
                            </div>
                        </div>
                        {/* {<!-- End Item Table-->} */}

                        {/* {<!-- Item Table-->} */}
                        <div className="col-lg-3">
                            <div className="item-price">
                            <h3>Salaried</h3>
                            <ul className="features">
                                <li><span className="brand-color">4</span> Family members</li>
                                <li><span className="brand-color">6</span> Transportation allowance</li>
                                <li>Assets Delivery</li><li>&nbsp;</li>
                            </ul>
                            <div className="price"><sup>$</sup><span>12</span></div>
                            <div className="period">per month</div>
                            <a href="!#" className="btn btn-iw">Try It Free!</a>
                            </div>
                        </div>
                        {/* {<!-- End Item Table-->} */}

                        {/* {<!-- Item Table-->} */}
                        <div className="col-lg-3">
                            <div className="item-price">
                            <h3>Businessman</h3>
                            <ul className="features">
                                <li><span className="brand-color">10</span> Family members</li>
                                <li><span className="brand-color">7</span> Transportation allowance</li>
                                <li>Assets Delivery</li><li>&nbsp;</li>
                            </ul>
                            <div className="price"><sup>$</sup><span>18</span></div>
                            <div className="period">per month</div>
                            <a href="!#" className="btn btn-iw">Try It Free!</a>
                            </div>
                        </div>
                        {/* {<!-- End Item Table-->} */}

                        {/* {<!-- Item Table-->} */}
                        <div className="col-lg-3">
                            <div className="item-price">
                            <h3>Gama</h3>
                            <ul className="features">
                                <li><span className="brand-color">10</span> Family members</li>
                                <li><span className="brand-color">7</span> Transportation allowance</li>
                                <li>Assets Delivery</li><li>&nbsp;</li>
                            </ul>
                            <div className="price"><sup>$</sup><span>28</span></div>
                            <div className="period">per month</div>
                            <a href="!#" className="btn btn-iw">Try It Free!</a>
                            </div>
                        </div>
                        {/* {<!-- End Item Table-->} */}
                    </div>
                </div>
            </div>
            {/* {<!-- End White Section -->} */}


            {/* {<!-- Sponsors-->} */}
            <div className="default-section paddings-50-50">
                <div className="container">
                    {/* {<!--Sponsors CLub -->} */}
                    <div className="row no-line-height">
                            <div className="col-md-12">
                                <h3 className="clear-title">Sponsors</h3>
                            </div>
                    </div>
                    {/* {<!--End Sponsors CLub -->} */}

                    <LogoSlider />

                </div>
            </div>
            {/* {<!-- End Sponsors -->} */}

            {/* {<!-- Newsletter -->} */}
            <NewsLetter />
            {/* {<!-- End Newsletter -->} */}
        </section>
        {/* {<!-- End Section Area -  Content Central -->} */}

        <div className="instagram-btn">
            <div className="btn-instagram">
                <i className="fa fa-instagram"></i>
                FOLLOW
                <a href="https://www.instagram.com/fifaworldcup/" target="_blank">&#64;fifaworldcup</a>
            </div>
        </div>

        <div className="content-instagram">
            <div id="instafeed"></div>
        </div> 
      
    </>
  );
};

export default PricingPage;
