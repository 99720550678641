import React from "react";
import { Link } from "react-router-dom";
import NewsLetter from "../../../components/news/news-letter";
// import Instagram from "../../../components/instagram/instagram";

import SlideOne from "../../../assets/img/football/slide/1.jpg";

import rusiaClubLogo from "../../../assets/img/football/clubs-logos/rusia.png";
import arabiaClubLogo from "../../../assets/img/football/clubs-logos/arabia.png";
import egyClubLogo from "../../../assets/img/football/clubs-logos/egy.png";
import uruClubLogo from "../../../assets/img/football/clubs-logos/uru.png";
import porClubLogo from "../../../assets/img/football/clubs-logos/por.png";
import espClubLogo from "../../../assets/img/football/clubs-logos/esp.png";
import marClubLogo from "../../../assets/img/football/clubs-logos/mar.png";
import irnClubLogo from "../../../assets/img/football/clubs-logos/irn.png";
import fraClubLogo from "../../../assets/img/football/clubs-logos/fra.png";
import ausClubLogo from "../../../assets/img/football/clubs-logos/aus.png";
import perClubLogo from "../../../assets/img/football/clubs-logos/per.png";
import denClubLogo from "../../../assets/img/football/clubs-logos/den.png";
import argClubLogo from "../../../assets/img/football/clubs-logos/arg.png";
import islClubLogo from "../../../assets/img/football/clubs-logos/isl.png";
import croClubLogo from "../../../assets/img/football/clubs-logos/cro.png";
import ngaClubLogo from "../../../assets/img/football/clubs-logos/nga.png";
import braClubLogo from "../../../assets/img/football/clubs-logos/bra.png";
import suiClubLogo from "../../../assets/img/football/clubs-logos/sui.png";
import costaClubLogo from "../../../assets/img/football/clubs-logos/costa-rica.png";
import srbClubLogo from "../../../assets/img/football/clubs-logos/srb.png";
import gerClubLogo from "../../../assets/img/football/clubs-logos/ger.png";
import mexClubLogo from "../../../assets/img/football/clubs-logos/mex.png";
import sweClubLogo from "../../../assets/img/football/clubs-logos/swe.png";
import korClubLogo from "../../../assets/img/football/clubs-logos/kor.png";
import belClubLogo from "../../../assets/img/football/clubs-logos/bel.png";
import panClubLogo from "../../../assets/img/football/clubs-logos/pan.png";
import tunClubLogo from "../../../assets/img/football/clubs-logos/tun.png";
import engClubLogo from "../../../assets/img/football/clubs-logos/eng.png";
import polClubLogo from "../../../assets/img/football/clubs-logos/pol.png";
import senClubLogo from "../../../assets/img/football/clubs-logos/sen.png";
import colombiaClubLogo from "../../../assets/img/football/clubs-logos/colombia.png";
import japanClubLogo from "../../../assets/img/football/clubs-logos/japan.png";

const Groups = (props) => {
  return (
    <>
      {/* <!-- Section Title --> */}
            <div className="section-title" style={{background:"url('"+SlideOne+"')"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h1>Groups List</h1>
                        </div>

                        <div className="col-md-4">
                            <div className="breadcrumbs">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Inner Page</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Section Title --> */}

            {/* <!-- Section Area - Content Central --> */}
            <section className="content-info">

                <div className="container padding-top">
                    <div className="groups-list page-group">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                               <h5><Link to="/groups">GROUP A</Link></h5>
                                <div className="item-group">
                                    <ul>
                                        <li>
                                           <Link to="/single-team">
                                                <img src={rusiaClubLogo} alt=""/>
                                                Russia
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={arabiaClubLogo} alt=""/>
                                                Saudi Arabia
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={egyClubLogo} alt=""/>
                                                Egypt
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={uruClubLogo} alt=""/>
                                                Uruguay
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                               <h5><Link to="/groups">GROUP B</Link></h5>
                                <div className="item-group">
                                    <ul>
                                        <li>
                                           <Link to="/single-team">
                                                <img src={porClubLogo} alt=""/>
                                                Portugal
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={espClubLogo} alt=""/>
                                                Spain
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={marClubLogo} alt=""/>
                                                Morocco
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={irnClubLogo} alt=""/>
                                                IR Iran
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                               <h5><Link to="/groups">GROUP C</Link></h5>
                                <div className="item-group">
                                    <ul>
                                        <li>
                                           <Link to="/single-team">
                                                <img src={fraClubLogo} alt=""/>
                                                France
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={ausClubLogo} alt=""/>
                                                Australia
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={perClubLogo} alt=""/>
                                                Peru
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={denClubLogo} alt=""/>
                                                Denmark
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                               <h5><Link to="/groups">GROUP D</Link></h5>
                                <div className="item-group">
                                    <ul>
                                        <li>
                                           <Link to="/single-team">
                                                <img src={argClubLogo} alt=""/>
                                                Argentina
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={islClubLogo} alt=""/>
                                                Iceland
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={croClubLogo} alt=""/>
                                                Croatia
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={ngaClubLogo} alt=""/>
                                                Nigeria
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                               <h5><Link to="/groups">GROUP E</Link></h5>
                                <div className="item-group">
                                    <ul>
                                        <li>
                                           <Link to="/single-team">
                                                <img src={braClubLogo} alt=""/>
                                                Brazil
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={suiClubLogo} alt=""/>
                                                Switzerland
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={costaClubLogo} alt=""/>
                                                Costa rica
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={srbClubLogo} alt=""/>
                                                Serbia
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                               <h5><Link to="/groups">GROUP F</Link></h5>
                                <div className="item-group">
                                    <ul>
                                        <li>
                                           <Link to="/single-team">
                                                <img src={gerClubLogo} alt=""/>
                                                Germany
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={mexClubLogo} alt=""/>
                                                Mexico
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={sweClubLogo} alt=""/>
                                                Sweden
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={korClubLogo} alt=""/>
                                                Korea Rep
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                               <h5><Link to="/groups">GROUP G</Link></h5>
                                <div className="item-group">
                                    <ul>
                                        <li>
                                           <Link to="/single-team">
                                                <img src={belClubLogo} alt=""/>
                                                Belgium
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={panClubLogo} alt=""/>
                                                Panama
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={tunClubLogo} alt=""/>
                                                Tunisia
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={engClubLogo} alt=""/>
                                                England
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                               <h5><Link to="/groups">GROUP H</Link></h5>
                                <div className="item-group">
                                    <ul>
                                        <li>
                                           <Link to="/single-team">
                                                <img src={polClubLogo} alt=""/>
                                                Poland
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={senClubLogo} alt=""/>
                                                Senegal
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={colombiaClubLogo} alt=""/>
                                                Colombia
                                            </Link>
                                        </li>

                                        <li>
                                           <Link to="/single-team">
                                                <img src={japanClubLogo} alt=""/>
                                                Japan
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Newsletter --> */}
                <NewsLetter />
                {/* <!-- End Newsletter --> */}
            </section>
            {/* <!-- End Section Area -  Content Central --> */}
      
    </>
  );
};

export default Groups;
