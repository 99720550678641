import Isotope from "isotope-layout";
import React from "react";
import { Link } from "react-router-dom";
import ColombiaClubLogo from "../../assets/img/clubs-logos/colombia.png";
import CristianoImg from "../../assets/img/players/1.jpg";
import MessiImg from "../../assets/img/players/2.jpg";
import NeymarImg from "../../assets/img/players/3.jpg";
import LuisImg from "../../assets/img/players/4.jpg";
import GarethImg from "../../assets/img/players/5.jpg";
import SergioImg from "../../assets/img/players/6.jpg";

const PlayersFilter = () => {
  // init one ref to store the future isotope object
  const isotope = React.useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = React.useState("*");

  // initialize an Isotope object with configs
  React.useEffect(() => {
    isotope.current = new Isotope(".portfolioContainer", {
      itemSelector: ".filter-item",
      layoutMode: "fitRows",
    });
    // cleanup
    return () => isotope.current.destroy();
  }, []);

  // handling filter key change
  React.useEffect(() => {
    filterKey === "*"
      ? isotope.current.arrange({ filter: `*` })
      : isotope.current.arrange({ filter: `.${filterKey}` });
  }, [filterKey]);

  /**
   * set filter key
   * @param {*} key
   * @returns
   */
  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      {/* {<!-- Nav Filters -->} */}
      <div className="portfolioFilter">
            <div className="container">
                <h5><i className="fa fa-filter" aria-hidden="true"></i>Filter By:</h5>
                <a href="javascript:;"
                    className={`${filterKey === "*" ? "current" : ""}`}
                    onClick={handleFilterKeyChange("*")}
                >
                    Show All
                </a>
                <a href="javascript:;"
                    onClick={handleFilterKeyChange("forward")}
                    className={`${filterKey === "forward" ? "current" : ""}`}
                >
                    Forward
                </a>
                <a href="javascript:;"
                    onClick={handleFilterKeyChange("defender")}
                    className={`${filterKey === "defender" ? "current" : ""}`}
                >
                    Defender
                </a>
                <a href="javascript:;"
                    onClick={handleFilterKeyChange("midfielder")}
                    className={`${filterKey === "midfielder" ? "current" : ""}`}
                >
                    Midfielder
                </a>
                <a href="javascript:;"
                    onClick={handleFilterKeyChange("goalkeeper")}
                    className={`${filterKey === "goalkeeper" ? "current" : ""}`}
                >
                    Goalkeeper
                </a>
            </div>
        </div>
        {/* {<!-- End Nav Filters -->} */}

        <div className="container padding-top ">
            <div className="row portfolioContainer h-100 ">

                {/* {<!-- Item Player -->} */}
                <div className="col-xl-3 col-lg-4 col-md-6 filter-item forward">
                    <div className="item-player">
                        <div className="head-player">
                            <img src={CristianoImg} alt="location-team" />
                            <div className="overlay"><Link to="/single-player">+</Link></div>
                        </div>
                        <div className="info-player">
                            <span className="number-player">
                                13
                            </span>
                            <h4>
                                Cristiano Ronaldo
                                <span>Forward</span>
                            </h4>
                            <ul>
                                <li>
                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                </li>
                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                <li><strong>AGE:</strong> <span>28</span></li>
                            </ul>
                        </div>
                        <Link to="/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                    </div>
                </div>
                {/* {<!-- End Item Player -->} */}

                {/* {<!-- Item Player -->} */}
                <div className="col-xl-3 col-lg-4 col-md-6 filter-item defender">
                    <div className="item-player">
                        <div className="head-player">
                            <img src={MessiImg} alt="location-team" />
                            <div className="overlay"><Link to="/single-player">+</Link></div>
                        </div>
                        <div className="info-player">
                            <span className="number-player">
                                10
                            </span>
                            <h4>
                                Lionel Messi
                                <span>Defender</span>
                            </h4>
                            <ul>
                                <li>
                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                </li>
                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                <li><strong>AGE:</strong> <span>28</span></li>
                            </ul>
                        </div>
                        <Link to="/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                    </div>
                </div>
                {/* {<!-- End Item Player -->} */}

                {/* {<!-- Item Player -->} */}
                <div className="col-xl-3 col-lg-4 col-md-6 filter-item midfielder">
                    <div className="item-player">
                        <div className="head-player">
                            <img src={NeymarImg} alt="location-team" />
                            <div className="overlay"><Link to="/single-player">+</Link></div>
                        </div>
                        <div className="info-player">
                            <span className="number-player">
                                2
                            </span>
                            <h4>
                                Neymar
                                <span>Midfielder</span>
                            </h4>
                            <ul>
                                <li>
                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                </li>
                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                <li><strong>AGE:</strong> <span>28</span></li>
                            </ul>
                        </div>
                        <Link to="/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                    </div>
                </div>
                {/* {<!-- End Item Player -->} */}

                {/* {<!-- Item Player -->} */}
                <div className="col-xl-3 col-lg-4 col-md-6 filter-item goalkeeper">
                    <div className="item-player">
                        <div className="head-player">
                            <img src={LuisImg} alt="location-team" />
                            <div className="overlay"><Link to="/single-player">+</Link></div>
                        </div>
                        <div className="info-player">
                            <span className="number-player">
                                2
                            </span>
                            <h4>
                                Luis Suárez
                                <span>Goalkeeper</span>
                            </h4>
                            <ul>
                                <li>
                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                </li>
                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                <li><strong>AGE:</strong> <span>28</span></li>
                            </ul>
                        </div>
                        <Link to="/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                    </div>
                </div>
                {/* {<!-- End Item Player -->} */}

                {/* {<!-- Item Player -->} */}
                <div className="col-xl-3 col-lg-4 col-md-6 filter-item midfielder">
                    <div className="item-player">
                        <div className="head-player">
                            <img src={GarethImg} alt="location-team" />
                            <div className="overlay"><Link to="/single-player">+</Link></div>
                        </div>
                        <div className="info-player">
                            <span className="number-player">
                                2
                            </span>
                            <h4>
                                Gareth Bale
                                <span>Midfielder</span>
                            </h4>
                            <ul>
                                <li>
                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                </li>
                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                <li><strong>AGE:</strong> <span>28</span></li>
                            </ul>
                        </div>
                        <Link to="/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                    </div>
                </div>
                {/* {<!-- End Item Player -->} */}

                {/* {<!-- Item Player -->} */}
                <div className="col-xl-3 col-lg-4 col-md-6 filter-item goalkeeper">
                    <div className="item-player">
                        <div className="head-player">
                            <img src={SergioImg} alt="location-team" />
                            <div className="overlay"><Link to="/single-player">+</Link></div>
                        </div>
                        <div className="info-player">
                            <span className="number-player">
                                2
                            </span>
                            <h4>
                                Sergio Agüero
                                <span>Goalkeeper</span>
                            </h4>
                            <ul>
                                <li>
                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                </li>
                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                <li><strong>AGE:</strong> <span>28</span></li>
                            </ul>
                        </div>
                        <Link to="/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                    </div>
                </div>
                {/* {<!-- End Item Player -->} */}

                {/* {<!-- Item Player -->} */}
                <div className="col-xl-3 col-lg-4 col-md-6 filter-item forward">
                    <div className="item-player">
                        <div className="head-player">
                            <img src={CristianoImg} alt="location-team" />
                            <div className="overlay"><Link to="/single-player">+</Link></div>
                        </div>
                        <div className="info-player">
                            <span className="number-player">
                                13
                            </span>
                            <h4>
                                Cristiano Ronaldo
                                <span>Forward</span>
                            </h4>
                            <ul>
                                <li>
                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                </li>
                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                <li><strong>AGE:</strong> <span>28</span></li>
                            </ul>
                        </div>
                        <Link to="/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                    </div>
                </div>
                {/* {<!-- End Item Player -->} */}

                {/* {<!-- Item Player -->} */}
                <div className="col-xl-3 col-lg-4 col-md-6 filter-item defender">
                    <div className="item-player">
                        <div className="head-player">
                            <img src={MessiImg} alt="location-team" />
                            <div className="overlay"><Link to="/single-player">+</Link></div>
                        </div>
                        <div className="info-player">
                            <span className="number-player">
                                10
                            </span>
                            <h4>
                                Lionel Messi
                                <span>Defender</span>
                            </h4>
                            <ul>
                                <li>
                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                </li>
                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                <li><strong>AGE:</strong> <span>28</span></li>
                            </ul>
                        </div>
                        <Link to="/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                    </div>
                </div>
                {/* {<!-- End Item Player -->} */}

                {/* {<!-- Item Player -->} */}
                <div className="col-xl-3 col-lg-4 col-md-6 filter-item midfielder">
                    <div className="item-player">
                        <div className="head-player">
                            <img src={NeymarImg} alt="location-team" />
                            <div className="overlay"><Link to="/single-player">+</Link></div>
                        </div>
                        <div className="info-player">
                            <span className="number-player">
                                2
                            </span>
                            <h4>
                                Neymar
                                <span>Midfielder</span>
                            </h4>
                            <ul>
                                <li>
                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                </li>
                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                <li><strong>AGE:</strong> <span>28</span></li>
                            </ul>
                        </div>
                        <Link to="/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                    </div>
                </div>
                {/* {<!-- End Item Player -->} */}

                {/* {<!-- Item Player -->} */}
                <div className="col-xl-3 col-lg-4 col-md-6 filter-item goalkeeper">
                    <div className="item-player">
                        <div className="head-player">
                            <img src={LuisImg} alt="location-team" />
                            <div className="overlay"><Link to="/single-player">+</Link></div>
                        </div>
                        <div className="info-player">
                            <span className="number-player">
                                2
                            </span>
                            <h4>
                                Luis Suárez
                                <span>Goalkeeper</span>
                            </h4>
                            <ul>
                                <li>
                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                </li>
                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                <li><strong>AGE:</strong> <span>28</span></li>
                            </ul>
                        </div>
                        <Link to="/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                    </div>
                </div>
                {/* {<!-- End Item Player -->} */}

                {/* {<!-- Item Player -->} */}
                <div className="col-xl-3 col-lg-4 col-md-6 filter-item midfielder">
                    <div className="item-player">
                        <div className="head-player">
                            <img src={GarethImg} alt="location-team" />
                            <div className="overlay"><Link to="/single-player">+</Link></div>
                        </div>
                        <div className="info-player">
                            <span className="number-player">
                                2
                            </span>
                            <h4>
                                Gareth Bale
                                <span>Midfielder</span>
                            </h4>
                            <ul>
                                <li>
                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                </li>
                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                <li><strong>AGE:</strong> <span>28</span></li>
                            </ul>
                        </div>
                        <Link to="/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                    </div>
                </div>
                {/* {<!-- End Item Player -->} */}

                {/* {<!-- Item Player -->} */}
                <div className="col-xl-3 col-lg-4 col-md-6 filter-item goalkeeper">
                    <div className="item-player">
                        <div className="head-player">
                            <img src={SergioImg} alt="location-team" />
                            <div className="overlay"><Link to="/single-player">+</Link></div>
                        </div>
                        <div className="info-player">
                            <span className="number-player">
                                2
                            </span>
                            <h4>
                                Sergio Agüero
                                <span>Goalkeeper</span>
                            </h4>
                            <ul>
                                <li>
                                    <strong>NATIONALITY</strong> <span><img src={ColombiaClubLogo} alt="" /> Colombia </span>
                                </li>
                                <li><strong>MATCHES:</strong> <span>90</span></li>
                                <li><strong>AGE:</strong> <span>28</span></li>
                            </ul>
                        </div>
                        <Link to="/single-player" className="btn">View Player <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                    </div>
                </div>
                {/* {<!-- End Item Player -->} */}

            </div>
        </div>
    </>
  );
};

export default PlayersFilter;
