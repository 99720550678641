import React from "react";
import SlideOne from "../../assets/img/slide/1.jpg";
import NewsLetter from "../../components/news/news-letter";

const PageNotFound = (props) => {
  return <>
        {/* {<!-- Section Title -->} */}
        <div className="section-title" style={{background:'url("'+SlideOne+'")'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <h1>Page 404</h1>
                    </div>

                    <div className="col-md-4">
                        <div className="breadcrumbs">
                            <ul>
                                <li><a href="index.html">Home</a></li>
                                <li>Inner Page</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* {<!-- End Section Title -->} */}

        {/* {<!-- Section Area - Content Central -->} */}
        <section className="content-info">

            {/* {<!-- Content Central -->} */}
            <section className="container">
                <div className="row">
                    <div className="page-error">
                        <h1>404 <i className="fa fa-unlink"></i></h1>
                            <hr className="tall" />
                        <p className="lead">We're sorry, but the page you were looking for doesn't exist.</p>
                        <a href="index.html" className="btn btn-lg btn-primary">Return Home</a>
                    </div>
                </div>
            </section>
            {/* {<!-- End Content Central -->} */}

            {/* {<!-- Newsletter -->} */}
            <NewsLetter />
            {/* {<!-- End Newsletter -->} */}
        </section>
        {/* {<!-- End Section Area -  Content Central -->} */}

        <div className="instagram-btn">
            <div className="btn-instagram">
                <i className="fa fa-instagram"></i>
                FOLLOW
                <a href="https://www.instagram.com/fifaworldcup/" target="_blank">&#64;fifaworldcup</a>
            </div>
        </div>

        <div className="content-instagram">
            <div id="instafeed"></div>
        </div>
  </>;
};

export default PageNotFound;
