import React from "react";
import { Link } from "react-router-dom";
import CristianoImg from "../../assets/img/cricket/players/1.jpg";
import MessiImg from "../../assets/img/cricket/players/2.jpg";
import NeymarImg from "../../assets/img/cricket/players/3.jpg";
import LuisImg from "../../assets/img/cricket/players/4.jpg";
import GarethImg from "../../assets/img/cricket/players/5.jpg";
import SergioImg from "../../assets/img/cricket/players/6.jpg";

const CricketBestplayers = () => {
  return (
    <>
      {/* <!-- Experts --> */}
      <div className="panel-box">
        <div className="titles">
          <h4>Best Players</h4>
        </div>

        <div className="row">
          <div className="col-xs-6 col-sm-3 col-md-4 col-lg-4">
            <div className="box-info">
              <Link to="/single-player">
                <img src={CristianoImg} alt="" className="img-responsive" />
              </Link>
              <h6 className="entry-title">
                <Link to="/single-player">Sachin Tendulkar</Link>
              </h6>
            </div>
          </div>
          <div className="col-xs-6 col-sm-3 col-md-4 col-lg-4">
            <div className="box-info">
              <Link to="/single-player">
                <img src={MessiImg} alt="" className="img-responsive" />
              </Link>
              <h6 className="entry-title">
                <Link to="/single-player">Shoaib Akhtar</Link>
              </h6>
            </div>
          </div>
          <div className="col-xs-6 col-sm-3 col-md-4 col-lg-4">
            <div className="box-info">
              <Link to="/single-player">
                <img src={NeymarImg} alt="" className="img-responsive" />
              </Link>
              <h6 className="entry-title">
                <Link to="/single-player">Sanath Jayasuriya</Link>
              </h6>
            </div>
          </div>
          <div className="col-xs-6 col-sm-3 col-md-4 col-lg-4">
            <div className="box-info">
              <Link to="/single-player">
                <img src={LuisImg} alt="" className="img-responsive" />
              </Link>
              <h6 className="entry-title">
                <Link to="/single-player">Ricky Ponting</Link>
              </h6>
            </div>
          </div>
          <div className="col-xs-6 col-sm-3 col-md-4 col-lg-4">
            <div className="box-info">
              <Link to="/single-player">
                <img src={GarethImg} alt="" className="img-responsive" />
              </Link>
              <h6 className="entry-title">
                <Link to="/single-player">Marnus Labuschagne</Link>
              </h6>
            </div>
          </div>
          <div className="col-xs-6 col-sm-3 col-md-4 col-lg-4">
            <div className="box-info">
              <Link to="/single-player">
                <img src={SergioImg} alt="" className="img-responsive" />
              </Link>
              <h6 className="entry-title">
                <Link to="/single-player">Trent Boult</Link>
              </h6>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Experts --> */}
    </>
  );
};

export default CricketBestplayers;
