import React, { Component } from "react";
import Slider from "react-slick";

import Sponsor1 from "../../assets/img/sponsors/1.png";
import Sponsor2 from "../../assets/img/sponsors/2.png";
import Sponsor3 from "../../assets/img/sponsors/3.png";
import Sponsor4 from "../../assets/img/sponsors/4.png";
import Sponsor5 from "../../assets/img/sponsors/5.png";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1
    };
    return (
      <div>
        <Slider {...settings} className="sponsors-carousel">
        
            <li><a href="/#"><img src={Sponsor1} alt=""/></a></li>
            <li><a href="/#"><img src={Sponsor2} alt=""/></a></li>
            <li><a href="/#"><img src={Sponsor3} alt=""/></a></li>
            <li><a href="/#"><img src={Sponsor4} alt=""/></a></li>
            <li><a href="/#"><img src={Sponsor5} alt=""/></a></li>
            <li><a href="/#"><img src={Sponsor3} alt=""/></a></li>
        
        </Slider>
      </div>
    );
  }
}