import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import porClubLogos from "../../assets/img/clubs-logos/por.png";
import espClubLogos from "../../assets/img/clubs-logos/esp.png";


export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1
    };
    return (
      <div>
        <Slider {...settings} className="sponsors1-carousel px-4">
        
            <li>
                <div className="recent-results">
                    <div className="info-results bg-transparent">
                        <ul>
                        <li>
                            <span className="head">
                            Portugal Vs Spain <span className="date">27 Jun 2017</span>
                            </span>
                            <div className="goals-result">
                                <Link to="/single-team">
                                <img src={porClubLogos} alt="" />
                                Portugal
                                </Link>
                                <span className="goals">
                                <b>2</b> - <b>3</b>
                                </span>
                                <Link to="/single-team">
                                <img src={espClubLogos} alt="" />
                                Spain
                                </Link>
                            </div>
                        </li>
                        </ul>
                    </div>
                </div>
            </li>
            <li>
                <div className="recent-results">
                    <div className="info-results bg-transparent">
                        <ul>
                        <li>
                            <span className="head">
                            Portugal Vs Spain <span className="date">27 Jun 2017</span>
                            </span>
                            <div className="goals-result">
                                <Link to="/single-team">
                                <img src={porClubLogos} alt="" />
                                Portugal
                                </Link>
                                <span className="goals">
                                <b>2</b> - <b>3</b>
                                </span>
                                <Link to="/single-team">
                                <img src={espClubLogos} alt="" />
                                Spain
                                </Link>
                            </div>
                        </li>
                        </ul>
                    </div>
                </div>
            </li>
            <li>
                <div className="recent-results">
                    <div className="info-results bg-transparent">
                        <ul>
                        <li>
                            <span className="head">
                            Portugal Vs Spain <span className="date">27 Jun 2017</span>
                            </span>
                            <div className="goals-result">
                                <Link to="/single-team">
                                <img src={porClubLogos} alt="" />
                                Portugal
                                </Link>
                                <span className="goals">
                                <b>2</b> - <b>3</b>
                                </span>
                                <Link to="/single-team">
                                <img src={espClubLogos} alt="" />
                                Spain
                                </Link>
                            </div>
                        </li>
                        </ul>
                    </div>
                </div>
            </li>
            <li>
                <div className="recent-results">
                    <div className="info-results bg-transparent">
                        <ul>
                        <li>
                            <span className="head">
                            Portugal Vs Spain <span className="date">27 Jun 2017</span>
                            </span>
                            <div className="goals-result">
                                <Link to="/single-team">
                                <img src={porClubLogos} alt="" />
                                Portugal
                                </Link>
                                <span className="goals">
                                <b>2</b> - <b>3</b>
                                </span>
                                <Link to="/single-team">
                                <img src={espClubLogos} alt="" />
                                Spain
                                </Link>
                            </div>
                        </li>
                        </ul>
                    </div>
                </div>
            </li>
        
        </Slider>
      </div>
    );
  }
}