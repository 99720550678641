import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import rusiaClubLogos from "../../assets/img/clubs-logos/rusia.png";
import croClubLogos from "../../assets/img/clubs-logos/cro.png";
import engClubLogos from "../../assets/img/clubs-logos/eng.png";
import colombiaClubLogos from "../../assets/img/clubs-logos/colombia.png";
import panClubLogos from "../../assets/img/clubs-logos/pan.png";
import polClubLogos from "../../assets/img/clubs-logos/pol.png";
import ausClubLogos from "../../assets/img/clubs-logos/aus.png";
import argClubLogos from "../../assets/img/clubs-logos/arg.png";
import tunClubLogos from "../../assets/img/clubs-logos/tun.png";
import arabiaClubLogos from "../../assets/img/clubs-logos/arabia.png";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 9,
      slidesToScroll: 1
    };
    return (
      <div>
        <Slider {...settings} className="sponsors2-carousel">
        
            <li><Link to="/single-team"><strong><img src={arabiaClubLogos} alt="" /></strong> <span>arabia</span></Link></li>
            <li><Link to="/single-team"><strong><img src={argClubLogos} alt="" /> </strong><span>arg</span></Link></li>
            <li><Link to="/single-team"><strong><img src={ausClubLogos} alt="" /></strong> <span>aus</span></Link></li>
            <li><Link to="/single-team"><strong><img src={colombiaClubLogos} alt="" /></strong> <span>colombia</span></Link></li>
            <li><Link to="/single-team"><strong><img src={croClubLogos} alt="" /> </strong><span>cro</span></Link></li>
            <li><Link to="/single-team"><strong><img src={engClubLogos} alt="" /></strong> <span>eng</span></Link></li>
            <li><Link to="/single-team"><strong><img src={panClubLogos} alt="" /></strong> <span>pan</span></Link></li>
            <li><Link to="/single-team"><strong><img src={polClubLogos} alt="" /></strong> <span>pol</span></Link></li>
            <li><Link to="/single-team"><strong><img src={rusiaClubLogos} alt="" /></strong> <span>rusia</span></Link></li>
            <li><Link to="/single-team"><strong><img src={tunClubLogos} alt="" /></strong> <span>tun</span></Link></li>
        
        </Slider>
      </div>
    );
  }
}