import axios from "axios";
import { React, useEffect, useState } from "react";

const LanguagesDropdown = () => {
  let language_code = localStorage.getItem("i18nextLng");
  // console.log(language_code)


    const [languagesData, setLanguagesData] = useState([]);
    const apilanguages = async () => {
  
      var base_url = "https://sportsbackend.pixeltechnosol.com/api/";
      var response = await axios.get(base_url+'languages');
  
      // console.log(JSON.stringify(response.data.data.items));
      setLanguagesData(response.data.data.items);
  
    }

    const renderLanguagesData = (languages, index) => {

      let selected_language = (language_code == languages.code) ? "selected" : "";
      // console.log(selected_language);  
      return (
          <>
          <option 
          selected={selected_language}
           data-id={languages.id} value={languages.code}>{languages.name}</option>
         </>
        );
    
    }

    useEffect(() => {
        apilanguages();
    }, []);

  

  return (

    <>
        
        {languagesData.map(renderLanguagesData)}
        {/* <option value="en">English</option>
            <option value="ar">Arabic</option> */}
    </>
  );
};

export default LanguagesDropdown;
