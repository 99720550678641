import React from "react";
import { Link } from "react-router-dom";
import NewsLetter from "../../../components/news/news-letter";
import LogoSlider from "../../../components/logoslider/logoslider";

import ColombiaClubLogo from "../../../assets/img/football/clubs-logos/colombia.png";
import ArgClubLogo from "../../../assets/img/football/clubs-logos/arg.png";
import ColClubLogo from "../../../assets/img/football/clubs-logos/col_logo.png";

import SingleTeam from "../../../assets/img/football/clubs-teams/single-team.jpg";
import EspnImage from "../../../assets/img/football/img-theme/espn.gif";

import LocationThree from "../../../assets/img/football/locations/3.jpg";

import BlogOne from "../../../assets/img/football/blog/2.jpg";
import BlogTwo from "../../../assets/img/football/blog/2.jpg";
import BlogThree from "../../../assets/img/football/blog/3.jpg";
import BlogFour from "../../../assets/img/football/blog/4.jpg";
import { Tab, Tabs } from "react-bootstrap";

const SingleResult = (props) => {
    
  return (
    <>
      
      {/* <!-- Section Title --> */}
            <div className="section-title single-result" style={{background:'url("'+LocationThree+'")'}}>
                <div className="container">
                    <div className="row">
                        {/* <!-- Result Location --> */}
                        <div className="col-lg-12">
                            <div className="result-location">
                                <ul>
                                    <li>
                                        Wed 26 Jun 2022
                                    </li>

                                    <li>
                                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        Saint Petersburg Stadium, Russia
                                    </li>
                                    <li>Att: 80,000</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Result Location --> */}

                    {/* <!-- Result --> */}
                    <div className="row">
                        <div className="col-md-5 col-lg-5">
                            <div className="team">
                                <img src={ColombiaClubLogo} alt="club-logo"/>
                                <Link to="/single-team">Colombia</Link>
                                <ul>
                                    <li>Jamez Rodriguez 12' <i className="fa fa-futbol-o" aria-hidden="true"></i></li>
                                    <li>Falcao Garcia 30' <i className="fa fa-futbol-o" aria-hidden="true"></i></li>
                                    <li>Juan cuadrado 60' <i className="fa fa-futbol-o" aria-hidden="true"></i></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-2 col-lg-2">
                            <div className="result-match">
                                3 : 2
                            </div>

                            <div className="live-on">
                                <a href="/#">
                                    Live on
                                    <img src={EspnImage} alt=""/>
                                </a>
                            </div>
                        </div>

                        <div className="col-md-5 col-lg-5">
                            <div className="team right">
                                <Link to="/single-team">Argentina</Link>
                                <img src={ArgClubLogo} alt="club-logo"/>
                                <ul>
                                    <li><i className="fa fa-futbol-o" aria-hidden="true"></i> Lionel Messi 22' </li>
                                    <li><i className="fa fa-futbol-o" aria-hidden="true"></i> Gonzalo Higuaín 50' </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Result --> */}

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="timeline-result">
                                <div className="team-timeline">
                                    <img src={ColClubLogo} alt="club-logo"/>
                                    <Link to="/single-team">Col</Link>
                                </div>
                                <ul className="timeline">
                                  <li className="card-result bottom goal" style={{left:"10%"}} data-placement="top" data-trigger="hover" data-toggle="popover" title="Goal" data-content="10. James Rodriguez">
                                       10'
                                   </li>

                                   <li className="card-result top change" style={{left:"20%"}} data-placement="bottom" data-trigger="hover" data-toggle="popover" title="Player Change" data-content="10. James Rodriguez for 9. Falcao Garcia">
                                       20'
                                   </li>

                                   <li className="card-result top red" style={{left:"31%"}} data-placement="bottom" data-trigger="hover" data-toggle="popover" title="Yellow card" data-content="10. James Rodriguez">
                                       31'
                                   </li>

                                   <li className="card-result top goal" style={{left:"40%"}} data-placement="bottom" data-trigger="hover" data-toggle="popover" title="Goal" data-content="10. James Rodriguez">
                                       40'
                                   </li>

                                   <li className="card-result top yellow" style={{left:"65%"}}  data-placement="bottom" data-trigger="hover" data-toggle="popover" title="Yellow card" data-content="10. James Rodriguez">
                                        65'
                                    </li>

                                    <li className="card-result bottom yellow" style={{left:"50%"}} data-placement="top" data-trigger="hover" data-toggle="popover" title="Yellow card" data-content="10. James Rodriguez">
                                        50'
                                    </li>

                                    <li className="card-result top red" style={{left:"65%"}} data-placement="bottom" data-trigger="hover" data-toggle="popover" title="Yellow card" data-content="10. James Rodriguez">
                                        65'
                                    </li>

                                    <li className="card-result bottom yellow" style={{left:"80%"}} data-placement="top" data-trigger="hover" data-toggle="popover" title="Yellow card" data-content="10. James Rodriguez">
                                        80'
                                    </li>

                                    <li className="card-result top goal" style={{left:"90%"}} data-placement="bottom" data-trigger="hover" data-toggle="popover" title="Goal" data-content="10. James Rodriguez">
                                       90'
                                   </li>
                                </ul>
                                <div className="team-timeline">
                                    <img src={ArgClubLogo} alt="club-logo"/>
                                    <Link to="/single-team">Argentina</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* <!-- End Section Title --> */}

            {/* <!-- Section Area - Content Central --> */}
            <section className="content-info">

                {/* <!-- Single Team Tabs --> */}
                <div className="single-team-tabs">
                   <div className="container">
                        <div className="row">
                            {/* <!-- Left Content - Tabs and Carousel --> */}
                            <div className="col-xl-12 col-md-12">
                                {/* <!-- Nav Tabs --> */}

                                <Tabs
                                    defaultActiveKey="Summary"
                                    id="uncontrolled-tab-example"
                                    className="nav nav-tabs"
                                >
                                <Tab eventKey="Summary" title="Summary">
                                    <div className="col-lg-12">
                                        {/* <!-- Content Tabs --> */}
                                        <div className="tab-content">
                                            {/* <!-- Tab One - Sumary --> */}
                                            <div className="tab-pane active" id="summary">

                                                <div className="panel-box padding-b">
                                                <div className="titles">
                                                    <h4>Match Summary</h4>
                                                </div>
                                                    <div className="row">
                                                    <div className="col-lg-12 col-xl-4">
                                                        <img src={ SingleTeam } alt=""/>
                                                    </div>

                                                    <div className="col-lg-12 col-xl-8">
                                                        <p>The Colombia national football team (Spanish: Selección de fútbol de Colombia) represents Colombia in international football competitions and is overseen by the Colombian Football Federation. It is a member of the CONMEBOL and is currently ranked thirteenth in the FIFA World Rankings.[3] The team are nicknamed Los Cafeteros due to the coffee production in their country.</p>

                                                            <p>Since the mid-1980s, the national team has been a symbol fighting the country's negative reputation. This has made the sport popular and made the national team a sign of nationalism, pride and passion for many Colombians worldwide.</p>

                                                            <p> It is a member of the CONMEBOL and is currently ranked thirteenth in the FIFA World Rankings.[3] The team are nicknamed Los Cafeteros due to the coffee production in their country</p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <!--Items Club News --> */}
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h3 className="clear-title">Match News</h3>
                                                </div>

                                                {/* <!--Item Club News --> */}
                                                <div className="col-lg-6 col-xl-3">
                                                    {/* <!-- Widget Text--> */}
                                                        <div className="panel-box">
                                                            <div className="titles no-margin">
                                                                <h4><a href="/#">World football's dates.</a></h4>
                                                            </div>
                                                            <a href="/#"><img src={BlogTwo} alt=""/></a>
                                                            <div className="row">
                                                            <div className="info-panel">
                                                                    <p>Fans from all around the world can apply for 2022 FIFA World Cup™ tickets as the first window of sales.</p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        {/* <!-- End Widget Text--> */}
                                                </div>
                                                {/* <!--End Item Club News --> */}

                                                {/* <!--Item Club News --> */}
                                                <div className="col-lg-6 col-xl-3">
                                                    {/* <!-- Widget Text--> */}
                                                        <div className="panel-box">
                                                            <div className="titles no-margin">
                                                                <h4><a href="/#">Mbappe’s year to remember</a></h4>
                                                            </div>
                                                            <a href="/#"><img src={BlogThree} alt=""/></a>
                                                            <div className="row">
                                                            <div className="info-panel">
                                                                    <p>Tickets may be purchased online by using Visa payment cards or Visa Checkout. Visa is the official.</p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        {/* <!-- End Widget Text--> */}
                                                </div>
                                                {/* <!--End Item Club News --> */}

                                                {/* <!--Item Club News --> */}
                                                <div className="col-lg-6 col-xl-3">
                                                    {/* <!-- Widget Text--> */}
                                                        <div className="panel-box">
                                                            <div className="titles no-margin">
                                                                <h4><a href="/#">Egypt are one family</a></h4>
                                                            </div>
                                                            <a href="/#"><img src={BlogFour} alt=""/></a>
                                                            <div className="row">
                                                            <div className="info-panel">
                                                                    <p>Successful applicants who have applied for supporter tickets and conditional supporter tickets will.</p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        {/* <!-- End Widget Text--> */}
                                                </div>
                                                {/* <!--End Item Club News --> */}

                                                    {/* <!--Item Club News --> */}
                                                <div className="col-lg-6 col-xl-3">
                                                    {/* <!-- Widget Text--> */}
                                                        <div className="panel-box">
                                                            <div className="titles no-margin">
                                                                <h4><a href="/#">Egypt are one family</a></h4>
                                                            </div>
                                                            <a href="/#"><img src={BlogOne} alt=""/></a>
                                                            <div className="row">
                                                            <div className="info-panel">
                                                                    <p>Successful applicants who have applied for supporter tickets and conditional supporter tickets will.</p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        {/* <!-- End Widget Text--> */}
                                                </div>
                                                {/* <!--End Item Club News --> */}

                                            </div>
                                            {/* <!--End Items Club News --> */}


                                            {/* <!--Items Club video --> */}
                                            <div className="row no-line-height">
                                                <div className="col-md-12">
                                                    <h3 className="clear-title">Match Videos</h3>
                                                </div>

                                                {/* <!--Item Club News --> */}
                                                <div className="col-lg-6 col-xl-4">
                                                    {/* <!-- Widget Text--> */}
                                                        <div className="panel-box">
                                                            <div className="titles no-margin">
                                                                <h4><a href="/#">Eliminatory to the world.</a></h4>
                                                            </div>
                                                            <iframe className="video" src="https://www.youtube.com/embed/Ln8rXkeeyP0" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe>
                                                        </div>
                                                        {/* <!-- End Widget Text--> */}
                                                </div>
                                                {/* <!--End Item Club News --> */}

                                                {/* <!--Item Club News --> */}
                                                <div className="col-lg-6 col-xl-4">
                                                    {/* <!-- Widget Text--> */}
                                                        <div className="panel-box">
                                                            <div className="titles no-margin">
                                                                <h4><a href="/#">Colombia classification</a></h4>
                                                            </div>
                                                            <iframe className="video" src="https://www.youtube.com/embed/Z5cackyUfgk" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe>
                                                        </div>
                                                        {/* <!-- End Widget Text--> */}
                                                </div>
                                                {/* <!--End Item Club News --> */}

                                                {/* <!--Item Club News --> */}
                                                <div className="col-lg-6 col-xl-4">
                                                    {/* <!-- Widget Text--> */}
                                                        <div className="panel-box">
                                                            <div className="titles no-margin">
                                                                <h4><a href="/#">World Cup goal</a></h4>
                                                            </div>
                                                            <iframe className="video" src="https://www.youtube.com/embed/Z5cackyUfgk" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe>
                                                        </div>
                                                        {/* <!-- End Widget Text--> */}
                                                </div>
                                                {/* <!--End Item Club News --> */}
                                            </div>
                                            {/* <!--End Items Club video --> */}

                                            {/* <!--Sponsors CLub --> */}
                                            <div className="row no-line-height">
                                                    <div className="col-md-12">
                                                        <h3 className="clear-title">Match Sponsors</h3>
                                                    </div>
                                                </div>
                                                {/* <!--End Sponsors CLub --> */}

                                                <LogoSlider />

                                            

                                            </div>
                                            {/* <!-- Tab One - Sumary --> */}
                                        </div>
                                    </div>
                                
                                </Tab>
                                <Tab eventKey="match-stats" className="" title="Match Stats">

                                    <div className="col-md-12">
                                        {/* <!-- Tab Two - stats --> */}
                                        <div className="tab-pane" id="stats">
                                            {/* <!-- Result --> */}
                                            <div className="row match-stats">
                                                <div className="col-lg-5">
                                                    <div className="team">
                                                        <img src={ColombiaClubLogo} alt="club-logo"/>
                                                        <Link to="/single-team">Colombia</Link>
                                                    </div>
                                                </div>

                                                <div className="col-lg-2">
                                                    <div className="result-match">
                                                        VS
                                                    </div>
                                                </div>

                                                <div className="col-lg-5">
                                                    <div className="team right">
                                                        <Link to="/single-team">Argentina</Link>
                                                        <img src={ArgClubLogo} alt="club-logo"/>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <ul>
                                                        <li>
                                                            <span className="left">58.5</span>
                                                            <span className="center">Possession %</span>
                                                            <span className="right">40</span>
                                                        </li>

                                                        <li>
                                                            <span className="left">58.5</span>
                                                            <span className="center">Shots On Target</span>
                                                            <span className="right">40</span>
                                                        </li>

                                                        <li>
                                                            <span className="left">58.5</span>
                                                            <span className="center">Shots</span>
                                                            <span className="right">40</span>
                                                        </li>

                                                        <li>
                                                            <span className="left">58.5</span>
                                                            <span className="center">Touches</span>
                                                            <span className="right">40</span>
                                                        </li>

                                                        <li>
                                                            <span className="left">58.5</span>
                                                            <span className="center">Passes</span>
                                                            <span className="right">40</span>
                                                        </li>

                                                        <li>
                                                            <span className="left">58.5</span>
                                                            <span className="center">Tackles</span>
                                                            <span className="right">40</span>
                                                        </li>

                                                        <li>
                                                            <span className="left">58.5</span>
                                                            <span className="center">Clearances</span>
                                                            <span className="right">40</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* <!-- End Result --> */}
                                        </div>
                                        {/* <!-- End Tab Two - stats --> */}
                                    </div>
                                    {/* <!-- Content Tabs --> */}
                                    
                                    
                                </Tab>
                                
                                </Tabs>
                                
                                {/* <!-- End Nav Tabs --> */}
                            
                            

                                    
                            </div>

                        </div>
                    </div>
                </div>
                {/* <!-- Single Team Tabs --> */}

                {/* <!-- Newsletter --> */}
                <NewsLetter />
                {/* <!-- End Newsletter --> */}
            </section>
            {/* <!-- End Section Area -  Content Central --> */}

            <div className="instagram-btn">
                <div className="btn-instagram">
                    <i className="fa fa-instagram"></i>
                    FOLLOW
                    <a href="https://www.instagram.com/fifaworldcup/" target="_blank">&#64;fifaworldcup</a>
                </div>
            </div>

            <div className="content-instagram">
                <div id="instafeed"></div>
            </div>
      
    </>
  );
};

export default SingleResult;
