import React, { isValidElement } from "react";
import NewsLetter from "../../../components/news/news-letter";
import { Link } from "react-router-dom";
import SlideOne from "../../../assets/img/tennis/slide/1.jpg";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { useEffect } from "react";

import PlayersFilter from "../../../components/players/players";
  
const Players = (props) => {

    useEffect(() => { 

    }, []);
  
  
  

  return (
    <>
        {/* {<!-- Section Title -->} */}
        <div className="section-title" style={{background:'url("'+SlideOne+'")'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <h1>Players List</h1>
                    </div>

                    <div className="col-md-4">
                        <div className="breadcrumbs">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Inner Page</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* {<!-- End Section Title -->} */}

        {/* {<!-- Section Area - Content Central -->} */}
        <section className="content-info">

            <PlayersFilter />

            {/* {<!-- Newsletter -->} */}
            <NewsLetter />
            {/* {<!-- End Newsletter -->} */}
        </section>
        {/* {<!-- End Section Area -  Content Central -->} */}

        <div className="instagram-btn">
            <div className="btn-instagram">
                <i className="fa fa-instagram"></i>
                FOLLOW
                <a href="https://www.instagram.com/fifaworldcup/" target="_blank">&#64;fifaworldcup</a>
            </div>
        </div>

        <div className="content-instagram">
            <div id="instafeed"></div>
        </div>          
    </>
  );
};

export default Players;
