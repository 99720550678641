import React from "react";
import { Link } from "react-router-dom";
import NewsLetter from "../../../components/news/news-letter";
import SlideOne from "../../../assets/img/cricket/slide/1.jpg";

const LiveStream = (props) => {
  return (
    <>
        {/* < !--Section Title-- > */}
            <div className="section-title" style={{background:`url(${SlideOne})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h1>Live Stream</h1>
                        </div>

                        <div className="col-md-4">
                            <div className="breadcrumbs">
                                <ul>
                                    <li><a href="index.html">Home</a></li>
                                    <li>Live Stream</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--End Section Title-- > */}

            {/* < !--Section Area - Content Central-- > */}
            <section className="content-info">

                <div className="container paddings-mini">
                   <div className="row">
                       {/* <!-- Sidebars --> */}
                        <aside className="col-lg-3">

                            <div>
                                <h4>Searh Sidebar</h4>
                                <form className="search" action="#" method="Post">
                                    <div className="input-group">
                                        <input className="form-control" placeholder="Search..." name="email"  type="email" required="required"/>
                                        <span className="input-group-btn">
                                            <button className="btn btn-primary" type="submit" name="subscribe" >Go!</button>
                                        </span>
                                    </div>
                                </form>
                            </div>

                            {/* <!-- Widget Categories--> */}
                            <div className="panel-box">
                                <div className="titles no-margin">
                                    <h4>Live Streams</h4>
                                </div>
                                <div className="info-panel">
                                    <ul className="list">
                                        <li><i className="fa fa-check"></i><a href="!#">Live Stream Link here</a></li>
                                        <li><i className="fa fa-check"></i><a href="!#">Live Stream Link here</a></li>
                                        <li><i className="fa fa-check"></i><a href="!#">Live Stream Link here</a></li>
                                        <li><i className="fa fa-check"></i><a href="!#">Live Stream Link here</a></li>
                                        <li><i className="fa fa-check"></i><a href="!#">Live Stream Link here</a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* <!-- End Widget Categories--> */}

                           
                           
                        </aside>
                        {/* <!-- End Sidebars --> */}

                        <div className="col-lg-9">
                            {/* <!-- Content Text--> */}
                            <div className="panel-box padding-b">
                                
                                <div className="info-panel">
                                  
                                
								<div className="highlight-sec">
                                   <iframe width="100%" style={{  height:"450px" }} src="https://www.youtube.com/embed/-Od1kbAj-8s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>       
                                        </div>   
                           

                                </div>

                            </div>
                            {/* <!-- End Content Text--> */}
                        </div>
                   </div>
                </div>

                {/* <!-- Newsletter --> */}
                <NewsLetter />
                {/* <!-- End Newsletter --> */}
            </section>
            {/* <!--End Section Area - Content Central-- > */}

            <div className="instagram-btn">
                <div className="btn-instagram">
                    <i className="fa fa-instagram"></i>
                    FOLLOW
                    <a href="https://www.instagram.com/fifaworldcup/" target="_blank">&#64;fifaworldcup</a>
                </div>
            </div>

            <div className="content-instagram">
                <div id="instafeed"></div>
            </div>
    
            </>
  );
};

export default LiveStream;
