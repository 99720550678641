import React from "react";
import NewsLetter from "../../../components/news/news-letter";

const RegisterPage = (props) => {
  return <>
        {/* {<!-- Section Area - Content Central -->} */}
        <section className="content-info">

            <div className="container paddings">
                {/* {<!-- Content Text-->} */}
                <div className="panel-box block-form">
                    <div className="titles text-center">
                        <h4>REGISTER YOUR EVENT NOW!.</h4>
                    </div>

                    <div className="info-panel">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <p className="lead">Feel the thrill of seeing a global sporting event in one of the world's most incredible cities. Headlining the calendar is the Rusia World Cup</p>
                            </div>
                        </div>

                        <form className="form-horizontal form-theme padding-top-mini">
                            <div className="form-group">
                                <label className="control-label col-sm-2">Event Name</label>
                                <div className="col-sm-10">
                                    <input type="text" id="ername" name="ername" className="form-control" placeholder="Type your name" required="" />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-sm-2">Start Date</label>
                                <div className="col-sm-10">
                                    <input type="date" id="erdate" name="erdate" className="form-control" required="" />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-sm-2">Sex</label>
                                <div className="col-sm-10">
                                    <select id="ersex" name="ersex" className="form-control" required="">
                                        <option value="">Select Sex</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-sm-2">Age Limit</label>
                                <div className="col-sm-10">
                                    <select id="erage" name="erage" className="form-control" required="">
                                        <option value="">Select Age</option>
                                        <option value="00-10">00-10</option>
                                        <option value="10-20">10-20</option>
                                        <option value="20-30">20-30</option>
                                        <option value="30-40">30-40</option>
                                        <option value="40-50">40-50</option>
                                        <option value="50-60">50-60</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-sm-2">City</label>
                                <div className="col-sm-10">
                                    <input type="text" id="ercity" name="ercity" className="form-control" placeholder="Type your city" required="" />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-sm-2">Address</label>
                                <div className="col-sm-10">
                                    <textarea id="eraddr" name="eraddr" className="form-control" placeholder="Type your address"></textarea>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-sm-2">Event Desctiptions</label>
                                <div className="col-sm-10">
                                    <textarea id="erdesc" name="erdesc" className="form-control" placeholder="Type event descriptions"></textarea>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-sm-2">Club Name</label>
                                <div className="col-sm-10">
                                    <input type="email" id="ercn" name="ercn" className="form-control" placeholder="Type your club name" required="" />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-sm-2">Contact E-mail</label>
                                <div className="col-sm-10">
                                    <input type="email" id="ermail" name="ermail" className="form-control" placeholder="Type your email" required="" />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-sm-2">Contact Number</label>
                                <div className="col-sm-10">
                                    <input type="number" id="erphone" name="erphone" className="form-control" placeholder="Type your phone" required="" />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="offset-sm-2 col-sm-10">
                                    <input type="submit" value="submit" className="bnt btn-iw" />
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
                {/* {<!-- End Content Text-->} */}
            </div>

            {/* {<!-- Newsletter -->} */}
            <NewsLetter />
            {/* {<!-- End Newsletter -->} */}
        </section>
        {/* {<!-- End Section Area -  Content Central -->} */}

        <div className="instagram-btn">
            <div className="btn-instagram">
                <i className="fa fa-instagram"></i>
                FOLLOW
                <a href="https://www.instagram.com/fifaworldcup/" target="_blank">&#64;fifaworldcup</a>
            </div>
        </div>

        <div className="content-instagram">
            <div id="instafeed"></div>
        </div>
  </>;
};

export default RegisterPage;
