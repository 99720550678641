import HomePage from "../../views/football/home/index";
import TeamsPage from "../../views/football/team/teams";
import FixturePage from "../../views/football/fixture/fixtures";
import ResultsPage from "../../views/football/result/results";
import SingleResultPage from "../../views/football/singleresult/single-results";
import GroupsPage from "../../views/football/groups/groups";
import PointTablePage from "../../views/football/pointtable/point-table";
import NewsPage from "../../views/football/news/news";
import ContactPage from "../../views/football/contact/contact";
import AboutPage from "../../views/football/about/about";
import FaqPage from "../../views/football/faq/faq";
import PageNotFound from "../../views/errors/page-not-found";
import RegisterPage from "../../views/football/register/register";
import PricingPage from "../../views/football/pricing/pricing";
import ServicesPage from "../../views/football/services/services";
import SingleNewsPage from "../../views/football/singlenews/single-news";
import PlayersPage from "../../views/football/players/players";
import SinglePlayerPage from "../../views/football/players/single-player";
import SingleTeamPage from "../../views/football/team/single-team";
import PredictionContestPage from "../../views/football/predictioncontest/prediction-contest";
import LeaguesPage from "../../views/football/leagues/leagues";
import PredictionLeaguePage from "../../views/football/predictionleague/prediction-league";
import MatchResultsPage from "../../views/football/matchresults/match-results";

import IsotopePage from "../../views/football/isotope/isotope";
import LiveStreamPage from "../../views/football/livestream/live-stream";
import SingleLeaguePage from "../../views/football/singleleague/single-league";

const footballRoutes = [
  {
    component: HomePage,
    exact: true,
    path: "/",
  },
  {
    component: TeamsPage,
    exact: true,
    path: "/teams",
  },
  {
    component: FixturePage,
    exact: true,
    path: "/fixtures",
  },
  {
    component: ResultsPage,
    exact: true,
    path: "/results",
  },
  {
    component: SingleResultPage,
    exact: true,
    path: "/single-results",
  },

  {
    component: GroupsPage,
    exact: true,
    path: "/groups",
  },
  {
    component: PointTablePage,
    exact: true,
    path: "/point-table",
  },
  {
    component: NewsPage,
    exact: true,
    path: "/news",
  },
  {
    component: ContactPage,
    exact: true,
    path: "/contact",
  },
  {
    component: AboutPage,
    exact: true,
    path: "/about",
  },
  {
    component: FaqPage,
    exact: true,
    path: "/faq",
  },
  {
    component: PageNotFound,
    exact: true,
    path: "/page-not-found",
  },
  {
    component: RegisterPage,
    exact: true,
    path: "/register",
  },
  {
    component: PricingPage,
    exact: true,
    path: "/pricing",
  },
  {
    component: ServicesPage,
    exact: true,
    path: "/services",
  },
  {
    component: IsotopePage,
    exact: true,
    path: "/isotope",
  },
  {
    component: PlayersPage,
    exact: true,
    path: "/players",
  },
  {
    component: SingleNewsPage,
    exact: true,
    path: "/single-news/:slug",
  },
  {
    component: SinglePlayerPage,
    exact: true,
    path: "/single-player",
  },
  {
    component: SingleTeamPage,
    exact: true,
    path: "/single-team",
  },
  {
    component: LiveStreamPage,
    exact: true,
    path: "/live-stream",
  },
  {
    component: PredictionContestPage,
    exact: true,
    path: "/prediction-contest",
  },
  {
    component: LeaguesPage,
    exact: true,
    path: "/leagues",
  },
  {
    component: SingleLeaguePage,
    exact: true,
    path: "/single-league",
  },
  {
    component: PredictionLeaguePage,
    exact: true,
    path: "/prediction-league",
  },
  {
    component: MatchResultsPage,
    exact: true,
    path: "/match-results",
  },
];

export default footballRoutes;
