import React from "react";
import { Link } from "react-router-dom";
import NewsLetter from "../../../components/news/news-letter";
import Accordion from 'react-bootstrap/Accordion';

import SlideOne from "../../../assets/img/football/slide/1.jpg";

import ColombiaClubLogo from "../../../assets/img/football/clubs-logos/colombia.png";
import RussiaClubLogo from "../../../assets/img/football/clubs-logos/rusia.png";
import ArabiaClubLogo from "../../../assets/img/football/clubs-logos/arabia.png";
import EgyptClubLogo from "../../../assets/img/football/clubs-logos/egy.png";
import UrugueyClubLogo from "../../../assets/img/football/clubs-logos/uru.png";
import BrazilClubLogo from "../../../assets/img/football/clubs-logos/bra.png";
import SwitzerlandClubLogo from "../../../assets/img/football/clubs-logos/sui.png";
import CostaRicaClubLogo from "../../../assets/img/football/clubs-logos/costa-rica.png";
import JapanClubLogo from "../../../assets/img/football/clubs-logos/japan.png";
import SenegalClubLogo from "../../../assets/img/football/clubs-logos/sen.png";
import PolandClubLogo from "../../../assets/img/football/clubs-logos/pol.png";
import MexicoClubLogo from "../../../assets/img/football/clubs-logos/mex.png";
import PanamaClubLogo from "../../../assets/img/football/clubs-logos/pan.png";
import BelgiumClubLogo from "../../../assets/img/football/clubs-logos/bel.png";
import EnglandClubLogo from "../../../assets/img/football/clubs-logos/eng.png";
import SerbiaClubLogo from "../../../assets/img/football/clubs-logos/srb.png";
import GermanyClubLogo from "../../../assets/img/football/clubs-logos/ger.png";
import SwedenClubLogo from "../../../assets/img/football/clubs-logos/swe.png";
import KoreaRepClubLogo from "../../../assets/img/football/clubs-logos/kor.png";
import TunisiaClubLogo from "../../../assets/img/football/clubs-logos/tun.png";

import LocationOne from "../../../assets/img/football/locations/1.jpg";
import LocationTwo from "../../../assets/img/football/locations/2.jpg";
import LocationThree from "../../../assets/img/football/locations/3.jpg";
import LocationFour from "../../../assets/img/football/locations/4.jpg";

const ServicesPage = (props) => {
  return (
    <>
        {/* {<!-- Section Title -->} */}
        <div className="section-title big-title" style={{background:'url("'+LocationThree+'")'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <h1>Services</h1>
                    </div>

                    <div className="col-md-4">
                        <div className="breadcrumbs">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Inner Page</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* {<!-- End Section Title -->} */}

        {/* {<!-- Section Area - Content Central -->} */}
        <section className="content-info">

            {/* {<!-- White Section -->} */}
            <div className="white-section paddings">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <h4 className="subtitle">
                                <span>Company Value</span>
                                Principal Services
                            </h4>
                            <div className="accrodation">

                            <Accordion defaultActiveKey="0" flush id="accordian-services">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Mision</Accordion.Header>
                                    <Accordion.Body>
                                    Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante.
                                                                
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Vision</Accordion.Header>
                                    <Accordion.Body>
                                    Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante.
                                                                
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>What we offer?</Accordion.Header>
                                    <Accordion.Body>
                                    Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante.
                                                                
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                                

                            </div>
                        </div>

                        <div className="col-lg-5">
                            <img src={LocationThree} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* {<!-- End White Section -->} */}

            {/* {<!-- End Parallax Section -->} */}
            <div className="default-section paddings">
                <div className="container">

                    <div className="row no-line-height">
                            <div className="col-md-12">
                                <h3 className="clear-title">Club Teams</h3>
                            </div>
                    </div>


                    <div className="row">
                        {/* {<!-- Item Team Group A-->} */}
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="item-team">
                                <div className="head-team">
                                    <img src={LocationOne} alt="location-team" />
                                    <div className="overlay"><Link to="/single-team">+</Link></div>
                                </div>
                                <div className="info-team">
                                    <span className="logo-team">
                                        <img src={RussiaClubLogo} alt="logo-team" />
                                    </span>
                                    <h4>Rusia</h4>
                                    <span className="location-team">
                                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        Stadium
                                    </span>
                                    <span className="group-team">
                                        <i className="fa fa-trophy" aria-hidden="true"></i>
                                        Group A
                                    </span>
                                </div>
                                <Link to="/single-team" className="btn">Team Profile <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                            </div>
                        </div>
                        {/* {<!-- End Item Team Group A-->} */}

                        {/* {<!-- Item Team Group A-->} */}
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="item-team">
                                <div className="head-team">
                                    <img src={LocationTwo} alt="location-team" />
                                    <div className="overlay"><Link to="/single-team">+</Link></div>
                                </div>
                                <div className="info-team">
                                    <span className="logo-team">
                                        <img src={ArabiaClubLogo} alt="logo-team" />
                                    </span>
                                    <h4>Saudi Arabia</h4>
                                    <span className="location-team">
                                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        Stadium
                                    </span>
                                    <span className="group-team">
                                        <i className="fa fa-trophy" aria-hidden="true"></i>
                                        Group A
                                    </span>
                                </div>
                                <Link to="/single-team" className="btn">Team Profile <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                            </div>
                        </div>
                        {/* {<!-- End Item Team Group A-->} */}

                        {/* {<!-- Item Team Group A-->} */}
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="item-team">
                                <div className="head-team">
                                    <img src={LocationThree} alt="location-team" />
                                    <div className="overlay"><Link to="/single-team">+</Link></div>
                                </div>
                                <div className="info-team">
                                    <span className="logo-team">
                                        <img src={EgyptClubLogo} alt="logo-team" />
                                    </span>
                                    <h4>Egypt</h4>
                                    <span className="location-team">
                                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        Stadium
                                    </span>
                                    <span className="group-team">
                                        <i className="fa fa-trophy" aria-hidden="true"></i>
                                        Group A
                                    </span>
                                </div>
                                <Link to="/single-team" className="btn">Team Profile <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                            </div>
                        </div>
                        {/* {<!-- End Item Team Group A-->} */}

                        {/* {<!-- Item Team Group A-->} */}
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="item-team">
                                <div className="head-team">
                                    <img src={LocationFour} alt="location-team" />
                                    <div className="overlay"><Link to="/single-news">+</Link></div>
                                </div>
                                <div className="info-team">
                                    <span className="logo-team">
                                        <img src={UrugueyClubLogo} alt="logo-team" />
                                    </span>
                                    <h4>Uruguay</h4>
                                    <span className="location-team">
                                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        Stadium
                                    </span>
                                    <span className="group-team">
                                        <i className="fa fa-trophy" aria-hidden="true"></i>
                                        Group A
                                    </span>
                                </div>
                                <Link to="/single-team" className="btn">Team Profile <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                            </div>
                        </div>
                        {/* {<!-- End Item Team Group A-->} */}
                    </div>
                </div>
            </div>
            {/* {<!-- End Gray Section -->} */}

            {/* {<!-- Parallax Section -->} */}
            <div className="parallax-section" style={{background:'url("'+SlideOne+'")'}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <h1 className="big-title">We are a great team</h1>
                        </div>
                    </div>
                </div>
            </div>
            {/* {<!-- End Gray Section -->} */}

            {/* {<!-- Sponsors-->} */}
            <div className="default-section paddings-mini">
                <div className="container">
                    <div className="groups-list page-group">
                        <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <h5><Link to="/groups">GROUP E</Link></h5>
                            <div className="item-group">
                                <ul>
                                    <li>
                                        <Link to="/single-team">
                                            <img src={BrazilClubLogo} alt="" />
                                            Brazil
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/single-team">
                                            <img src={SwitzerlandClubLogo} alt="" />
                                            Switzerland
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/single-team">
                                            <img src={CostaRicaClubLogo} alt="" />
                                            Costa rica
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/single-team">
                                            <img src={SerbiaClubLogo} alt="" />
                                            Serbia
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <h5><Link to="/groups">GROUP F</Link></h5>
                            <div className="item-group">
                                <ul>
                                    <li>
                                        <Link to="/single-team">
                                            <img src={GermanyClubLogo} alt="" />
                                            Germany
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/single-team">
                                            <img src={MexicoClubLogo} alt="" />
                                            Mexico
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/single-team">
                                            <img src={SwedenClubLogo} alt="" />
                                            Sweden
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/single-team">
                                            <img src={KoreaRepClubLogo} alt="" />
                                            Korea Rep
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <h5><Link to="/groups">GROUP G</Link></h5>
                            <div className="item-group">
                                <ul>
                                    <li>
                                        <Link to="/single-team">
                                            <img src={BelgiumClubLogo} alt="" />
                                            Belgium
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/single-team">
                                            <img src={PanamaClubLogo} alt="" />
                                            Panama
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/single-team">
                                            <img src={TunisiaClubLogo} alt="" />
                                            Tunisia
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/single-team">
                                            <img src={EnglandClubLogo} alt="" />
                                            England
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <h5><Link to="/groups">GROUP H</Link></h5>
                            <div className="item-group">
                                <ul>
                                    <li>
                                        <Link to="/single-team">
                                            <img src={PolandClubLogo} alt="" />
                                            Poland
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/single-team">
                                            <img src={SenegalClubLogo} alt="" />
                                            Senegal
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/single-team">
                                            <img src={ColombiaClubLogo} alt="" />
                                            Colombia
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/single-team">
                                            <img src={JapanClubLogo} alt="" />
                                            Japan
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            {/* {<!-- End Sponsors -->} */}


            {/* {<!-- Newsletter -->} */}
            <NewsLetter />
            {/* {<!-- End Newsletter -->} */}
        </section>
        {/* {<!-- End Section Area -  Content Central -->} */}

        <div className="instagram-btn">
            <div className="btn-instagram">
                <i className="fa fa-instagram"></i>
                FOLLOW
                <a href="https://www.instagram.com/fifaworldcup/" target="_blank">&#64;fifaworldcup</a>
            </div>
        </div>

        <div className="content-instagram">
            <div id="instafeed"></div>
        </div>
    </>
  );
};

export default ServicesPage;
