import React from "react";

import NewsLetter from "../../../components/news/news-letter";
import SlideOne from "../../../assets/img/tennis/slide/1.jpg";

import ColombiaClubLogo from "../../../assets/img/tennis/clubs-logos/colombia.png";
import BrazilClubLogo from "../../../assets/img/tennis/clubs-logos/bra.png";
import RussiaClubLogo from "../../../assets/img/tennis/clubs-logos/rusia.png";
import SpainClubLogo from "../../../assets/img/tennis/clubs-logos/esp.png";
import IranClubLogo from "../../../assets/img/tennis/clubs-logos/irn.png";
import ArgentinaClubLogo from "../../../assets/img/tennis/clubs-logos/arg.png";
import JapanClubLogo from "../../../assets/img/tennis/clubs-logos/japan.png";
import SenegalClubLogo from "../../../assets/img/tennis/clubs-logos/sen.png";
import PolandClubLogo from "../../../assets/img/tennis/clubs-logos/pol.png";
import FranceClubLogo from "../../../assets/img/tennis/clubs-logos/fra.png";
import AustraliaClubLogo from "../../../assets/img/tennis/clubs-logos/aus.png";
import MexicoClubLogo from "../../../assets/img/tennis/clubs-logos/mex.png";
import PanamaClubLogo from "../../../assets/img/tennis/clubs-logos/pan.png";
import BelgiumClubLogo from "../../../assets/img/tennis/clubs-logos/bel.png";
import EnglandClubLogo from "../../../assets/img/tennis/clubs-logos/eng.png";
import SerbiaClubLogo from "../../../assets/img/tennis/clubs-logos/srb.png";


const PointTablePage = (props) => {
  return (
    <>
        {/* {<!-- Section Title -->} */}
        <div className="section-title" style={{background:'url("'+SlideOne+'")'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <h1>Points Table</h1>
                    </div>

                    <div className="col-md-4">
                        <div className="breadcrumbs">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Inner Page</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* {<!-- End Section Title -->} */}

        {/* {<!-- Section Area - Content Central -->} */}
        <section className="content-info">

            <div className="container paddings-mini">
                <div className="row">
                    <div className="col-lg-12">
                        <table className="table-striped table-responsive table-hover result-point">
                            <thead className="point-table-head">
                                <tr>
                                    <th className="text-left">No</th>
                                    <th className="text-left">TEAM</th>
                                    <th className="text-center">P</th>
                                    <th className="text-center">W</th>
                                    <th className="text-center">D</th>
                                    <th className="text-center">L</th>
                                    <th className="text-center">GS</th>
                                    <th className="text-center">GA</th>
                                    <th className="text-center">+/-</th>
                                    <th className="text-center">PTS</th>
                                </tr>
                            </thead>

                            <tbody className="text-center">
                                <tr>
                                    <td className="text-left number">1 <i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                    <td className="text-left">
                                        <img src={ColombiaClubLogo} alt="Colombia" /><span>Colombia</span>
                                    </td>
                                    <td>38</td>
                                    <td>26</td>
                                    <td>9</td>
                                    <td>3</td>
                                    <td>73</td>
                                    <td>32</td>
                                    <td>+41</td>
                                    <td>87</td>
                                </tr>

                                <tr>
                                    <td className="text-left number">2 <i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                    <td className="text-left">
                                        <img src={BrazilClubLogo} alt="Brazil" /><span>Brazil</span>
                                    </td>
                                    <td>38</td>
                                    <td>24</td>
                                    <td>7</td>
                                    <td>7</td>
                                    <td>83</td>
                                    <td>38</td>
                                    <td>+45</td>
                                    <td>79</td>
                                </tr>

                                <tr>
                                    <td className="text-left number">3 <i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                    <td className="text-left">
                                        <img src={ArgentinaClubLogo} alt="Argentina" /><span>Argentina</span>
                                    </td>
                                    <td>38</td>
                                    <td>22</td>
                                    <td>9</td>
                                    <td>7</td>
                                    <td>71</td>
                                    <td>36</td>
                                    <td>+35</td>
                                    <td>75</td>
                                </tr>

                                <tr>
                                    <td className="text-left number">4<i className="fa fa-caret-down" aria-hidden="true"></i></td>
                                    <td className="text-left">
                                        <img src={JapanClubLogo} alt="Japan" /><span>Japan</span>
                                    </td>
                                    <td>38</td>
                                    <td>20</td>
                                    <td>10</td>
                                    <td>8</td>
                                    <td>62</td>
                                    <td>37</td>
                                    <td>+25</td>
                                    <td>70</td>
                                </tr>

                                <tr>
                                    <td className="text-left number">5  <i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                    <td className="text-left">
                                        <img src={SenegalClubLogo} alt="Senegal" /><span>Senegal</span>
                                    </td>
                                    <td>38</td>
                                    <td>19</td>
                                    <td>7</td>
                                    <td>12</td>
                                    <td>58</td>
                                    <td>53</td>
                                    <td>+5</td>
                                    <td>64</td>
                                </tr>

                                <tr>
                                    <td className="text-left number">6<i className="fa fa-caret-down" aria-hidden="true"></i></td>
                                    <td className="text-left">
                                        <img src={PolandClubLogo} alt="Poland" /><span>Poland</span>
                                    </td>
                                    <td>38</td>
                                    <td>18</td>
                                    <td>8</td>
                                    <td>12</td>
                                    <td>52</td>
                                    <td>48</td>
                                    <td>+4</td>
                                    <td>62</td>
                                </tr>

                                <tr>
                                    <td className="text-left number">7<i className="fa fa-caret-down" aria-hidden="true"></i></td>
                                    <td className="text-left">
                                        <img  src={RussiaClubLogo} alt="Russia" /><span>Russia</span>
                                    </td>
                                    <td>38</td>
                                    <td>18</td>
                                    <td>6</td>
                                    <td>14</td>
                                    <td>54</td>
                                    <td>33</td>
                                    <td>+21</td>
                                    <td>60</td>
                                </tr>

                                <tr>
                                    <td className="text-left number">8<i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                    <td className="text-left">
                                        <img src={IranClubLogo} alt="Iran" /><span>Iran</span>
                                    </td>
                                    <td>38</td>
                                    <td>12</td>
                                    <td>11</td>
                                    <td>15</td>
                                    <td>48</td>
                                    <td>50</td>
                                    <td>-2</td>
                                    <td>47</td>
                                </tr>

                                <tr>
                                    <td className="text-left number">9 <i className="fa fa-circle" aria-hidden="true"></i></td>
                                    <td className="text-left">
                                        <img src={SpainClubLogo} alt="Spain" /><span>Spain</span>
                                    </td>
                                    <td>38</td>
                                    <td>26</td>
                                    <td>9</td>
                                    <td>3</td>
                                    <td>73</td>
                                    <td>32</td>
                                    <td>+41</td>
                                    <td>87</td>
                                </tr>

                                <tr>
                                    <td className="text-left number">10<i className="fa fa-circle" aria-hidden="true"></i></td>
                                    <td className="text-left">
                                        <img src={FranceClubLogo} alt="France" /><span>France</span>
                                    </td>
                                    <td>38</td>
                                    <td>24</td>
                                    <td>7</td>
                                    <td>7</td>
                                    <td>83</td>
                                    <td>38</td>
                                    <td>+45</td>
                                    <td>79</td>
                                </tr>

                                <tr>
                                    <td className="text-left number">11<i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                    <td className="text-left">
                                        <img src={AustraliaClubLogo} alt="Australia" /><span>Australia</span>
                                    </td>
                                    <td>38</td>
                                    <td>22</td>
                                    <td>9</td>
                                    <td>7</td>
                                    <td>71</td>
                                    <td>36</td>
                                    <td>+35</td>
                                    <td>75</td>
                                </tr>

                                <tr>
                                    <td className="text-left number">12<i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                    <td className="text-left">
                                        <img src={MexicoClubLogo} alt="Mexico" /><span>Mexico</span>
                                    </td>
                                    <td>38</td>
                                    <td>20</td>
                                    <td>10</td>
                                    <td>8</td>
                                    <td>62</td>
                                    <td>37</td>
                                    <td>+25</td>
                                    <td>70</td>
                                </tr>

                                <tr>
                                    <td className="text-left number">13 <i className="fa fa-circle" aria-hidden="true"></i></td>
                                    <td className="text-left">
                                        <img src={PanamaClubLogo} alt="Panama" /><span>Panama</span>
                                    </td>
                                    <td>38</td>
                                    <td>19</td>
                                    <td>7</td>
                                    <td>12</td>
                                    <td>58</td>
                                    <td>53</td>
                                    <td>+5</td>
                                    <td>64</td>
                                </tr>

                                <tr>
                                    <td className="text-left number">14<i className="fa fa-caret-down" aria-hidden="true"></i></td>
                                    <td className="text-left">
                                        <img src={BelgiumClubLogo} alt="Belgium" /><span>Belgium</span>
                                    </td>
                                    <td>38</td>
                                    <td>18</td>
                                    <td>8</td>
                                    <td>12</td>
                                    <td>52</td>
                                    <td>48</td>
                                    <td>+4</td>
                                    <td>62</td>
                                </tr>

                                <tr>
                                    <td className="text-left number">15<i className="fa fa-caret-up" aria-hidden="true"></i></td>
                                    <td className="text-left">
                                        <img  src={EnglandClubLogo} alt="England" /><span>England</span>
                                    </td>
                                    <td>38</td>
                                    <td>18</td>
                                    <td>6</td>
                                    <td>14</td>
                                    <td>54</td>
                                    <td>33</td>
                                    <td>+21</td>
                                    <td>60</td>
                                </tr>

                                <tr>
                                    <td className="text-left number">16<i className="fa fa-caret-down" aria-hidden="true"></i></td>
                                    <td className="text-left">
                                        <img src={SerbiaClubLogo} alt="Serbia" /><span>Serbia</span>
                                    </td>
                                    <td>38</td>
                                    <td>12</td>
                                    <td>11</td>
                                    <td>15</td>
                                    <td>48</td>
                                    <td>50</td>
                                    <td>-2</td>
                                    <td>47</td>
                                </tr>
                                </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* {<!-- Newsletter -->} */}
            <NewsLetter />
            {/* {<!-- End Newsletter -->} */}
        </section>
        {/* {<!-- End Section Area -  Content Central -->} */}

        <div className="instagram-btn">
            <div className="btn-instagram">
                <i className="fa fa-instagram"></i>
                FOLLOW
                <a href="https://www.instagram.com/fifaworldcup/" target="_blank">&#64;fifaworldcup</a>
            </div>
        </div>

        <div className="content-instagram">
            <div id="instafeed"></div>
        </div>
      
    </>
  );
};

export default PointTablePage;
