import React from "react";
import { Link } from "react-router-dom";
import CricketSportsGallery from "../../../components/gallery/cricket-sports-gallery";
import RecentNews from "../../../components/news/recent-news";
import CricketBestplayers from "../../../components/players/cricket-best-players";
import NewsLetter from "../../../components/news/news-letter";
import LocationSlider from "../../../components/locationslider/locationslider";
import GameSection from "../../../components/games/games-section";

import SlideOne from "../../../assets/img/cricket/slide/1.jpg";


import ColombiaClubLogo from "../../../assets/img/cricket/clubs-logos/colombia.png";
import RussiaClubLogo from "../../../assets/img/cricket/clubs-logos/rusia.png";
import ArabiaClubLogo from "../../../assets/img/cricket/clubs-logos/arabia.png";
import EgyptClubLogo from "../../../assets/img/cricket/clubs-logos/egy.png";
import UrugueyClubLogo from "../../../assets/img/cricket/clubs-logos/uru.png";
import PortagalClubLogo from "../../../assets/img/cricket/clubs-logos/por.png";
import SpainClubLogo from "../../../assets/img/cricket/clubs-logos/esp.png";
import MorrocoClubLogo from "../../../assets/img/cricket/clubs-logos/mar.png";
import IranClubLogo from "../../../assets/img/cricket/clubs-logos/irn.png";

import CristianoImg from "../../../assets/img/cricket/players/1.jpg";
import MessiImg from "../../../assets/img/cricket/players/2.jpg";
import NeymarImg from "../../../assets/img/cricket/players/3.jpg";
import LuisImg from "../../../assets/img/cricket/players/4.jpg";
import GarethImg from "../../../assets/img/cricket/players/5.jpg";
import SergioImg from "../../../assets/img/cricket/players/6.jpg";
import JamezImg from "../../../assets/img/cricket/players/7.jpg";
import FalcaoImg from "../../../assets/img/cricket/players/1.jpg";

import LocationOne from "../../../assets/img/cricket/locations/1.jpg";
import LocationTwo from "../../../assets/img/cricket/locations/2.jpg";
import LocationThree from "../../../assets/img/cricket/locations/3.jpg";
import LocationFour from "../../../assets/img/cricket/locations/4.jpg";

import Player1 from "../../../assets/img/cricket/players/1.jpg";
import Player2 from "../../../assets/img/cricket/players/2.jpg";
import Player3 from "../../../assets/img/cricket/players/3.jpg";

import rusiaClubLogo from "../../../assets/img/cricket/clubs-logos/rusia.png";
import arabiaClubLogo from "../../../assets/img/cricket/clubs-logos/arabia.png";
import braClubLogo from "../../../assets/img/cricket/clubs-logos/bra.png";
import costaClubLogo from "../../../assets/img/cricket/clubs-logos/costa-rica.png";
import colombiaClubLogo from "../../../assets/img/cricket/clubs-logos/colombia.png";
import japanClubLogo from "../../../assets/img/cricket/clubs-logos/japan.png";
import fraClubLogo from "../../../assets/img/cricket/clubs-logos/fra.png";
import ausClubLogo from "../../../assets/img/cricket/clubs-logos/aus.png";

import BasketBall from "../../../assets/img/cricket/sports/basketball-icon.png";
import Cricket from "../../../assets/img/cricket/sports/cricket-icon.png";
import Tennis from "../../../assets/img/cricket/sports/tennis-icon.png";
import Football from "../../../assets/img/cricket/sports/football-icon.png";

import Sidebarimg from "../../../assets/img/cricket/adds/sidebar.png";
import SportTwo from "../../../assets/img/cricket/sports/2.jpg";
import SportThree from "../../../assets/img/cricket/sports/3.jpg";
import SportFour from "../../../assets/img/cricket/sports/4.jpg";
import SportFive from "../../../assets/img/cricket/sports/1.jpg";


import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import CricketHeroSlider from "../../../components/hero-slider/cricket-hero-slider";


const Home = (props) => {

  
  let language_code = localStorage.getItem("i18nextLng");
  if(typeof language_code == "undefined" || language_code == null  || language_code == "en-GB" ){
    language_code = 'en';
  }
  var game_slug = localStorage.getItem("game_slug");

  if(typeof game_slug == "undefined" || game_slug == null ){
    game_slug = "football";
  }


  return (
    <>
      {/* <!-- section-hero--> */}
      <CricketHeroSlider game_slug={game_slug} language_code={language_code}/>
      {/* <!-- End section-hero--> */}

      {/* <!-- Section Area - Content Central --> */}
      <section className="content-info">
        {/* <!-- Dark Home --> */}
        <div className="dark-home paddings-50-50">
          <div className="container">
            <div className="row">
              {/* <!-- Club Ranking --> */}
              <div className="col-lg-4">
                <div className="club-ranking">
                  <h5>
                    <Link to="/cricket/group-list">Club Ranking</Link>
                  </h5>
                  <div className="info-ranking">
                    <ul>
                      <li>
                        <span className="position">1</span>
                        <Link to="/cricket/single-team">
                          <img src={RussiaClubLogo} alt="" />
                          Russia
                        </Link>
                        <span className="points">90</span>
                      </li>

                      <li>
                        <span className="position">2</span>
                        <Link to="/cricket/single-team">
                          <img src={ArabiaClubLogo} alt="" />
                          Saudi Arabia
                        </Link>
                        <span className="points">86</span>
                      </li>

                      <li>
                        <span className="position">3</span>
                        <Link to="/cricket/single-team">
                          <img src={EgyptClubLogo} alt="" />
                          Egypt
                        </Link>
                        <span className="points">84</span>
                      </li>

                      <li>
                        <span className="position">4</span>
                        <Link to="/cricket/single-team">
                          <img src={UrugueyClubLogo} alt="" />
                          Uruguay
                        </Link>
                        <span className="points">70</span>
                      </li>

                      <li>
                        <span className="position">5</span>
                        <Link to="/cricket/single-team">
                          <img src={PortagalClubLogo} alt="" />
                          Portugal
                        </Link>
                        <span className="points">67</span>
                      </li>

                      <li>
                        <span className="position">6</span>
                        <Link to="/cricket/single-team">
                          <img src={SpainClubLogo} alt="" />
                          Spain
                        </Link>
                        <span className="points">60</span>
                      </li>

                      <li>
                        <span className="position">7</span>
                        <Link to="/cricket/single-team">
                          <img src={MorrocoClubLogo} alt="" />
                          Morocco
                        </Link>
                        <span className="points">90</span>
                      </li>

                      <li>
                        <span className="position">8</span>
                        <Link to="/cricket/single-team">
                          <img src={IranClubLogo} alt="" />
                          IR Iran
                        </Link>
                        <span className="points">53</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <!-- End Club Ranking --> */}

              {/* <!-- recent-results --> */}
              <div className="col-lg-4">
                <div className="recent-results">
                  <h5>
                    <Link to="/cricket/group-list">Recent Result</Link>
                  </h5>
                  <div className="info-results">
                    <ul>
                      <li>
                        <span className="head">
                          Portugal Vs Spain{" "}
                          <span className="date">27 Jun 2017</span>
                        </span>

                        <div className="goals-result">
                          <Link to="/cricket/single-team">
                            <img src={PortagalClubLogo} alt="" />
                            Portugal
                          </Link>

                          <span className="goals">
                            <b>2</b> - <b>3</b>
                          </span>

                          <Link to="/cricket/single-team">
                            <img src={SpainClubLogo} alt="" />
                            Spain
                          </Link>
                        </div>
                      </li>

                      <li>
                        <span className="head">
                          Rusia Vs Colombia{" "}
                          <span className="date">30 Jun 2017</span>
                        </span>

                        <div className="goals-result">
                          <Link to="/cricket/single-team">
                            <img src={RussiaClubLogo} alt="" />
                            Rusia
                          </Link>

                          <span className="goals">
                            <b>2</b> - <b>3</b>
                          </span>

                          <Link to="/cricket/single-team">
                            <img src={ColombiaClubLogo} alt="" />
                            Colombia
                          </Link>
                        </div>
                      </li>

                      <li>
                        <span className="head">
                          Uruguay Vs Portugal{" "}
                          <span className="date">31 Jun 2017</span>
                        </span>

                        <div className="goals-result">
                          <Link to="/cricket/single-team">
                            <img src={UrugueyClubLogo} alt="" />
                            Uruguay
                          </Link>

                          <span className="goals">
                            <b>2</b> - <b>3</b>
                          </span>

                          <Link to="/cricket/single-team">
                            <img src={PortagalClubLogo} alt="" />
                            Portugal
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <!-- end recent-results --> */}

              {/* <!-- Top player --> */}
              <div className="col-lg-4">
                <div className="player-ranking">
                  <h5>
                    <Link to="/cricket/group-list">Top player</Link>
                  </h5>
                  <div className="info-player">
                    <ul>
                      <li>
                        <span className="position">1</span>
                        <Link to="/cricket/single-team">
                          <img src={CristianoImg} alt="" />
                          Cristiano R.
                        </Link>
                        <span className="points">90</span>
                      </li>

                      <li>
                        <span className="position">2</span>
                        <Link to="/cricket/single-team">
                          <img src={MessiImg} alt="" />
                          Lionel Messi
                        </Link>
                        <span className="points">88</span>
                      </li>

                      <li>
                        <span className="position">3</span>
                        <Link to="/cricket/single-team">
                          <img src={NeymarImg} alt="" />
                          Neymar
                        </Link>
                        <span className="points">86</span>
                      </li>

                      <li>
                        <span className="position">4</span>
                        <Link to="/cricket/single-team">
                          <img src={LuisImg} alt="" />
                          Luis Suárez
                        </Link>
                        <span className="points">80</span>
                      </li>

                      <li>
                        <span className="position">5</span>
                        <Link to="/cricket/single-team">
                          <img src={GarethImg} alt="" />
                          Gareth Bale
                        </Link>
                        <span className="points">76</span>
                      </li>

                      <li>
                        <span className="position">6</span>
                        <Link to="/cricket/single-team">
                          <img src={SergioImg} alt="" />
                          Sergio Agüero
                        </Link>
                        <span className="points">74</span>
                      </li>

                      <li>
                        <span className="position">7</span>
                        <Link to="/cricket/single-team">
                          <img src={JamezImg} alt="" />
                          Jamez R.
                        </Link>
                        <span className="points">70</span>
                      </li>

                      <li>
                        <span className="position">8</span>
                        <Link to="/cricket/single-team">
                          <img src={FalcaoImg} alt="" />
                          Falcao Garcia
                        </Link>
                        <span className="points">65</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <!-- End Top player --> */}
            </div>
          </div>
        </div>
		
		
		 <div className="white-section paddings">
          <div className="container">
           

            <div className="row">
			 <div className="col-md-9">
			 <div className="row">
              <div className="col-md-12">
                <h3 className="clear-title">Club Teams</h3>
              </div>
			 
           
              {/* <!-- Item Team Group A--> */}
              <div className="col-md-6 col-lg-4 ">
                <div className="item-team">
                  <div className="head-team">
                    <img src={LocationOne} alt="location-team" />
                    <div className="overlay">
                      <Link to="/cricket/single-team">+</Link>
                    </div>
                  </div>
                  <div className="info-team">
                    <span className="logo-team">
                      <img src={RussiaClubLogo} alt="logo-team" />
                    </span>
                    <h4>Rusia</h4>
                    <span className="location-team">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      Stadium
                    </span>
                    <span className="group-team">
                      <i className="fa fa-trophy" aria-hidden="true"></i>
                      Group A
                    </span>
                  </div>
                  <Link to="/cricket/single-team" className="btn">
                    Team Profile{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
              {/* <!-- End Item Team Group A--> */}

              {/* <!-- Item Team Group A--> */}
              <div className="col-md-6 col-lg-4 ">
                <div className="item-team">
                  <div className="head-team">
                    <img src={LocationTwo} alt="location-team" />
                    <div className="overlay">
                      <Link to="/cricket/single-team">+</Link>
                    </div>
                  </div>
                  <div className="info-team">
                    <span className="logo-team">
                      <img src={ArabiaClubLogo} alt="logo-team" />
                    </span>
                    <h4>Saudi Arabia</h4>
                    <span className="location-team">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      Stadium
                    </span>
                    <span className="group-team">
                      <i className="fa fa-trophy" aria-hidden="true"></i>
                      Group A
                    </span>
                  </div>
                  <Link to="/cricket/single-team" className="btn">
                    Team Profile
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
              {/* <!-- End Item Team Group A--> */}

              {/* <!-- Item Team Group A--> */}
              <div className="col-md-6 col-lg-4 ">
                <div className="item-team">
                  <div className="head-team">
                    <img src={LocationThree} alt="location-team" />
                    <div className="overlay">
                      <Link to="/cricket/single-team">+</Link>
                    </div>
                  </div>
                  <div className="info-team">
                    <span className="logo-team">
                      <img src={EgyptClubLogo} alt="logo-team" />
                    </span>
                    <h4>Egypt</h4>
                    <span className="location-team">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      Stadium
                    </span>
                    <span className="group-team">
                      <i className="fa fa-trophy" aria-hidden="true"></i>
                      Group A
                    </span>
                  </div>
                  <Link to="/cricket/single-team" className="btn">
                    Team Profile{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
              {/* <!-- End Item Team Group A--> */}
            </div>
            </div>
            <div className="col-md-3">
							<div className="row">
							<div className="col-md-12 ">
                                  <h3 className="clear-title">Prediction Poll</h3>
								  <div className="rounded-0 card item-team p-4">
								  
								    <p className="text-dark font-weight-bold">Who are the two sides of the World Cup final?</p>
                    <form>
                      <div className="form-check">
                          <input className="form-check-input ml-0 " type="radio" name="exampleRadios" id="exampleRadios1" value="option1"/>
                          <label className="form-check-label text-dark font-weight-bold" for="exampleRadios1">
                            Morocco vs Argentina
                          </label>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input ml-0 " type="radio" name="exampleRadios" id="exampleRadios2" value="option2"/>
                          <label className="form-check-label text-dark font-weight-bold" for="exampleRadios2">
                            Morocco vs Croatia
                          </label>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input ml-0 " type="radio" name="exampleRadios" id="exampleRadios3" value="option3"/>
                          <label className="form-check-label text-dark font-weight-bold" for="exampleRadios3">
                          Argentina vs France
                          </label>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input ml-0 "  type="radio" name="exampleRadios" id="exampleRadios4" value="option4"/>
                          <label className="form-check-label text-dark font-weight-bold" for="exampleRadios4">
                          France vs Croatia
                          </label>
                        </div>
                        <button type="submit" className="btn btn-success px-4 py-2 mt-4">Vote</button>

                      </form>   
                    </div>
                  </div>
							  </div>
							</div>
             
            </div>
          </div>
        </div>
		
		
        {/* <!-- Dark Home --> */}

        <div className="dark-section">
          <div className="container">
            <div className="row pt-5 pb-5">
              {/* <!-- Top player --> */}
              <div className="col-lg-5">

              <Tabs
                defaultActiveKey="most-viewed-videos"
                id="uncontrolled-tab-example"
                className="nav nav-tabs mb-4"
              >
              <Tab eventKey="most-viewed-videos" title="Most Viewed Videos">
              <div className="tab-pane active" id="statistics">
                    <div className="row">
                      {/* <!-- Club Ranking --> */}
                      <div className="col-lg-6">
                        <iframe width="100%" height="215" src="https://www.youtube.com/embed/MQSb1J9sodk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
                      <div className="col-lg-6">
                        <iframe width="100%" height="215" src="https://www.youtube.com/embed/rvryFy3ujDg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
                    </div>
                  </div>
              </Tab>
              <Tab eventKey="latest-videos" className="" title="Latest Videos">
                <div className="tab-pane" id="groups">
                    <div className="groups-list">
                      <div className="row">
                        {/* <!-- Club Ranking --> */}
                        <div className="col-lg-6">
                          <iframe width="100%" height="215" src="https://www.youtube.com/embed/jopwEaxUwtM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="col-lg-6">
                          <iframe width="100%" height="215" src="https://www.youtube.com/embed/_DS8tf3EZj0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                       
                      </div>


                    </div>
                  </div>
                
              </Tab>
              
            </Tabs>
                
               
                {/* <!-- End Nav Tabs --> */}

                <div className="player-ranking pt-4">
                  <h5><Link to="/cricket/group-list">List of Man Of The Match</Link></h5>
                  <div className="info-player">
                    <ul>
                      <li>
                        <span className="position">
                          1
                        </span>
                        <Link to="/cricket/single-team">
                          <img src={Player1} alt="" />
                          Sachin
                        </Link>
                        <span className="points">
                          9
                        </span>
                      </li>

                      <li>
                        <span className="position">
                          2
                        </span>
                        <Link to="/cricket/single-team">
                          <img src={Player2} alt="" />
                          Sohib
                        </Link>
                        <span className="points">
                          8
                        </span>
                      </li>

                      <li>
                        <span className="position">
                          3
                        </span>
                        <Link to="/cricket/single-team">
                          <img src={Player3} alt="" />
                          Jaisurya
                        </Link>
                        <span className="points">
                          8
                        </span>
                      </li>

                    </ul>
                  </div>

                </div>

                {/* <!-- Nav Tabs --> */}

                {/* <!-- Content Tabs --> */}


              </div>
              {/* <!-- End Top player --> */}

              <div className="col-lg-7 pt-4">
                <h2>Highlights</h2>
                <div className="highlight-sec">
                  <iframe width="100%" style={{  height:"450px" }} src="https://www.youtube.com/embed/jopwEaxUwtM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Content Central --> */}
        <div className="container padding-top">
          <div className="row">
            {/* <!-- content Column Left --> */}
            <div className="col-lg-6 col-xl-7">
              
              <RecentNews limit={3} game_id={game_slug} language_id={language_code} />
              
              <CricketBestplayers />
            </div>

            {/* <!-- content Sidebar Center --> */}
            <aside className="col-sm-6 col-lg-3 col-xl-3">
              {/* <!-- Locations --> */}
              <div className="panel-box">
                <div className="titles no-margin">
                  <h4>Locations</h4>
                </div>
                {/* <!-- Locations Carousel --> */}

                <LocationSlider />

                {/* <!-- Locations Carousel --> */}
              </div>
              {/* <!-- End Locations --> */}

              {/* <!-- Video presentation --> */}
              <div className="panel-box">
                <div className="titles no-margin">
                  <h4>Presentation</h4>
                </div>
                {/* <!-- Locations Video --> */}
                <div className="row">
                  <iframe
                    src="https://www.youtube.com/embed/AfOlAUd7u4o"
                    className="video"
                  ></iframe>
                  <div className="info-panel">
                    <h4>Rio de Janeiro</h4>
                    <p>
                      Lorem ipsum dolor sit amet, sit amet, consectetur
                      adipisicing elit, elit, incididunt ut labore et dolore
                      magna aliqua sit amet, consectetur adipisicing elit,
                    </p>
                  </div>
                </div>
                {/* <!-- End Locations Video --> */}
              </div>
              {/* <!-- End Video presentation--> */}

              {/* <!-- Widget img--> */}
              <div className="panel-box">
                <div className="titles no-margin">
                  <h4>Widget Image</h4>
                </div>
                <img src={SlideOne} alt="" />
                <div className="row">
                  <div className="info-panel">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      ut sit amet, consectetur adipisicing elit, labore et
                      dolore.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- End Widget img--> */}
            </aside>

            {/* <!-- content Sidebar Right --> */}
            <aside className="col-sm-6 col-lg-3 col-xl-2">
              {/* <!-- Diary --> */}
              <div className="panel-box">
                <div className="titles">
                  <h4>
                    <i className="fa fa-calendar"></i>Matches
                  </h4>
                </div>

                {/* <!-- List Diary --> */}
                <ul className="list-diary">
                  {/* <!-- Item List Diary --> */}
                  <li>
                    <h6>
                      GROUP A <span>14 JUN 2022 - 18:00</span>
                    </h6>
                    <ul className="club-logo">
                      <li>
                        <img src={rusiaClubLogo} alt="" />
                        <span>RUSSIA</span>
                      </li>
                      <li>
                        <img src={arabiaClubLogo} alt="" />
                        <span>SAUDI ARABIA</span>
                      </li>
                    </ul>
                  </li>
                  {/* <!-- End Item List Diary --> */}

                  {/* <!-- Item List Diary --> */}
                  <li>
                    <h6>
                      GROUP E <span>22 JUN 2022 - 15:00</span>
                    </h6>
                    <ul className="club-logo">
                      <li>
                        <img src={braClubLogo} alt="" />
                        <span>BRAZIL</span>
                      </li>
                      <li>
                        <img src={costaClubLogo} alt="" />
                        <span>COSTA RICA</span>
                      </li>
                    </ul>
                  </li>
                  {/* <!-- End Item List Diary --> */}

                  {/* <!-- Item List Diary --> */}
                  <li>
                    <h6>
                      GROUP H <span>19 JUN 2022 - 15:00</span>
                    </h6>
                    <ul className="club-logo">
                      <li>
                        <img src={colombiaClubLogo} alt="" />
                        <span>COLOMBIA</span>
                      </li>
                      <li>
                        <img src={japanClubLogo} alt="" />
                        <span>JAPAN</span>
                      </li>
                    </ul>
                  </li>
                  {/* <!-- End Item List Diary --> */}

                  {/* <!-- Item List Diary --> */}
                  <li>
                    <h6>
                      GROUP C <span>16 JUN 2022 - 15:00</span>
                    </h6>
                    <ul className="club-logo">
                      <li>
                        <img src={fraClubLogo} alt="" />
                        <span>FRANCE</span>
                      </li>
                      <li>
                        <img src={ausClubLogo} alt="" />
                        <span>AUSTRALIA</span>
                      </li>
                    </ul>
                  </li>
                  {/* <!-- End Item List Diary --> */}
                </ul>
                {/* <!-- End List Diary --> */}
              </div>
              {/* <!-- End Diary --> */}

              {/* <!-- Adds Sidebar --> */}
              <div className="panel-box">
                <div className="titles no-margin">
                  <h4>
                    <i className="fa fa-link"></i>Cta
                  </h4>
                </div>
                <a
                  href="http://themeforest.net/user/iwthemes/portfolio?ref=iwthemes"
                  target="_blank"
                >
                  <img
                    src={Sidebarimg}
                    className="img-responsive"
                    alt=""
                  />
                </a>
              </div>
              {/* <!-- End Adds Sidebar --> */}
            </aside>
            {/* <!-- End content Sidebar Right --> */}
          </div>
        </div>

        {/* Games Section */}
        
        <GameSection />

        {/* Games Section End*/}

       

        <CricketSportsGallery />

        <NewsLetter />
      </section>
    </>
  );
};

export default Home;
