import React from "react";
import { Link } from "react-router-dom";
import NewsLetter from "../../../components/news/news-letter";
import SlideOne from "../../../assets/img/tennis/slide/1.jpg";
import RecentNews from "../../../components/news/recent-news";
import Categories from "../../../components/categories/categories";


const NewsPage = (props) => {

  let language_code = localStorage.getItem("i18nextLng");
  if(typeof language_code == "undefined" || language_code == null  || language_code == "en-GB" ){
    language_code = 'en';
  }
  var game_slug = localStorage.getItem("game_slug");

    if(typeof game_slug == "undefined" || game_slug == null ){
        game_slug = "football";
    }

  return (
    <>
        {/* {<!-- Section Title -->} */}
        <div className="section-title" style={{background:'url("'+SlideOne+'")'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <h1>News Left Sidebar</h1>
                    </div>

                    <div className="col-md-4">
                        <div className="breadcrumbs">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Inner Page</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* {<!-- End Section Title -->} */}

        {/* {<!-- Section Area - Content Central -->} */}
        <section className="content-info">

            <div className="container paddings-mini">
                <div className="row">

                    {/* {<!-- Sidebars -->} */}
                    <aside className="col-lg-3">

                        <div>
                            <h4>Searh Sidebar</h4>
                            <form className="search" action="#" method="Post">
                                <div className="input-group">
                                    <input className="form-control" placeholder="Search..." name="email"  type="email" required="required" />
                                    <span className="input-group-btn">
                                        <button className="btn btn-primary" type="submit" name="subscribe" >Go!</button>
                                    </span>
                                </div>
                            </form>
                        </div>

                        {/* {<!-- Widget Categories-->} */}
                        <div className="panel-box">
                            <div className="titles no-margin">
                                <h4>Categories</h4>
                            </div>
                            <div className="info-panel">
                                <Categories />
                            </div>
                        </div>
                        {/* {<!-- End Widget Categories-->} */}

                        {/* {<!-- Widget Text-->} */}
                        <div className="panel-box">
                            <div className="titles no-margin">
                                <h4>Widget Text</h4>
                            </div>
                            <div className="info-panel">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, incididunt ut labore et dolore.</p>
                            </div>
                        </div>
                        {/* {<!-- End Widget Text-->} */}

                        {/* {<!-- Widget img-->} */}
                        <div className="panel-box">
                            <div className="titles no-margin">
                                <h4>Widget Image</h4>
                            </div>
                            <img src={SlideOne} alt="" />
                            <div className="row">
                                <div className="info-panel">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,  ut sit amet, consectetur adipisicing elit, labore et dolore.</p>
                                </div>
                            </div>
                        </div>
                        {/* {<!-- End Widget img-->} */}
                    </aside>
                    {/* {<!-- End Sidebars -->} */}


                    <div className="col-lg-9">
                        {/* {<!-- Content Text-->} */}
                            <RecentNews limit={3} game_id={game_slug} language_id={language_code} />
                        {/* {<!-- End Content Text-->} */}
                    </div>
                </div>
            </div>

            {/* {<!-- Newsletter -->} */}
            <NewsLetter />
            {/* {<!-- End Newsletter -->} */}
        </section>
        {/* {<!-- End Section Area -  Content Central -->} */}

        <div className="instagram-btn">
            <div className="btn-instagram">
                <i className="fa fa-instagram"></i>
                FOLLOW
                <a href="https://www.instagram.com/fifaworldcup/" target="_blank">&#64;fifaworldcup</a>
            </div>
        </div>

        <div className="content-instagram">
            <div id="instafeed"></div>
        </div>
      
    </>
  );
};

export default NewsPage;
